import { useNavigate } from "react-router-dom";
import { convertToLocalDateString } from "../../utilities/WotvDataUtil";
import { useRecoilValue, useRecoilValue_TRANSITION_SUPPORT_UNSTABLE, useSetRecoilState } from "recoil";
import { filterSelectedDateAtom, guildBattleByGuildId } from "../../service/GuildBattleDataService";
import { CalendarValueType } from "primereact/calendar";
import * as React from "react";
import { useTransition } from "react";
import GuildBattleHistoryTable from "../table/GuildBattleHistoryTable";
import { ReadyDateCalendarInput } from "../wotv/ReadyDateCalendarInput";
import { Fieldset } from "primereact/fieldset";
import { ProgressBar } from "primereact/progressbar";

export interface GuildRankingDashletProps {
    hideGuildName?: boolean;
    hideEnemyGuildName?: boolean;
    hideCalendarInput?: boolean;

    showCrashes?: boolean;
}

export const GuildRankingDashlet = (props: GuildRankingDashletProps) => {
    const navigate = useNavigate();
    const [inTransition, startTransition] = useTransition();

    const guildBattleItems = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(guildBattleByGuildId({
        guildId: 0
    }));

    let selectedDate = useRecoilValue(filterSelectedDateAtom);
    let setSelectedDate = useSetRecoilState(filterSelectedDateAtom);

    const onCalendarChange = (calendarValue: CalendarValueType): calendarValue is Date => {
        let calendarDate = calendarValue as Date;
        startTransition(() => {
            setSelectedDate(convertToLocalDateString(calendarDate));
        });
        return true;
    };

    return (
        <>
            <Fieldset legend={"Leaderboard"}>
                <div className="flex align-items-center">
                    {!props.hideCalendarInput &&
                        <div className="p-inputgroup" style={{ maxWidth: "200px" }}>
                        <span className="p-inputgroup-addon">
                            Date
                        </span>
                            <ReadyDateCalendarInput date={selectedDate} onChange={onCalendarChange} />
                        </div>
                    }
                </div>
            </Fieldset>

            <GuildBattleHistoryTable guildBattleItems={guildBattleItems}
                                     tableId="guild-ranking-table" hideDate
                                     hideWipes
                                     hideAbsences
                                     hideFreshClears/>
        </>);
};