import * as React from "react";
import { GuildBattleMapItem, isGlobal } from "../../utilities/WotvDataUtil";

export type GuildBattleMapIconProps = {
    guildBattleMapItem: GuildBattleMapItem
    width?: number
    height?: number
    disable?: boolean

    onClick?: () => void
    title?: string
}

export class GuildBattleMapIcon extends React.Component<GuildBattleMapIconProps, {}> {
    render() {
        let {questId, mapIname} = this.props.guildBattleMapItem;

        let region = isGlobal() ? "gl" : "jp";

        const url = `https://assets.wotv-stats.com/${region}/guildmapicons/${mapIname.toLowerCase()}.png`;
        return<img src={url} onClick={this.props.onClick}
                 title={this.props.title}
                 style={{
                     filter: `brightness(${this.props.disable ? "40%" : "100%"})`,
                     width: this.props.width || 25,
                     height: this.props.height || 25,
                     lineHeight: this.props.height || 25,
                     verticalAlign: "middle",
                     marginTop: -20
                 }}
            />;
    }
}