import * as React from 'react';
import { UnitsDashlet } from "../components/dashlet/UnitsDashlet";
import { elementColorMap } from "../utilities/WotvDataUtil";

export const UnitsPage = () => {

    return (
        <div className="layout-dashboard" style={{marginTop: "5px"}}>
            <div className="grid">
                {
                    [...Array(8).keys()].map(i => {
                       return  <div className="col-12 lg:col-6">
                            <div className="card widget-table" style={{marginTop: "5px",
                                borderStyle: "solid",
                                borderColor: elementColorMap[i+1]}}>
                                <UnitsDashlet element={i+1}/>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    );
};