import {selectorFamily, useRecoilValue_TRANSITION_SUPPORT_UNSTABLE} from "recoil";
import * as React from "react";
import {Fieldset} from "primereact/fieldset";
import {compareDesc} from "date-fns";
import {apiUrl} from "../../Config";
import {GuildChangeEventItem, PlayerChangeEventItem} from "../../types/wotv-guild-data-api";
import {convertLocalDateArrayToDate} from "../../utilities/WotvDataUtil";
import {GuildNameChangesTable} from "../table/GuildNameChangesTable";
import {InputText} from "primereact/inputtext";
import {PlayerNameChangesTable} from "../table/PlayerNameChangesTable";

export interface PlayerNameChangesDashletProps {
    date?: string
    playerId: number

    hideGuildName?: boolean
    hideDateColumn?: boolean
    hideEnemyGuildName?: boolean
    hideCalendarInput?: boolean

    showCrashes?: boolean
}

export const playerChangesByPlayerIdSelector = selectorFamily({
    key: 'playerChangesByPlayerIdSelector',
    get: (playerId: number) => ({get}) => {

        return fetch(apiUrl() + "/player/" + playerId + "/changes")
            .then(response => response.json())
            .then(items => {
                return items as PlayerChangeEventItem[]
            })
    }
});

export const PlayerNameChangesDashlet = (props: PlayerNameChangesDashletProps) => {
    const playerChangeEventItems = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(playerChangesByPlayerIdSelector(props.playerId));

    let sortedItems = [...playerChangeEventItems].filter(item => item.oldName);

    sortedItems.sort((a, b) => compareDesc(convertLocalDateArrayToDate(a.date), convertLocalDateArrayToDate(b.date)));

    return (
        <>
            <Fieldset legend={"Name Changes"}>
                <div className="flex" style={{columnGap: "10px", rowGap: "10px", flexWrap: "wrap"}}>
                    <div className="p-inputgroup" style={{maxWidth: "100px"}}>
                        <span className="p-inputgroup-addon">
                            Total
                        </span>
                        <InputText value={sortedItems.length} readOnly></InputText>
                    </div>
                </div>
            </Fieldset>
            <PlayerNameChangesTable playerChangeEventItems={sortedItems}/>
        </>);
}