import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { convertLocalDateArrayToDate, convertLocalDateArrayToString } from "../../utilities/WotvDataUtil";
import { compareDesc } from "date-fns";
import { PlayerChangeEventItem } from "../../types/wotv-guild-data-api";

export type PlayerNameChangesTableProps = {
    playerChangeEventItems: PlayerChangeEventItem[],
}

export const PlayerNameChangesTable = (props: PlayerNameChangesTableProps) => {
    let sortedItems = [...props.playerChangeEventItems];

    sortedItems.sort((a, b) => compareDesc(convertLocalDateArrayToDate(a.date), convertLocalDateArrayToDate(b.date)));

    return (
        <>
            <DataTable id="player-event-table" value={sortedItems} dataKey="id"
                       rowHover rows={10} paginator responsiveLayout="scroll">
                <Column field="date" header="Date" body={(data) => convertLocalDateArrayToString(data.date)}></Column>
                <Column field="name" header="Name"></Column>
            </DataTable>
        </>
    );
};
