import {PartyItem, PartyType} from "../../types/wotv-guild-data-api";
import PlayerPartyImage from "../../graphics/PlayerPartyImage";
import {useNavigate} from "react-router-dom";
import React, {startTransition} from "react";
import {convertLocalDateArrayToDate, convertLocalDateArrayToString} from "../../utilities/WotvDataUtil";
import {DataView} from "primereact/dataview";
import {compareDesc} from "date-fns";
import {InputText} from "primereact/inputtext";

export interface PlayerMatchedPartiesDataViewProps {
    guildId?: number;
    sessionId?: number;
    date?: string;

    playerId?: number;

    partyType: PartyType;
    hideLegend?: boolean;
    parties: PartyItem[];
    rows?: number
}

export type PlayerDataViewItem = {
    playerParty: PartyItem
    matchedParties: PartyItem[]
    type: PartyType
}

export const PlayerMatchedPartiesDataView = (props: PlayerMatchedPartiesDataViewProps) => {
    const navigate = useNavigate();

    let partyIdMap: { [index: number]: PartyItem } = {};

    props.parties.forEach(party => {
        partyIdMap[party.partyId] = party;
    });

    let partiesMap: Map<PartyItem, PlayerDataViewItem> = new Map();

    let filteredParties = (typeof props.guildId !== "undefined" ?
        props.parties.filter(party => party.guildId === props.guildId) :
        props.parties.filter(party => party.playerId === props.playerId))
        .filter(party => party.partyType === props.partyType);

    filteredParties.forEach(party => {
        partiesMap.set(party, {
            playerParty: party,
            matchedParties: [],
            type: party.partyType
        });

        for (const matchedParty of party.matchedParties) {
            let playerDataViewItem = partiesMap.get(party);
            playerDataViewItem!.matchedParties.push(partyIdMap[matchedParty]);
        }
    });

    let playerDataViewItems = [...partiesMap.values()];
    playerDataViewItems.sort((a, b) => compareDesc(convertLocalDateArrayToDate(a.playerParty.date), convertLocalDateArrayToDate(b.playerParty.date)));

    const onDateClick = (partyItem: PartyItem) => {
        startTransition(() => {
            navigate("/guild/" + partyItem.guildId + "/" + convertLocalDateArrayToString(partyItem.date) + "/" + partyItem.sessionId);
            window.scrollTo({top: 0, behavior: "smooth"});
        });
    };

    const onNameClick = (partyItem: PartyItem) => {
        if (partyItem.playerId !== props.playerId) {
            startTransition(() => {
                navigate("/player/" + partyItem.playerId);
                window.scrollTo({top: 0, behavior: "smooth"});
            });
        }
    };

    const onGuildClick = (guildId: number) => {
        startTransition(() => {
            navigate("/guild/" + guildId);
            window.scrollTo({top: 0, behavior: "smooth"});
        });
    };

    const itemTemplate = (data: PlayerDataViewItem) => {
        let playerParty = data.playerParty;
        let matchedParties = data.matchedParties;
        return (
            <div className={"col-12"}>
                <div
                    className={""}>

                    {!props.hideLegend &&
                        <>
                            <div className="flex align-items-center "
                                 style={{
                                     columnGap: "5px",
                                     rowGap: "10px",
                                     flexWrap: "wrap",
                                     marginTop: "20px",
                                     marginBottom: "-8px"
                                 }}>
                                <div className="p-inputgroup" style={{maxWidth: "140px", height: "25px"}}>
                                    <span className="p-inputgroup-addon">
                                        Date
                                    </span>
                                    <InputText className={"cursor-pointer"}
                                               value={convertLocalDateArrayToString(playerParty.date)}
                                               readOnly
                                               onClick={() => onDateClick(playerParty)}></InputText>
                                </div>
                                <div className="p-inputgroup" style={{maxWidth: "150px", height: "25px"}}>
                                    <InputText className={"cursor-pointer"} value={playerParty.guildName} readOnly
                                               onClick={() => onGuildClick(playerParty.guildId)}></InputText>
                                </div>
                                <div className="p-inputgroup" style={{maxWidth: "150px", height: "25px"}}>
                                    <InputText className={"cursor-pointer"} value={playerParty.enemyGuildName} readOnly
                                               onClick={() => onGuildClick(playerParty.enemyGuildId)}></InputText>
                                </div>
                            </div>
                        </>}
                    <div className={"flex "}
                         style={{flexWrap: "wrap", columnGap: "10px", marginTop: "15px"}}>

                        <PlayerPartyImage {...playerParty} onNameClick={onNameClick}
                                          onGuildClick={() => onGuildClick(playerParty.guildId)}></PlayerPartyImage>
                        {matchedParties.length > 0 && matchedParties.map((matchedParty) => {
                            return <PlayerPartyImage {...matchedParty} onNameClick={onNameClick}
                                                     onGuildClick={() => matchedParty.guildId}></PlayerPartyImage>;
                        })
                        }
                    </div>
                </div>
            </div>
        );
    };

    return (
        <DataView
            value={playerDataViewItems}
            itemTemplate={(item, layout) => itemTemplate(item)}
            rowsPerPageOptions={[5, 10, 15, 30]}
            rows={5}
            paginator
            totalRecords={playerDataViewItems.length}
            // paginatorPosition={"both"}
        >
        </DataView>
    );
};