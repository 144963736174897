import React from "react";

const StarImage = ({height = 15}: { height?: number }) => {
    return <img
        src="/assets/gb/cmn_icon_star.png"
        style={{
            height: height,
            lineHeight: height,
            verticalAlign: "middle"
        }}
        title="Stars"
    ></img>
}

export default StarImage;