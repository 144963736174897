import { PartyItem, PartyType } from "../../types/wotv-guild-data-api";
import React, { startTransition, useState } from "react";
import { convertLocalDateStringToDate, convertToLocalDateString } from "../../utilities/WotvDataUtil";
import { selectorFamily, useRecoilValue_TRANSITION_SUPPORT_UNSTABLE, useSetRecoilState } from "recoil";
import { filterSelectedDateAtom, guildRankingData } from "../../service/GuildBattleDataService";
import { dataReadyDatesSelector } from "../../service/ConfigService";
import { unitDailyMetaSelector } from "../dashlet/UnitGuildRankUsageChartDashlet";
import { CalendarValueType } from "primereact/calendar";
import { Fieldset } from "primereact/fieldset";
import { ReadyDateCalendarInput } from "../wotv/ReadyDateCalendarInput";
import { WotvIcon } from "../../graphics/icon/WotvIcon";
import {
    UnitPartyCompositionChart,
    UnitPartyCompositionChartItem,
    UnitPartyCompositionStats
} from "../wotv/UnitPartyCompositionChart";
import { Paginator } from "primereact/paginator";
import { useDebounce } from "react-use";
import { InputNumber } from "primereact/inputnumber";

export interface UnitPartyCompositionDashletProps {
    unitId: number,
    date?: string,
    title?: string,
    partyType: PartyType,
}

export type UnitPartyDataRequest = {
    date?: string,
    unitId: number,

    minGuildRank?: number
    maxGuildRank?: number

    flexChecked?: boolean
    wipeChecked?: boolean

    partyType: PartyType
    first: number
}

function extractUnitNamesCombined(partyItem: PartyItem) {
    const unitNames = [partyItem.unit1, partyItem.unit2, partyItem.unit3, partyItem.reserveUnit1, partyItem.reserveUnit2];

    unitNames.sort((a, b) => {
        if (a === 0) return 1;
        if (b === 0) return -1;
        // If none of them is zero, use normal sorting.
        return a - b;
    });

    return unitNames.join("#");
}

const partyWiped = (partyItem: PartyItem) => partyItem.matchedParties.length === 1 && !partyItem.unit1Alive && !partyItem.unit2Alive && !partyItem.unit3Alive;

export const partyCompositionDataSelector = selectorFamily({
    key: "partyCompositionDataSelector",
    get: (props: UnitPartyDataRequest) => ({ get }) => {

        let dataReadyDates = get(dataReadyDatesSelector);
        let latestDate = dataReadyDates[0];
        let date = props.date ? convertLocalDateStringToDate(props.date) : latestDate;

        let unitMetaItems = get(unitDailyMetaSelector({
            date: props.date,
            unitId: props.unitId
        }));

        let battleItems = get(guildRankingData(convertToLocalDateString(date)));

        const guildRankMap: Map<number, number> = new Map();

        for (const battleItem of battleItems) {
            guildRankMap.set(battleItem.guildId, battleItem.guildRank);
        }

        let unitMetaItem = unitMetaItems.filter(unitMetaItem => unitMetaItem.partyType === props.partyType)![0];

        const partyType = unitMetaItem.partyType;

        const partyCountMap: Map<string, number> = new Map();
        const partyWipeMap: Map<string, number> = new Map();
        const partyStarsMap: Map<string, number> = new Map();
        const partyGuildRankMap: Map<string, number[]> = new Map();
        const partyFilterCountMap: Map<string, number> = new Map();

        const partyMatchedPartiesMap: Map<string, PartyItem[]> = new Map();

        const matchedPartyIdMap: Map<number, PartyItem> = new Map();

        unitMetaItem.matchedPartyItems.forEach((partyItem) => matchedPartyIdMap.set(partyItem.partyId, partyItem));

        const checkAttackParty = (attackParty: PartyItem, attackMatchedParties: PartyItem[]) => {

            let attackFilterMatched = true;

            if (props.wipeChecked && !(partyWiped(attackParty))) {
                attackFilterMatched = false;
            }

            if (props.flexChecked) {
                const clearedMatchParties = attackMatchedParties.filter((matchedParty) => {
                    return matchedParty.matchedParties.length === 1 && !matchedParty.unit1Alive && !matchedParty.unit2Alive && !matchedParty.unit3Alive;
                });

                if (clearedMatchParties.length === 0) {
                    attackFilterMatched = false;
                }
            }

            return attackFilterMatched;
        };

        for (const partyItem of unitMetaItem.partyItems) {
            let partyGuildRank = guildRankMap.get(partyItem.guildId)!;

            if (partyGuildRank > 500) {
                continue;
            }

            if (props.maxGuildRank && partyGuildRank > props.maxGuildRank) {
                continue;
            }

            if (props.minGuildRank && partyGuildRank < props.minGuildRank) {
                continue;
            }

            if (partyItem.unit1 === 0 || partyItem.unit2 === 0 || partyItem.unit3 === 0) {
                continue;
            }

            if (partyItem.absent) {
                continue;
            }

            let matchedParties = partyItem.matchedParties.map(partyId => matchedPartyIdMap.get(partyId)!);

            const unitNamesCombined = extractUnitNamesCombined(partyItem);

            if (!partyGuildRankMap.has(unitNamesCombined)) {
                partyGuildRankMap.set(unitNamesCombined, []);
            }

            if (!partyMatchedPartiesMap.has(unitNamesCombined)) {
                partyMatchedPartiesMap.set(unitNamesCombined, []);
            }

            partyCountMap.set(unitNamesCombined, (partyCountMap.get(unitNamesCombined) || 0) + 1);

            if (partyWiped(partyItem)) {
                partyWipeMap.set(unitNamesCombined, (partyWipeMap.get(unitNamesCombined) || 0) + 1);
            }

            partyGuildRankMap.get(unitNamesCombined)!.push(partyGuildRank);

            let partyItems = partyMatchedPartiesMap.get(unitNamesCombined)!;

            if (partyType === "A") {
                if (!checkAttackParty(partyItem, matchedParties)) {
                    continue;
                }
            } else {
                let filteredAttackParties = matchedParties.filter(matchedParty => {
                    return checkAttackParty(matchedParty, [partyItem]);
                });

                if (filteredAttackParties.length === 0) {
                    continue;
                }
            }

            partyStarsMap.set(unitNamesCombined, (partyStarsMap.get(unitNamesCombined) || 0) + partyItem.stars);

            partyFilterCountMap.set(unitNamesCombined, (partyFilterCountMap.get(unitNamesCombined) || 0) + 1);

            for (const matchedParty of matchedParties) {

                partyItems.push(matchedParty);
            }
        }


        const unitPartyCompositionStatsMap: Map<string, UnitPartyCompositionStats> = new Map();

        for (const unitNamesCombined of partyCountMap.keys()) {

            let unitNames = unitNamesCombined.split("#");

            let guildRankArray = partyGuildRankMap.get(unitNamesCombined)!;

            guildRankArray.sort((o1, o2) => o1 - o2);

            const mid = Math.floor(guildRankArray.length / 2);

            const median = guildRankArray.length % 2 !== 0 ? guildRankArray[mid] : (guildRankArray[mid - 1] + guildRankArray[mid]) / 2;

            let numTeams = partyCountMap.get(unitNamesCombined)!;

            let matchedPartiesLength = partyMatchedPartiesMap.has(unitNamesCombined) ? partyMatchedPartiesMap.get(unitNamesCombined)!.length : 0;

            unitPartyCompositionStatsMap.set(unitNamesCombined, {
                partyType: partyType,
                totalTeams: numTeams!,
                numTeamsFiltered: partyFilterCountMap.get(unitNamesCombined) || 0,
                unit1: +unitNames[0],
                unit2: +unitNames[1],
                unit3: +unitNames[2],
                reserveUnit1: +unitNames[3],
                reserveUnit2: +unitNames[4],
                unit1Alive: true,
                unit2Alive: true,
                unit3Alive: true,
                reserveUnit1Alive: true,
                reserveUnit2Alive: true,
                numWipes: partyWipeMap.get(unitNamesCombined) || 0,
                stars: (partyStarsMap.get(unitNamesCombined) || 0) / partyFilterCountMap.get(unitNamesCombined)!,
                defends: matchedPartiesLength / partyFilterCountMap.get(unitNamesCombined)!,
                guildRank: Math.floor(median),
                matchedParties: partyMatchedPartiesMap.get(unitNamesCombined) || []
            });
        }

        let partyCountMapEntries = Array.from(partyCountMap.entries());

        const total = partyCountMapEntries.length;

        partyCountMapEntries.sort((a, b) => {
            // return unitPartyCompositionStatsMap.get(b[0])!.stars - unitPartyCompositionStatsMap.get(a[0])!.stars
            return b[1] - a[1];
        });

        const max = total > 0 ? partyCountMapEntries[0][1] : 0;

        let first = props.first;
        let limit = 5;
        partyCountMapEntries = partyCountMapEntries.slice(first, first + limit);

        const unitPartyCompositionChartItem: UnitPartyCompositionChartItem = {
            keys: Array.from(partyCountMapEntries.map(value => value[0])),
            unitPartyCompositionStatsMap: unitPartyCompositionStatsMap,
            total: total,
            maxCount: max
        };

        partyCountMapEntries.forEach(value => {
            const unitNamesCombined = value[0];
            const count = value[1];

            // @ts-ignore
            unitPartyCompositionChartItem[unitNamesCombined] = count;
        });
        return unitPartyCompositionChartItem;
    }
});

export type Sort = "NAME" | "GUILD_RANK" | "STARS" | "COUNT"

export type PartySortOption = {
    label: string
    value: Sort
}

export const UnitPartyCompositionDashlet = (props: UnitPartyCompositionDashletProps) => {
    let selectedDate = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(filterSelectedDateAtom);
    let setSelectedDate = useSetRecoilState(filterSelectedDateAtom);

    const [minRank, setMinRank] = useState<number>(1);
    const [maxRank, setMaxRank] = useState<number>(500);

    const [wipeChecked, setWipeChecked] = useState(false);
    const [flexChecked, setFlexChecked] = useState(false);

    const [first, setFirst] = useState(0);

    const [debouncedMinRank, setDebouncedMinRank] = useState<number>(1);
    const [debouncedMaxRank, setDebouncedMaxRank] = useState<number>(500);

    const [, cancel] = useDebounce(
        () => {
            startTransition(() => {
                if (minRank > maxRank) {
                    setMinRank(maxRank);
                }
                setDebouncedMinRank(Math.min(maxRank, minRank));
            });
        },
        750,
        [minRank]
    );

    const [, cancel2] = useDebounce(
        () => {
            startTransition(() => {
                if (maxRank < minRank) {
                    setMaxRank(minRank);
                }
                setDebouncedMaxRank(Math.max(maxRank, minRank));
            });
        },
        750,
        [maxRank]
    );

    const onCalendarChange = (calendarValue: CalendarValueType): calendarValue is Date => {
        let calendarDate = calendarValue as Date;
        startTransition(() => {
            setSelectedDate(convertToLocalDateString(calendarDate));
        });
        return true;
    };

    let chartData = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(partyCompositionDataSelector({
        date: selectedDate,
        unitId: props.unitId,
        partyType: props.partyType,
        wipeChecked: wipeChecked,
        flexChecked: flexChecked,
        first: first,
        minGuildRank: debouncedMinRank,
        maxGuildRank: debouncedMaxRank,
        // absentFilterChecked: absentChecked,
        // attackFilterChecked: attackFilterChecked,
        // defenseFilterChecked: defenseFilterChecked
    }));

    return (
        <>
            <Fieldset legend={props.title || (props.partyType === "A" ? "Attack Parties" : "Defense Parties")}>
                <div className="flex justify-content-start"
                     style={{ columnGap: "10px", rowGap: "10px", flexWrap: "wrap" }}>
                    <div className="p-inputgroup" style={{ maxWidth: "200px" }}>
                        <span className="p-inputgroup-addon">
                            Date
                        </span>
                        <ReadyDateCalendarInput date={selectedDate} onChange={onCalendarChange} />
                    </div>
                    <div className="p-inputgroup" style={{ maxWidth: "200px" }}>
                        <span className="p-inputgroup-addon">Max Guild Rank</span>
                        <InputNumber value={maxRank} min={1} max={500} showButtons
                                     onChange={(e) => setMaxRank(e.value!)}></InputNumber>
                    </div>
                    <div className="p-inputgroup" style={{ maxWidth: "200px" }}>
                        <span className="p-inputgroup-addon">Min. Guild Rank</span>
                        <InputNumber value={minRank} min={1} max={500} showButtons
                                     onChange={(e) => setMinRank(e.value!)}></InputNumber>
                    </div>
                    <div className="p-inputgroup align-items-center"
                         style={{ maxWidth: "145px", columnGap: "3px" }}>
                        <span className="p-inputgroup-addon">Filters</span>
                        <WotvIcon url={"/assets/icons/toilet_paper.png"} height={22} width={30}
                                  title="1st attack failures"
                                  onClick={() => {
                                      if (!wipeChecked) {
                                          setFlexChecked(false);
                                      }

                                      setWipeChecked(!wipeChecked);
                                  }} disable={!wipeChecked} />
                        <div style={{ marginLeft: 5 }}>
                            <WotvIcon url={"/assets/icons/flex_icon.png"} height={20} width={20}
                                      title="Fresh team clears"
                                      onClick={() => {
                                          if (!flexChecked) {
                                              setWipeChecked(false);
                                          }

                                          setFlexChecked(!flexChecked);
                                      }} disable={!flexChecked} />
                        </div>
                    </div>


                </div>
            </Fieldset>
            <UnitPartyCompositionChart maxCount={chartData.maxCount} title={props.title} partyType={props.partyType}
                                       data={chartData} />
            <Paginator first={first} rows={5} totalRecords={chartData.total}
                       template={"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"}
                       onPageChange={(e) => setFirst(e.first)}></Paginator>
        </>
    );
};