import React from "react";

export const TrophyImage = () => {
    return <img
        src="/assets/gb/guildbattle_icon_trophy.png"
        style={{
            height: "18px",
            lineHeight: "18px",
            verticalAlign: "middle"
        }}
    ></img>
}