import { selectorFamily, useRecoilValue, useRecoilValue_TRANSITION_SUPPORT_UNSTABLE, useSetRecoilState } from "recoil";
import { filterSelectedDateAtom, findPlayerLogsSelector, guildRankingData } from "../../service/GuildBattleDataService";
import * as React from "react";
import { startTransition, useState } from "react";
import { Fieldset } from "primereact/fieldset";
import { PartyType, PlayerLogInfo } from "../../types/wotv-guild-data-api";
import { WotvIcon } from "../../graphics/icon/WotvIcon";
import { convertToLocalDateString, unitElementMapSelector } from "../../utilities/WotvDataUtil";
import { idMapSelector } from "../../service/ConfigService";
import { Dropdown } from "primereact/dropdown";
import { PartySortOption, Sort } from "./UnitPartiesDataViewDashlet";
import { PlayerMatchedPartiesDetailDataView } from "../wotv/PlayerMatchedPartiesDetailDataView";
import { CalendarValueType } from "primereact/calendar";
import { ReadyDateCalendarInput } from "../wotv/ReadyDateCalendarInput";

export interface PlayerMatchedPartiesDashletProps {
    date?: string;
    guildId?: number;

    hideGuildName?: boolean;
    hideDateColumn?: boolean;
    hideEnemyGuildName?: boolean;
    hideCalendarInput?: boolean;

    showCrashes?: boolean;
    sort?: "RANK" | "DATE";

    sessionId?: number;

    playerId?: number;

    unitId?: number;

    partyType: PartyType;
    hideLegend?: boolean;
    hideElementsFilter?: boolean;
    hideDateFilter?: boolean;
    hideAbsentFilter?: boolean;
    attackFilterDefault: boolean;
    defenseFilterDefault: boolean;
}

export type PlayerChangeFilters = {
    date?: string;
    sessionId?: number;
    playerId?: number;
    guildId?: number;
    unitId?: number;

    wipeChecked: boolean,
    absentChecked: boolean,
    flexChecked: boolean
    partyType: PartyType

    attackFilterChecked: boolean
    defendFilterChecked: boolean,

    elementFilter: boolean[]
    sort?: Sort
}


export const filteredPlayerLogsSelector = selectorFamily({
    key: "filteredPlayerLogsSelector",
    get: (props: PlayerChangeFilters) => ({ get }) => {
        const idMap = get(idMapSelector);
        const unitElementMap = get(unitElementMapSelector);

        let playerLogItems = get(findPlayerLogsSelector({
            playerId: props.playerId,
            sessionId: props.sessionId,
            date: props.date,
            unitId: props.unitId
        }));

        const checkElement = ((playerLogInfo: PlayerLogInfo) => {
            const unit1Element = +unitElementMap[idMap[playerLogInfo.unit1]];
            const unit2Element = +unitElementMap[idMap[playerLogInfo.unit2]];
            const unit3Element = +unitElementMap[idMap[playerLogInfo.unit3]];
            return (props.elementFilter[unit1Element] || props.elementFilter[unit2Element] || props.elementFilter[unit3Element] || props.elementFilter.every((active) => !active));
        });

        const checkAttackParty = (attackPlayerLogInfo: PlayerLogInfo, defendPlayerLogInfo: PlayerLogInfo) => {

            let attackFilterMatched = true;

            if (props.wipeChecked && !(attackPlayerLogInfo.totalActions === 1 && attackPlayerLogInfo.currentNumUnitsAlive === 0)) {
                attackFilterMatched = false;
            }

            if (props.flexChecked && !(defendPlayerLogInfo.totalActions === 1 && defendPlayerLogInfo.currentNumUnitsAlive === 0)) {
                attackFilterMatched = false;
            }

            return attackFilterMatched;
        };

        const checkFilter = (attackPlayerLogInfo: PlayerLogInfo, defendPlayerLogInfo: PlayerLogInfo) => {

            if (props.partyType === "A") {
                let attackElementMatched = checkElement(attackPlayerLogInfo);
                let defendElementMatched = checkElement(defendPlayerLogInfo);
                let attackFilterMatched = checkAttackParty(attackPlayerLogInfo, defendPlayerLogInfo);

                if (!attackFilterMatched) {
                    return false;
                }

                if (props.attackFilterChecked && props.defendFilterChecked) {
                    if (!attackElementMatched && !defendElementMatched) {
                        return false;
                    }
                } else if (props.attackFilterChecked && !props.defendFilterChecked) {
                    if (!attackElementMatched) {
                        return false;
                    }
                } else if (!props.attackFilterChecked && props.defendFilterChecked) {
                    if (!defendElementMatched) {
                        return false;
                    }
                }
            } else {
                let attackElementMatched = checkElement(defendPlayerLogInfo);
                let defendElementMatched = checkElement(attackPlayerLogInfo);
                let attackFilterMatched = checkAttackParty(attackPlayerLogInfo, defendPlayerLogInfo);
                if ((props.wipeChecked || props.flexChecked) && defendPlayerLogInfo.totalActions === 0) {
                    return false;
                }

                if (!attackFilterMatched) {
                    return false;
                }

                if (props.attackFilterChecked && props.defendFilterChecked) {
                    if (!attackElementMatched && !defendElementMatched) {
                        return false;
                    }
                } else if (props.attackFilterChecked && !props.defendFilterChecked) {
                    if (!attackElementMatched) {
                        return false;
                    }
                } else if (!props.attackFilterChecked && props.defendFilterChecked) {
                    if (!defendElementMatched) {
                        return false;
                    }
                }
            }

            return true;

        };

        let filteredPlayerLogItems = playerLogItems.filter(playerLogItem => {
            return playerLogItem.allyLogInfo.partyType === props.partyType
                && (props.guildId ? playerLogItem.allyLogInfo.guildId === props.guildId : true)
                && (props.playerId ? playerLogItem.allyLogInfo.playerId === props.playerId : true)
                && (props.unitId ? (playerLogItem.allyLogInfo.unit1 === props.unitId
                    || playerLogItem.allyLogInfo.unit2 === props.unitId
                    || playerLogItem.allyLogInfo.unit3 === props.unitId) : true)
                && checkFilter(playerLogItem.allyLogInfo, playerLogItem.enemyLogInfo);
        });

        return filteredPlayerLogItems;
    }
});

export const PlayerMatchedPartiesDetailDashlet = (props: PlayerMatchedPartiesDashletProps) => {

    const [elementFilter, setElementFilter] = useState<boolean[]>([false, false, false, false, false, false, false, false, false, false]);

    const [wipeChecked, setWipeChecked] = useState(false);
    const [absentChecked, setAbsentChecked] = useState(false);
    const [flexChecked, setFlexChecked] = useState(false);

    const [attackFilterChecked, setAttackFilterChecked] = useState(props.attackFilterDefault);
    const [defenseFilterChecked, setDefenseFilterChecked] = useState(props.defenseFilterDefault);

    let selectedDate = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(filterSelectedDateAtom);
    let setSelectedDate = useSetRecoilState(filterSelectedDateAtom);

    let playerLogItems = useRecoilValue(filteredPlayerLogsSelector({
        sessionId: props.sessionId,
        date: selectedDate,
        guildId: props.guildId,
        playerId: props.playerId,
        unitId: props.unitId,

        partyType: props.partyType,

        wipeChecked: wipeChecked,
        absentChecked: absentChecked,
        flexChecked: flexChecked,
        attackFilterChecked: attackFilterChecked,
        defendFilterChecked: defenseFilterChecked,
        elementFilter: elementFilter
    }));


    const onElementFilterChange = (element: number) => {
        let filters = [...elementFilter];
        filters[element] = !filters[element];
        setElementFilter(filters);
    };

    const onCalendarChange = (calendarValue: CalendarValueType): calendarValue is Date => {
        let calendarDate = calendarValue as Date;
        startTransition(() => {
            setSelectedDate(convertToLocalDateString(calendarDate));
        });
        return true;
    };

    return (
        <>
            <Fieldset legend={props.partyType === "A" ? "Attack Party Battle Log" : "Defense Party Battle Log"}>
                <div className="flex align-items-center "
                     style={{ columnGap: "10px", rowGap: "10px", flexWrap: "wrap" }}>

                    {!props.hideDateFilter &&
                    <div className="p-inputgroup" style={{ maxWidth: "200px" }}>
                        <span className="p-inputgroup-addon">
                            Date
                        </span>
                        <ReadyDateCalendarInput date={selectedDate} onChange={onCalendarChange} />
                    </div>}
                    {!props.hideElementsFilter &&
                        <div className="p-inputgroup align-items-center"
                             style={{ maxWidth: "280px", columnGap: "3px" }}>
                            <span className="p-inputgroup-addon">Filters</span>
                            <WotvIcon url={"/assets/icons/Element_Icon_Fire.png"} height={25} width={25}
                                      onClick={() => onElementFilterChange(1)} disable={!elementFilter[1]} />
                            <WotvIcon url={"/assets/icons/Element_Icon_Ice.png"} height={25} width={25}
                                      onClick={() => onElementFilterChange(2)} disable={!elementFilter[2]} />
                            <WotvIcon url={"/assets/icons/Element_Icon_Wind.png"} height={25} width={25}
                                      onClick={() => onElementFilterChange(3)} disable={!elementFilter[3]} />
                            <WotvIcon url={"/assets/icons/Element_Icon_Earth.png"} height={25} width={25}
                                      onClick={() => onElementFilterChange(4)} disable={!elementFilter[4]} />
                            <WotvIcon url={"/assets/icons/Element_Icon_Thunder.png"} height={25} width={25}
                                      onClick={() => onElementFilterChange(5)} disable={!elementFilter[5]} />
                            <WotvIcon url={"/assets/icons/Element_Icon_Water.png"} height={25} width={25}
                                      onClick={() => onElementFilterChange(6)} disable={!elementFilter[6]} />
                            <WotvIcon url={"/assets/icons/Element_Icon_Shine.png"} height={25} width={25}
                                      onClick={() => onElementFilterChange(7)} disable={!elementFilter[7]} />
                            <WotvIcon url={"/assets/icons/Element_Icon_Dark.png"} height={25} width={25}
                                      onClick={() => onElementFilterChange(8)} disable={!elementFilter[8]} />
                        </div>
                    }
                    <div className="p-inputgroup" style={{ maxWidth: "100px" }}>
                        <WotvIcon url={"/assets/icons/toilet_paper.png"} height={22} width={30}
                                  title="1st attack failures"
                                  onClick={() => {
                                      if (!wipeChecked) {
                                          setAbsentChecked(false);
                                          setFlexChecked(false);
                                      }

                                      setWipeChecked(!wipeChecked);
                                  }} disable={!wipeChecked} />
                        {!props.hideAbsentFilter &&
                            <div style={{ marginLeft: 5 }}>
                                <WotvIcon url={"/assets/icons/absent_icon.png"} height={22} width={22}
                                          title="Absent / missed attacks"
                                          onClick={() => {
                                              if (!absentChecked) {
                                                  setWipeChecked(false);
                                                  setFlexChecked(false);
                                              }

                                              setAbsentChecked(!absentChecked);

                                          }} disable={!absentChecked} />
                            </div>
                        }
                        <div style={{ marginLeft: 10 }}>
                            <WotvIcon url={"/assets/icons/flex_icon.png"} height={20} width={20}
                                      title="Fresh team clears"
                                      onClick={() => {
                                          if (!flexChecked) {
                                              setWipeChecked(false);
                                              setAbsentChecked(false);
                                          }

                                          setFlexChecked(!flexChecked);
                                      }} disable={!flexChecked} />
                        </div>
                    </div>
                    <div className="p-inputgroup align-items-center" style={{ maxWidth: "220px" }}>
                        <span className="p-inputgroup-addon"> Elemental Filters for</span>

                        <div style={{ marginLeft: 10 }}>
                            <WotvIcon url={"/assets/gb/guildbattle_icon_battle_amount.png"} height={25} width={25}
                                      onClick={() => setAttackFilterChecked(!attackFilterChecked)}
                                      disable={!attackFilterChecked} />
                        </div>

                        <div style={{ marginLeft: 10 }}>
                            <WotvIcon url={"/assets/icons/shield.png"} height={25} width={25}
                                      onClick={() => setDefenseFilterChecked(!defenseFilterChecked)}
                                      disable={!defenseFilterChecked} />
                        </div>
                    </div>

                    {/*                    <div className="p-inputgroup align-items-center"
                         style={{ maxWidth: "165px" }}>
                        <span className="p-inputgroup-addon">Sort</span>
                        <Dropdown optionLabel="label" optionValue="value" value={sort} options={sortOptions}
                                  onChange={(e) => setSort(e.value)}
                                  itemTemplate={sortItemTemplate}
                                  placeholder="Sort" />
                    </div>*/}

                </div>
            </Fieldset>
            <PlayerMatchedPartiesDetailDataView guildId={props.guildId} sessionId={props.sessionId}
                                                playerId={props.playerId}
                                                date={props.date} partyType={props.partyType}
                                                playerLogItems={playerLogItems}
                                                hideLegend={!props.playerId} />
        </>);
};