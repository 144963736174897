import * as React from "react";
import { useRef, useState, useTransition } from "react";
import { selectorFamily, useRecoilValue } from "recoil";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { InputText, InputTextProps } from "primereact/inputtext";
import { useDebounce } from "react-use";
import { SearchRequestProps, searchSelector } from "../../service/SearchService";
import { Toast } from "primereact/toast";
import { OverlayPanel } from "primereact/overlaypanel";
import { CircleLoader } from "react-spinners";


export interface GlobalSearchBarProps {

    inputTextProps?: InputTextProps;
}

export interface PlayerSearchRowItem extends GuildSearchRowItem {
    playerId: number;
    playerName: string;
    playerMatchedName?: string;
}

export interface GuildSearchRowItem {
    guildId: number;
    guildName: string;
    guildMatchedName?: string;
    guildRank?: number;
    type: string;
}

export const searchRowItemSelector = selectorFamily({
    key: "searchRowItemSelector",
    get: (props: SearchRequestProps) => ({ get }) => {
        if (props.query.length === 0) {
            return {
                playerSearchRowItems: [],
                guildSearchRowItems: []
            };
        }

        const searchResponse = get(searchSelector({ ...props }));

        let playerSearchRowItems: PlayerSearchRowItem[] = [];
        let guildSearchRowItems: GuildSearchRowItem[] = [];

        const guildIds = new Set<number>();
        const playerIds = new Set<number>();

        let searchString = props.query.toLowerCase();

        for (const playerSearchResult of searchResponse.playerSearchResults) {
            let latestPartyItem = playerSearchResult.latestPartyItem;
            let playerChangeEventItem = playerSearchResult.playerChangeEventItem;

            let playerId = latestPartyItem.playerId;

            if (playerIds.has(playerId)) {
                continue;
            }

            playerIds.add(playerId);

            let currentName = latestPartyItem.playerName;
            let oldName = playerChangeEventItem.oldName;
            let newName = playerChangeEventItem.name;


            let foundName = "";

            if (!currentName.toLowerCase().includes(searchString)) {
                if (newName.toLowerCase().includes(searchString)) {
                    foundName = newName;
                } else if (oldName.toLowerCase().includes(searchString)) {
                    foundName = oldName;
                }
            }

            playerSearchRowItems.push({
                playerId: playerId,
                playerName: currentName,
                guildId: latestPartyItem.guildId,
                guildName: latestPartyItem.guildName,
                playerMatchedName: foundName,
                type: "PCE"
            });
        }

        for (const guildSearchResult of searchResponse.guildSearchResults) {
            let latestGuildBattleItem = guildSearchResult.latestGuildBattleItem;
            let guildChangeEventItem = guildSearchResult.guildChangeEventItem;

            let guildId = latestGuildBattleItem.guildId;

            if (guildIds.has(guildId)) {
                continue;
            }

            guildIds.add(guildId);

            let currentName = latestGuildBattleItem.guildName;
            let guildNameOld = guildChangeEventItem.oldName;
            let guildNameNew = guildChangeEventItem.name;

            let foundName = "";

            if (!currentName.toLowerCase().includes(searchString)) {
                if (guildNameNew.toLowerCase().includes(searchString)) {
                    foundName = guildNameNew;
                } else if (guildNameOld.toLowerCase().includes(searchString)) {
                    foundName = guildNameOld;
                }
            }

            guildSearchRowItems.push({
                guildId: guildId,
                guildName: currentName,
                guildMatchedName: foundName,
                guildRank: latestGuildBattleItem.guildRank,
                type: "GCE"
            });
        }

        playerSearchRowItems.sort((a, b) => a.playerName.localeCompare(b.playerName));
        guildSearchRowItems.sort((a, b) => a.guildRank! - b.guildRank!);

        return {
            playerSearchRowItems: playerSearchRowItems,
            guildSearchRowItems: guildSearchRowItems
        };
    }
});

export const GlobalSearchBar = (props: GlobalSearchBarProps) => {
    const navigate = useNavigate();
    const [inTransition, startTransition] = useTransition();
    const [loading, setLoading] = useState(false);

    const [searchText, setSearchText] = useState("");

    const [debouncedSearchText, setDebouncedSearchText] = useState<string>("");

    const op = useRef<OverlayPanel>(null);
    const toast = useRef<Toast>(null);

    const [, cancel] = useDebounce(
        () => {
            startTransition(() => {
                setDebouncedSearchText(searchText);
                setLoading(false);
            });
        },
        250,
        [searchText]
    );

    const onSearchChange = (value: string) => {
        setLoading(true);
        let trimmedValue = value.trim();

        if (!trimmedValue) {
            op.current?.hide();
            setLoading(false);
        }

        setSearchText(trimmedValue);
    };

    const searchRowItemsMap = useRecoilValue(searchRowItemSelector({
        query: debouncedSearchText
    }));

    const onGuildNameClick = (guildId: number) => {
        startTransition(() => {
            op.current?.hide();
            navigate("/guild/" + guildId);
        });
    };

    const onNameClick = (playerId: number) => {
        startTransition(() => {
            op.current?.hide();
            navigate("/player/" + playerId);
        });
    };

    const formatPlayerRowContent = (playerSearchRowItem: PlayerSearchRowItem): JSX.Element => {
        return (<>
            <Button className="p-button-raised p-button-secondary"
                    onClick={() => onNameClick(playerSearchRowItem.playerId!)}>{playerSearchRowItem.playerName}
                {playerSearchRowItem.playerMatchedName ? " ( " + playerSearchRowItem.playerMatchedName + " )" : ""}
            </Button>
        </>);
    };

    const formatGuildName = (guildSearchRowItem: GuildSearchRowItem): JSX.Element => {
        return (<>
            <Button className="p-button-raised p-button-secondary"
                    onClick={() => onGuildNameClick(guildSearchRowItem.guildId)}>{guildSearchRowItem.guildName}
                {guildSearchRowItem.guildMatchedName ? " ( " + guildSearchRowItem.guildMatchedName + " )" : ""}
            </Button>
        </>);
    };

    return (<>
            <Toast ref={toast} />

            <span className="p-input-icon-left" style={{marginLeft: 5}}>
                    <i className="pi pi-search" />
            <InputText {...props.inputTextProps} style={{width: 125}} placeholder="Search" type="text"
                       onFocus={event => {
                           if (event.currentTarget.value) {
                               op.current?.show(event, event.currentTarget);
                           }
                       }}
                       onChange={event => {
                           op.current?.show(event, event.currentTarget);
                           onSearchChange(event.currentTarget.value);
                       }
                       }
            />

        </span>
            <OverlayPanel
                ref={op}
                style={{ width: "400px" }}
            >

                {loading &&
                    <div className={"flex justify-content-center"} style={{ width: "100%" }}>
                        <CircleLoader color={"white"} />
                    </div>
                }

                {!loading &&
                    <DataTable value={searchRowItemsMap.playerSearchRowItems} dataKey="id"
                               rowHover rows={5} paginator responsiveLayout="scroll">
                        <Column field="name" header={"Players (" + searchRowItemsMap.playerSearchRowItems.length + ")"}
                                body={(data) => formatPlayerRowContent(data)}></Column>
                        <Column field="guildName" header="Last Known Guild" style={{ width: "150px" }}
                                body={(data) => formatGuildName(data)}></Column>
                    </DataTable>}
                {!loading &&
                    <DataTable value={searchRowItemsMap.guildSearchRowItems} dataKey="id"
                               rowHover rows={5} paginator responsiveLayout="scroll">
                        <Column field="guildName"
                                header={"Guilds (" + searchRowItemsMap.guildSearchRowItems.length + ")"}
                                body={(data) => formatGuildName(data)}></Column>
                        <Column field="guildName" header="Last Known Rank" style={{ width: "150px" }}
                                body={(data) => data.guildRank}></Column>
                    </DataTable>
                }
            </OverlayPanel>
        </>
    );
};