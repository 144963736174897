import * as React from 'react';
import {GuildRankingDashlet} from "../components/dashlet/GuildRankingDashlet";
import {MetaUnitChartPanel} from "../components/chart/MetaUnitChartPanel";
import {GuildRosterActivityDashlet} from "../components/dashlet/GuildRosterActivityDashlet";

const DashboardPage = () => {
    return (
        <div className="layout-dashboard" style={{marginTop: "5px"}}>
            <div className="grid">
                <div className="col-12 md:col-12 lg:col-8 xl:col-8">
                    <div className="card widget-table">
                        <GuildRankingDashlet/>
                    </div>
                </div>
                <div className="col-12 md:col-12 lg:col-4 xl:col-4">
                    <div className="card widget-table">
                        <GuildRosterActivityDashlet guildId={0} hideDateColumn={true} sort={"RANK"}/>
                    </div>
                </div>

                <div className="col-12 lg:col-6">
                    <div className="card widget-table">
                        <MetaUnitChartPanel maxUnitRank={15} showDashboardLegend partyType={"A"} hideElementsFilter hideMaxResultsFilter hideAbsentFilter/>
                    </div>
                </div>
                <div className="col-12 lg:col-6">
                    <div className="card widget-table">
                        <MetaUnitChartPanel maxUnitRank={15} showDashboardLegend partyType={"D"} hideElementsFilter hideMaxResultsFilter hideAbsentFilter/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardPage;
