import React, { startTransition } from "react";
import {PartyImage} from "./PartyImage";
import StarImage from "./icon/StarImage";
import {PartyItem, PlayerLogInfo, PlayerLogItem} from "../types/wotv-guild-data-api";
import {PositionImage} from "./icon/PositionImage";
import {ShieldImage} from "./icon/ShieldImage";
import {WotvIcon} from "./icon/WotvIcon";
import { useNavigate } from "react-router-dom";

export interface PlayerLogItemExtended {
    onNameClick?: (playerLogItem: PlayerLogInfo) => void;
    onGuildClick?: (playerLogItem: PlayerLogInfo) => void;
    onSwordClick?: (playerLogItem: PlayerLogInfo) => void;
    guildRank?: number;
    allyLogInfo: PlayerLogInfo;
    enemyLogInfo: PlayerLogInfo;
}

const PlayerPartyDetailImage = (props: PlayerLogItemExtended) => {
    const navigate = useNavigate();

    let allyLogInfo = props.allyLogInfo;
    let enemyLogInfo = props.enemyLogInfo;

    const onNameClick = (playerLogInfo: PlayerLogInfo) => {
            startTransition(() => {
                navigate("/player/" + playerLogInfo.playerId);
                window.scrollTo({top: 0, behavior: "smooth"});
            });
    };

    const onGuildClick = props.onGuildClick || (() => {
    });
    const onSwordClick = props.onSwordClick || (() => {
    });

    let width = 210;

    return <div style={{
        height: "85px",
        width: "20px",
        minWidth: width,
        backgroundRepeat: "no-repeat",
        backgroundSize: width + "px 85px",
        margin: "0px",
        padding: "0px",
        borderSpacing: "0px",
        // paddingLeft: "20px",
        backgroundImage: allyLogInfo.partyType === "A" ? "url(/assets/gb/guildbattle_list_player_wide_v3.png)" : "url(/assets/gb/guildbattle_list_enemy_wide_v3.png)"
        // backgroundColor: partyItem.partyType === "A" ? "rgba(51,73,255,0.40)" : "rgba(255,51,51,0.4)",
        // borderRadius: "10px"
    }}>
        <div className="flex justify-content-between cursor-pointer" style={{padding: "4px 13px 0px 13px"}}>
                    <span
                        onClick={(event) => onNameClick(allyLogInfo)}
                        style={{
                            width: width,
                            lineHeight: "20px",
                            fontWeight: 500,
                            color: "white",
                            marginLeft: "0px",
                            fontSize: "13px"
                            // width: "110px",
                        }}>{allyLogInfo.playerName}<span style={{marginLeft: "5px"}}><PositionImage
                        guildPosition={allyLogInfo.guildPosition}/></span>
                    </span>
        </div>
        <div className={"flex justify-content-start"} style={{padding: "4px 0px 0px 15px"}}>
            <div style={{width: width - 63}}><PartyImage {...allyLogInfo} unit1Status={allyLogInfo.unit1Status}
                                                         unit2Status={allyLogInfo.unit2Status}
                                                         unit3Status={allyLogInfo.unit3Status}
            /></div>

            <div className={"flex flex-column"} style={{ marginTop: props.guildRank ? -5 : 0 }}>

                {allyLogInfo.partyType === "D" && <div style={{
                    marginLeft: 2, marginTop: 10,
                    cursor: props.onSwordClick ? "pointer" : "default"
                }}
                                                       onClick={() => onSwordClick(allyLogInfo)}><ShieldImage /><span
                    style={{
                        marginLeft: "5px",
                        fontWeight: 500
                    }}>{allyLogInfo.actionNumber}/{allyLogInfo.totalActions}</span></div>}

                {allyLogInfo.partyType === "A" &&
                    <div><StarImage /><span style={{
                        marginLeft: 4,
                        fontWeight: 500
                    }}>{enemyLogInfo.previousNumUnitsAlive - enemyLogInfo.currentNumUnitsAlive}</span></div>}

                {allyLogInfo.partyType === "A" &&
                    <div style={{
                        cursor: props.onSwordClick ? "pointer" : "default"
                    }}
                         onClick={() => onSwordClick(allyLogInfo)}>
                        <WotvIcon url={"/assets/gb/guildbattle_icon_battle_amount.png"} height={15} width={15} />
                        <span style={{
                            marginLeft: "2px",
                            fontWeight: 500
                        }}>{allyLogInfo.actionNumber}/{allyLogInfo.totalActions}</span>
                    </div>}
                {props.guildRank &&
                <div className={"flex align-items-center"}
                     style={{ marginTop: allyLogInfo.partyType === "A" ? "0px" : "3px" }}
                ><WotvIcon url={"/assets/icons/mili_rank.png"} height={15} width={15}
                           title={"Median guild rank"} /><span
                    style={{
                        marginLeft: allyLogInfo.partyType === "A" ? "4px" : "6px",
                        fontWeight: 500,
                    }}>{props.guildRank}</span></div>}
            </div>
        </div>
    </div>;
};

export default PlayerPartyDetailImage;