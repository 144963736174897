import { useRecoilValue_TRANSITION_SUPPORT_UNSTABLE } from "recoil";
import { guildBattleByGuildId } from "../../service/GuildBattleDataService";
import GuildBattleHistoryTable from "../table/GuildBattleHistoryTable";
import React, { useTransition } from "react";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { convertLocalDataArrayToString } from "../../utilities/WotvDataUtil";

export interface GuildBattleInfoDashletProps {
    guildId: number;
    sessionId?: number;
    date?: string;

    playerId?: number;
}

export const GuildBattleInfoDashlet = (props: GuildBattleInfoDashletProps) => {
    const navigate = useNavigate();
    const [inTransition, startTransition] = useTransition();

    const guildBattleItems = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(guildBattleByGuildId({
        guildId: 0,
        date: props.date
    }));

    let allyGuildBattle = [...guildBattleItems].filter(value => value.guildId === props.guildId).pop()!;
    let enemyGuildBattle = [...guildBattleItems].filter(value => value.guildId === allyGuildBattle!.enemyGuildId).pop()!;

    const onClickSwitchView = () => {
        startTransition(() => {
            navigate("/guild/" + enemyGuildBattle.guildId + "/" + convertLocalDataArrayToString(enemyGuildBattle.date) + "/" + enemyGuildBattle.sessionId);
            window.scrollTo({ top: 0, behavior: "smooth" });
        });
    };

    return (
        <>
            <Fieldset legend={"Battle Summary"}>
                <div className="flex justify-content-start"
                     style={{ columnGap: "10px", rowGap: "10px", flexWrap: "wrap" }}>
                        <div className="p-inputgroup" style={{ maxWidth: "300px" }}>
                        <span className="p-inputgroup-addon">
                            Point of View
                        </span>
                            <InputText value={allyGuildBattle.guildName} readOnly></InputText>
                        </div>
                        <div className="p-inputgroup" style={{ maxWidth: "150px" }}>
                        <span className="p-inputgroup-addon">
                            Date
                        </span>
                            <InputText value={props.date} readOnly></InputText>
                        </div>
                    <Button label="Switch View" icon="pi pi-sync" onClick={event => onClickSwitchView()} />
                </div>
            </Fieldset>
            <GuildBattleHistoryTable
                guildBattleItems={[allyGuildBattle!, enemyGuildBattle!]}
                tableId={"guild-battle-detail-table"}
                hidePaginator
                hideEnemyGuildName
                hideEnemyStars
                hideDate
            />
        </>
    );
};