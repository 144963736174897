import {CartesianGrid, Dot, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {convertLocalDataArrayToString, convertLocalDateArrayToDate} from "../../utilities/WotvDataUtil";
import * as React from 'react'
import {ReactElement, useTransition} from 'react'
import {GuildBattleItem} from "../../types/wotv-guild-data-api";
import {compareAsc} from "date-fns";
import {TooltipProps} from "recharts/types/component/Tooltip";
import {WinLoseDrawImage} from "../../graphics/WinLoseDrawImage";
import {useNavigate} from "react-router-dom";


export interface RankProgressionChartDataItem {
    rank: number;
    date: string;
}

export interface RankProgressionLineChartProps {
    guildBattleItems: GuildBattleItem[],
    title?: string,
}

export const RankProgressionLineChart = (props: RankProgressionLineChartProps) => {
    const navigate = useNavigate();
    const [inTransition, startTransition] = useTransition();

    let minRange = Math.floor(Math.min(...props.guildBattleItems.map(value => value.guildRank)) / 10) * 10;
    let maxRange = Math.ceil(Math.max(...props.guildBattleItems.map(value => value.guildRank)) / 10) * 10;
    const domain = [
        minRange === 0 ? 1 : minRange,
        maxRange
    ]

    const sortedGuildBattleItems = [...props.guildBattleItems];

    sortedGuildBattleItems.sort((a, b) => compareAsc(convertLocalDateArrayToDate(a.date), convertLocalDateArrayToDate(b.date)))

    const customTooltipContent = (props: TooltipProps<number, string>): JSX.Element => {
        if (props.active && props.payload && props.payload.length) {
            let payload = props.payload[0].payload;
            return (
                <div className="card custom-tooltip" style={{background: "rgba(0, 0, 0, 0.60)"}}>
                    <div><span>{payload.guildName}</span></div>
                    <div><span>Date: {convertLocalDataArrayToString(payload.date)}</span></div>
                    <div><span>Rank: {payload.guildRank}</span></div>
                    <div><span>Trophies: {payload.leagueTrophy}</span></div>
                    <div><span>Opponent: {payload.enemyGuildName}</span> <WinLoseDrawImage guildBattleItem={payload}
                                                                                  height={18}/></div>
                </div>
            );
        }

        return <></>;
    };

    // @ts-ignore
    const CustomizedDot = ({ cx, cy, stroke, payload, value, fill, r, index, strokeWidth }): ReactElement<SVGElement> => {

        let guildBattleItem = payload as GuildBattleItem;

        if (guildBattleItem.outcome === 1){
            return <Dot cx={cx} cy={cy} r={r} stroke={"rgba(0,255,4,0.8)"} fill={"rgba(0,255,4,0.8)"} strokeWidth={strokeWidth}></Dot>
        } else if (guildBattleItem.outcome === 2){
            return <Dot cx={cx} cy={cy} r={r} stroke={"#f11717"} fill={"#f11717"} strokeWidth={strokeWidth}></Dot>
        } else {
            return <Dot cx={cx} cy={cy} r={r} stroke={"rgba(255,255,255,0.85)"} fill={"rgba(255,255,255,0.85)"} strokeWidth={strokeWidth}></Dot>
        }
    };

    const onDotClick = (guildBattleItem: GuildBattleItem) => {
            startTransition(() => {
                navigate("/guild/" + guildBattleItem.guildId + "/" + convertLocalDataArrayToString(guildBattleItem.date) + "/" + guildBattleItem.sessionId);
            })
    }

    return (
        <ResponsiveContainer height={450}>
            <LineChart data={sortedGuildBattleItems}
                       margin={{top: 30, right: 10, left: -20}}
                       // onClick={(e, payload) => {onDotClick(e.activePayload![0].payload)}}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey={item => convertLocalDataArrayToString(item.date)} style={{fill: "#FFFFFF"}}/>
                <YAxis tickCount={10} domain={domain} reversed allowDecimals={false}
                       style={{fill: "#FFFFFF"}}/>
                <Line type="monotone" dataKey="guildRank"
                      isAnimationActive={false}
                      strokeWidth={"3px"}
                      dot={CustomizedDot}
                />
                <Tooltip content={customTooltipContent}/>
            </LineChart>
        </ResponsiveContainer>
    );
}