import {atom, selector} from "recoil";
import {addDays, compareDesc, format, isBefore, max, min, parse} from "date-fns";
import {apiUrl} from "../Config";
import {UnitMapItem} from "../types/wotv-guild-data-api";

export const dataReadyDatesSelector = selector({
    key: 'dataReadyDatesSelector',
    get: ({get}) => {
        let metaUrl = apiUrl() + "/config/dates";
        return fetch(metaUrl)
            .then(response => response.json())
            .then((dateStrings: string[]) => {

                let dates = dateStrings.map((item) => parse(item, "yyyy-MM-dd", new Date()));
                dates.sort(compareDesc);
                return dates;
            })
    }
});

export const invalidDatesSelector = selector({
    key: 'invalidDatesSelector',
    get: ({get}) => {
        const validDates = get(dataReadyDatesSelector);

        let currentDate = min(validDates);

        let invalidDates = [];

        let validDateStrings = validDates.map((date) => format(date, "yyyy-MM-dd"));

        while (isBefore(currentDate, max(validDates))) {
            if (!validDateStrings.includes(format(currentDate, "yyyy-MM-dd"))) {
                invalidDates.push(new Date(currentDate));
            }
            currentDate = addDays(currentDate, 1);
        }

        return invalidDates;
    }
});


const fetchUnitMap = () => {
    let metaUrl = apiUrl() + "/config/unitmap";
    return fetch(metaUrl)
        .then(response => response.json())
        .then((item: UnitMapItem) => {
            return item["unitNameMap"];
        })
}

export const unitMapAtom = atom({
    key: 'unitMapAtom',
    default: fetchUnitMap()
});

export const idMapSelector = selector({
    key: 'idMapSelector',
    get: ({get}) => {
        let unitMap = get(unitMapAtom);

        let idMap = Object.fromEntries(Object.entries(unitMap).map(([k, v]) => [v, k]))

        idMap[0] = "~UNREVEALED~";

        return idMap as { [index: string]: string };
    }
});