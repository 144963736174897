import * as React from 'react'
import * as ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
// import reportWebVitals from './reportWebVitals';
import {RecoilRoot} from "recoil";
import App from "./App";

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(rootElement);

root.render(
    <React.StrictMode>
        <HashRouter>
            <RecoilRoot>
                <App></App>
            </RecoilRoot>
        </HashRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
