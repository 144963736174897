import React from "react";
import {PositionType} from "../../types/wotv-guild-data-api";

export type PositionImageProps = {
    guildPosition: PositionType
    size?: number
}

export const PositionImage = (props: PositionImageProps) => {
    let positionImg = <></>

    if (props.guildPosition === "S" || props.guildPosition === "L") {
        positionImg = <span><img style={{
            width: props.size || 18,
            height: props.size || 18,
            lineHeight: props.size || 18,
            verticalAlign: "middle"
        }} src={"/assets/icons/" + props.guildPosition + ".png"} alt={""}/></span>
    }

    return positionImg;
}