import * as React from "react";
import { Fieldset } from "primereact/fieldset";

export const ContactPage = () => {
    return (
        <div className="layout-dashboard" style={{ marginTop: "5px" }}>
            <div className="grid">
                <div className="col-12">
                    <div className={"flex justify-content-center"}>
                        <span className={"text-3xl"}>Contact</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card widget-table">
                        <Fieldset legend={<span className={"text-cyan-400 line-height-2"}>Feel free to reach out</span>}>
                            <p className={"line-height-4"}>
                                If you have any questions or just want to chat about something, you can find me here.
                            </p>
                            <p className={"line-height-4"}>
                                <span className={"pi pi-discord"}> Discord: Aryantes#0555</span>
                            </p>
                            <p className={"line-height-4"}>
                                <span className={"pi pi-reddit"}> Reddit: /u/aryantes</span>
                            </p>
                        </Fieldset>
                    </div>
                </div>
            </div>
        </div>
    );
};
