import {max, min, parse} from "date-fns";
import {Calendar, CalendarProps, CalendarValueType} from "primereact/calendar";
import * as React from "react";
import {useRecoilValue_TRANSITION_SUPPORT_UNSTABLE} from "recoil";
import {dataReadyDatesSelector, invalidDatesSelector} from "../../service/ConfigService";
import {convertLocalDateStringToDate} from "../../utilities/WotvDataUtil";

export interface ReadyDateCalendarInputProps {
    date: string,
    onChange: (e: CalendarValueType) => void

    calendarProps?: CalendarProps

    minDate?: string
    maxDate?: string
}

export const ReadyDateCalendarInput = (props: ReadyDateCalendarInputProps) => {
    const readyDates = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(dataReadyDatesSelector);
    const invalidDates = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(invalidDatesSelector);

    const minimumValidDate = props.minDate ? convertLocalDateStringToDate(props.minDate) : min(readyDates);
    const maximumValidDate = props.maxDate ? convertLocalDateStringToDate(props.maxDate) : max(readyDates);

    return (
        <Calendar {...props.calendarProps}
                  showIcon
                  value={parse(props.date, "yyyy-MM-dd", new Date())}
                  minDate={minimumValidDate}
                  maxDate={maximumValidDate}
                  readOnlyInput
                  disabledDates={invalidDates}
                  onChange={(e) => props.onChange(e.value)}></Calendar>
    );
}