import { convertLocalDateStringToDate, convertToLocalDateString } from "../../utilities/WotvDataUtil";
import * as React from "react";
import { useState, useTransition } from "react";
import { useRecoilValue } from "recoil";
import { lineChartMetaData } from "../../service/MetaService";
import { PartyType } from "../../types/wotv-guild-data-api";
import { Fieldset } from "primereact/fieldset";
import { useNavigate } from "react-router-dom";
import { UnitMetaLineChart } from "./UnitMetaLineChart";
import { ReadyDateCalendarInput } from "../wotv/ReadyDateCalendarInput";
import { CalendarValueType } from "primereact/calendar";
import { useDebounce } from "react-use";
import { InputNumber } from "primereact/inputnumber";
import { isAfter, isBefore } from "date-fns";
import { WotvIcon } from "../../graphics/icon/WotvIcon";

export interface MetaUnitChartPanelProps {
    minRank?: number;
    maxRank?: number;
    fromDate?: string;
    toDate?: string;
    guildId?: number;
    numBattlesBack?: number;

    showDashboardLegend?: boolean;

    hideRankInputs?: boolean;
    hideElementsFilter?: boolean;
    hideMaxResultsFilter?: boolean;
    hideAbsentFilter?: boolean;
    unitIdOnly?: number;

    chartHeight?: number;

    partyType: PartyType;
    title?: string;
    maxUnitRank?: number
}

export const MetaUnitChartPanel = (props: MetaUnitChartPanelProps) => {
    const navigate = useNavigate();
    const [inTransition, startTransition] = useTransition();
    const [calendarFromDateString, setCalendarFromDateString] = useState<string>(props.fromDate!);
    const [calendarToDateString, setCalendarToDateString] = useState<string>(props.toDate!);
    const [minRank, setMinRank] = useState<number>(1);
    const [maxRank, setMaxRank] = useState<number>(500);
    const [debouncedMinRank, setDebouncedMinRank] = useState<number>(1);
    const [debouncedMaxRank, setDebouncedMaxRank] = useState<number>(500);
    const [maxUnitRank, setMaxUnitRank] = useState<number>(props.maxUnitRank || 10);
    const [minUnitRank, setMinUnitRank] = useState<number>(1);
    const [elementFilter, setElementFilter] = useState<boolean[]>([false, false, false, false, false, false, false, false, false, false]);
    const [absentChecked, setAbsentChecked] = useState(true);

    const [, cancel] = useDebounce(
        () => {
            startTransition(() => {
                if (minRank > maxRank) {
                    setMinRank(maxRank);
                }
                setDebouncedMinRank(Math.min(maxRank, minRank));
            });
        },
        750,
        [minRank]
    );

    const [, cancel2] = useDebounce(
        () => {
            startTransition(() => {
                if (maxRank < minRank) {
                    setMaxRank(minRank);
                }
                setDebouncedMaxRank(Math.max(maxRank, minRank));
            });
        },
        750,
        [maxRank]
    );

    const onElementFilterChange = (element: number) => {
        let filters = [...elementFilter];
        filters[element] = !filters[element];
        setElementFilter(filters);
    };

    const metaAggregate = useRecoilValue(lineChartMetaData({
        guildId: props.guildId,
        minRank: debouncedMinRank,
        maxRank: debouncedMaxRank,
        fromDate: calendarFromDateString,
        toDate: calendarToDateString,
        maxUnitRank: maxUnitRank,
        minUnitRank: minUnitRank,
        elementFilter: elementFilter,
        numBattlesBack: props.numBattlesBack,
        absentFilterChecked: absentChecked,
        unitIdOnly: props.unitIdOnly
    }));

    let chartData = props.partyType === "A" ? metaAggregate.attackUnitData : metaAggregate.defendUnitData;
    let chartKeys = props.partyType === "A" ? metaAggregate.attackDataKeys : metaAggregate.defendDataKeys;
    let chartTitle = props.title || (props.partyType === "A" ? "Attack Teams: Unit Usage" : "Defense Teams: Unit Usage");
    let chartDomain = props.partyType === "A" ? metaAggregate.attackDomain : metaAggregate.defendDomain;

    let maxKeys = props.guildId ? (props.partyType === "A" ? metaAggregate.attackDataKeys.length : metaAggregate.defendDataKeys.length) : Math.max(metaAggregate.attackDataKeys.length, metaAggregate.defendDataKeys.length);

    const onCalendarFromChange = (calendarValue: CalendarValueType): calendarValue is Date => {
        let calendarFromDate = calendarValue as Date;
        let calendarToDate = calendarToDateString ? convertLocalDateStringToDate(calendarToDateString) : metaAggregate.maxDate;

        if (isAfter(calendarFromDate, calendarToDate)) {
            calendarFromDate = new Date(calendarToDate.getTime());
        }
        startTransition(() => {
            setCalendarFromDateString(convertToLocalDateString(calendarFromDate));
        });
        return true;
    };

    const onCalendarToChange = (calendarValue: CalendarValueType) => {
        let calendarToDate = calendarValue as Date;
        let calendarFromDate = calendarFromDateString ? convertLocalDateStringToDate(calendarFromDateString) : metaAggregate.minDate;

        if (isBefore(calendarToDate, calendarFromDate)) {
            calendarToDate = new Date(calendarFromDate.getTime());
        }
        startTransition(() => {
            setCalendarToDateString(convertToLocalDateString(calendarToDate));
        });
        return true;
    };

    return (<>
            <Fieldset legend={chartTitle} style={{ marginBottom: "10px" }}>
                <div className="flex justify-content-start"
                     style={{ columnGap: "10px", rowGap: "10px", flexWrap: "wrap" }}>
                    <div className="p-inputgroup" style={{ maxWidth: "200px" }}>
                        <span className="p-inputgroup-addon">From</span>
                        <ReadyDateCalendarInput
                            date={metaAggregate.minDate ? convertToLocalDateString(metaAggregate.minDate) : calendarFromDateString}
                            onChange={onCalendarFromChange} />
                    </div>

                    <div className="p-inputgroup" style={{ maxWidth: "200px" }}>
                        <span className="p-inputgroup-addon">To</span>
                        <ReadyDateCalendarInput
                            date={metaAggregate.maxDate ? convertToLocalDateString(metaAggregate.maxDate) : calendarFromDateString}
                            onChange={onCalendarToChange} />
                    </div>
                    {!props.hideRankInputs && <div className="p-inputgroup" style={{ maxWidth: "200px" }}>
                        <span className="p-inputgroup-addon">Max Guild Rank</span>
                        <InputNumber value={maxRank} min={1} max={500} showButtons
                                     onChange={(e) => setMaxRank(e.value!)}></InputNumber>
                    </div>}
                    {!props.hideRankInputs && <div className="p-inputgroup" style={{ maxWidth: "200px" }}>
                        <span className="p-inputgroup-addon">Min. Guild Rank</span>
                        <InputNumber value={minRank} min={1} max={500} showButtons
                                     onChange={(e) => setMinRank(e.value!)}></InputNumber>
                    </div>}
                    {(!props.hideMaxResultsFilter && !props.unitIdOnly)&&
                        <>
                            <div className="p-inputgroup" style={{ maxWidth: "200px" }}>
                                <span className="p-inputgroup-addon">Max Unit Rank</span>
                                <InputNumber value={maxUnitRank} min={1} max={999} showButtons
                                             onChange={(e) => setMaxUnitRank(e.value!)}></InputNumber>
                            </div>
                            <div className="p-inputgroup" style={{ maxWidth: "200px" }}>
                                <span className="p-inputgroup-addon">Min Unit Rank</span>
                                <InputNumber value={minUnitRank} min={1} max={999} showButtons
                                             onChange={(e) => setMinUnitRank(e.value!)}></InputNumber>
                            </div>
                        </>
                    }
                    {(!props.hideElementsFilter && !props.unitIdOnly) &&
                        <div className="p-inputgroup align-items-center" style={{ maxWidth: "275px", columnGap:"3px"}}>
                            <span className="p-inputgroup-addon">Filters</span>

                            <WotvIcon url={"/assets/icons/Element_Icon_Fire.png"} height={25} width={25} onClick={() => onElementFilterChange(1)} disable={!elementFilter[1]} />
                            <WotvIcon url={"/assets/icons/Element_Icon_Ice.png"} height={25} width={25} onClick={() => onElementFilterChange(2)} disable={!elementFilter[2]} />
                            <WotvIcon url={"/assets/icons/Element_Icon_Wind.png"} height={25} width={25} onClick={() => onElementFilterChange(3)} disable={!elementFilter[3]} />
                            <WotvIcon url={"/assets/icons/Element_Icon_Earth.png"} height={25} width={25} onClick={() => onElementFilterChange(4)} disable={!elementFilter[4]} />
                            <WotvIcon url={"/assets/icons/Element_Icon_Thunder.png"} height={25} width={25} onClick={() => onElementFilterChange(5)} disable={!elementFilter[5]} />
                            <WotvIcon url={"/assets/icons/Element_Icon_Water.png"} height={25} width={25} onClick={() => onElementFilterChange(6)} disable={!elementFilter[6]} />
                            <WotvIcon url={"/assets/icons/Element_Icon_Shine.png"} height={25} width={25} onClick={() => onElementFilterChange(7)} disable={!elementFilter[7]} />
                            <WotvIcon url={"/assets/icons/Element_Icon_Dark.png"} height={25} width={25} onClick={() => onElementFilterChange(8)} disable={!elementFilter[8]} />
                        </div>
                    }
                    {!props.hideAbsentFilter &&
                        <div className="p-inputgroup align-items-center" style={{ maxWidth: "25px", columnGap:"3px"}}>
                            <WotvIcon url={"/assets/icons/absent_icon.png"} height={22} width={22} title="Absent / missed attacks"
                                      onClick={() => {
                                          setAbsentChecked(!absentChecked);

                                      }} disable={!absentChecked} />
                        </div>
                    }
                </div>
            </Fieldset>
            <UnitMetaLineChart chartHeight={props.chartHeight} data={chartData} keys={chartKeys} maxKeys={maxKeys} domain={chartDomain} individualUnitOnly={!!props.unitIdOnly} />
        </>
    );
};