import {selectorFamily, useRecoilValue} from "recoil";
import {findPartiesSelector} from "../../service/GuildBattleDataService";
import * as React from "react";
import {Fieldset} from "primereact/fieldset";
import { compareAsc, compareDesc, isEqual } from "date-fns";
import {InputText} from "primereact/inputtext";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {convertLocalDateArrayToDate, convertLocalDateArrayToString} from "../../utilities/WotvDataUtil";
import {useNavigate} from "react-router-dom";
import {useTransition} from "react";
import {Button} from "primereact/button";
import {GuildSearchRowItem} from "../util/GlobalSearchBar";
import { playerChangesByPlayerIdSelector } from "./PlayerNameChangesDashlet";

export type GuildHistoryRowItem = {
    guildId: number,
    guildName: string,
    joinDate?: string,
    leftDate?: string
}

export type PlayerGuildHistoryDashletProps = {
    playerId: number
}

export type PlayerAttackPartiesProps = {
    playerId: number
}

export const playerAttackPartiesSelector = selectorFamily({
    key: 'playerAttackPartiesSelector',
    get: (props: PlayerAttackPartiesProps) => ({get}) => {

        let guildHistoryItemMap: Map<number, GuildHistoryRowItem> = new Map();

        let playerChangeEventItems = get(playerChangesByPlayerIdSelector(
            props.playerId
        ));

        let sortedPlayerChangeEventItems = [...playerChangeEventItems].filter(item => item.action === "JOINED" || item.action === "LEFT");

        sortedPlayerChangeEventItems.sort((a,b) => compareAsc(convertLocalDateArrayToDate(a.date), convertLocalDateArrayToDate(b.date)));

        if (sortedPlayerChangeEventItems.length === 0){
            return []
        }

        let firstEvent = sortedPlayerChangeEventItems[0];

        let guildHistoryRowItems: GuildHistoryRowItem[] = [];

        let guildHistoryRowItem: GuildHistoryRowItem = {
            guildId: firstEvent.guildId,
            guildName: firstEvent.guildName,
            joinDate: convertLocalDateArrayToString(firstEvent.date)
        }

        guildHistoryRowItems.push(guildHistoryRowItem);

        for (let i = 1; i < sortedPlayerChangeEventItems.length; i++){
            const playerChangeEventItem = sortedPlayerChangeEventItems[i];

            if (playerChangeEventItem.action === "JOINED") {
                guildHistoryRowItem = {
                    guildId: playerChangeEventItem.guildId,
                    guildName: playerChangeEventItem.guildName,
                    joinDate: convertLocalDateArrayToString(playerChangeEventItem.date)
                }
                guildHistoryRowItems.push(guildHistoryRowItem);
            } else
            if (playerChangeEventItem.action === "LEFT") {
                guildHistoryRowItem.leftDate = convertLocalDateArrayToString(playerChangeEventItem.date)
            }
        }

        guildHistoryRowItems.sort((a,b) => {
            return b.joinDate!.localeCompare(a.joinDate!)
        });

        return guildHistoryRowItems;
    }
});

export const PlayerGuildHistoryDashlet = (props: PlayerGuildHistoryDashletProps) => {

    const navigate = useNavigate();
    const [inTransition, startTransition] = useTransition();

    let parties = useRecoilValue(findPartiesSelector({
        playerId: props.playerId
    }));

    let guildHistoryRowItem = useRecoilValue(playerAttackPartiesSelector({
        playerId: props.playerId
    }));


    const onGuildNameClick = (guildId: number) => {
        startTransition(() => {
            navigate("/guild/" + guildId);
        })
    }


    const formatGuildName = (item: GuildHistoryRowItem): JSX.Element => {
        return (<>
            <Button className="p-button-raised p-button-secondary"
                    onClick={() => onGuildNameClick(item.guildId)}>{item.guildName}
            </Button>
        </>)
    }

    return (
        <>
            <Fieldset legend={"Guild History"}>
                <div className="flex justify-content-start"
                     style={{columnGap: "10px", rowGap: "10px", flexWrap: "wrap"}}>
                    <div className="p-inputgroup" style={{maxWidth: "120px"}}>
                        <span className="p-inputgroup-addon">
                            Total
                        </span>
                        <InputText value={guildHistoryRowItem.length} readOnly></InputText>
                    </div>
                </div>
            </Fieldset>

            <DataTable value={guildHistoryRowItem} dataKey="id"
                       rowHover rows={10} paginator responsiveLayout="scroll"
                       first={0}
            >
                <Column field="guildName" header="Guild" body={data => formatGuildName(data)}></Column>
                <Column field="joinDate" header="Join Date"></Column>
                <Column field="leftDate" header="Left Date"></Column>
            </DataTable>
        </>);
}