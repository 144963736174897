import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import ArrowImage from "../../graphics/icon/ArrowImage";
import {useNavigate} from "react-router-dom";
import {format} from "date-fns";
import {convertLocalDataArrayToString, convertLocalDateArrayToDate} from "../../utilities/WotvDataUtil";
import {GuildBattleItem} from "../../types/wotv-guild-data-api";
import {createRef, useState, useTransition} from "react";
import {TrophyImage} from "../../graphics/icon/TrophyImage";
import SwordImage from "../../graphics/icon/SwordImage";
import StarImage from "../../graphics/icon/StarImage";
import {Button} from "primereact/button";
import {WinLoseDrawImage} from "../../graphics/WinLoseDrawImage";
import { ToiletPaperIcon } from "../../graphics/icon/ToiletPaperIcon";
import { FlexIcon } from "../../graphics/icon/FlexIcon";
import { WotvIcon } from "../../graphics/icon/WotvIcon";

export interface BattleHistoryTableProps {
    tableId: string
    date?: string

    guildBattleItems: GuildBattleItem[]

    searchFilter?: string

    hideGuildName?: boolean
    hideEnemyActions?: boolean
    hideEnemyGuildName?: boolean
    hideCalendarInput?: boolean
    hidePaginator?: boolean
    hideDate?: boolean
    hideEnemyStars?: boolean

    hideWipes?: boolean
    hideAbsences?: boolean
    hideFreshClears?: boolean
}

const GuildBattleHistoryTable = (props: BattleHistoryTableProps) => {
    const navigate = useNavigate();
    const [inTransition, startTransition] = useTransition();

    const formatDate = (localDateArray: number[]) => {
        let date = convertLocalDateArrayToDate(localDateArray);
        return <span>{format(date, 'yyyy-MM-dd')}</span>
    }

    const formatGuildRank = (valueField: number, changeField: number, reverse: boolean = false) => {
        const rank = valueField;
        const change = changeField * (reverse ? -1 : 1);
        const direction = change < 0 ? "down" : change === 0 ? "none" : "up";
        if (direction === "none") {
            return <span>{rank}</span>
        } else {
            return <div>
                {rank}
                &nbsp;
                (<ArrowImage direction={change < 0 ? "down" : "up"}/>
                &nbsp;
                {Math.abs(change)})
            </div>
        }
    }

    const formatOutcome = (guildBattleItem: GuildBattleItem, onClick: (guildBattleItem: GuildBattleItem) => void) => {
        return <WinLoseDrawImage guildBattleItem={guildBattleItem} onClick={() => onClick(guildBattleItem)}/>
    }

    const formatStars = (guildBattleItem: GuildBattleItem, onClick: (guildBattleItem: GuildBattleItem, event: React.MouseEvent<HTMLButtonElement>) => void) => {
        return (<Button className="p-button-raised p-button-secondary"
                        onClick={(event) => onClick(guildBattleItem, event)}
                        onContextMenu={(event) => onClick(guildBattleItem, event)}>
                {guildBattleItem.stars}

                {!props.hideEnemyStars && <>
                <img src={"/assets/gb/cmn_icon_star.png"} style={{
                    margin: "0px 3px",
                    verticalAlign: "middle"
                }} height={"15px"} alt={""}/>
                {guildBattleItem.enemyStars}</>}
            </Button>
        );
    }
    const formatGuildName = (guildName: string, guildId: number, onClick: (guildId: number, event: React.MouseEvent<HTMLButtonElement>) => void) => {
        return <Button label={guildName}
                       className="p-button-raised p-button-secondary"
                       style={{lineHeight: "20px"}}
                       onClick={(event) => onClick(guildId, event)}
                       onContextMenu={(event) => onClick(guildId, event)}/>
    };

    const guildNameOnClick = (guildId: number, event?: React.MouseEvent<HTMLButtonElement>) => {
        if (event){
            event.preventDefault();

            let destinationUrl = "/guild/" + guildId;

            if (event.button === 0) {
                // Left-click: Navigate in current tab
                startTransition(() => {
                    navigate(destinationUrl);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                })
            } else if (event.button === 2) {
                // Right-click: Open in new tab
                const newWindow = window.open(
                    `${window.location.pathname}#${destinationUrl}`,
                    '_blank',
                    'noopener,noreferrer'
                );
                if (newWindow) newWindow.opener = null;
            }
        }
    }
    const guildBattleOnClick = (guildBattleItem: GuildBattleItem, event?: React.MouseEvent<HTMLElement>) => {
        if (event){
            event.preventDefault();

            let destinationUrl = "/guild/" + guildBattleItem.guildId + "/" + convertLocalDataArrayToString(guildBattleItem.date) + "/" + guildBattleItem.sessionId;

            if (event.button === 0) {
                // Left-click: Navigate in current tab
                startTransition(() => {
                    navigate(destinationUrl);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                })
            } else if (event.button === 2) {
                // Right-click: Open in new tab
                const newWindow = window.open(
                    `${window.location.pathname}#${destinationUrl}`,
                    '_blank',
                    'noopener,noreferrer'
                );
                if (newWindow) newWindow.opener = null;
            }
        }
    }

    return (<DataTable id={props.tableId} value={props.guildBattleItems.slice(0,500)} dataKey="sessionId" rows={10}
                       paginator={!props.hidePaginator}
                       stateKey={props.tableId}
                       responsiveLayout="scroll"
                       breakpoint="900px"
                       rowHover
        /*
                       globalFilterFields={["guildName", "enemyGuildName"]}
                       filters={filters}*/
                       onRowClick={e => guildBattleOnClick(e.data, e.originalEvent)}
    >
        {!props.hideDate && <Column field="date" style={{minWidth: '90px'}} header="Date"
                                    body={(guildBattleItem) => formatDate(guildBattleItem.date)}></Column>}

        <Column field="rank" style={{ minWidth: '90px'}} header="Rank"
                body={(guildBattleItem) => formatGuildRank(guildBattleItem.guildRank, guildBattleItem.guildRankChange, true)}></Column>

        {!props.hideGuildName && <Column field="name" style={{minWidth: '110px'}} header="Name"
                                         body={(guildBattleItem) => formatGuildName(guildBattleItem.guildName, guildBattleItem.guildId, guildNameOnClick)}></Column>}

        <Column field="trophy" style={{minWidth: '100px'}} alignHeader={'center'} align={'center'}
                header={<TrophyImage/>}
                body={(guildBattleItem) => formatGuildRank(guildBattleItem.leagueTrophy, guildBattleItem.leagueTrophyChange)}></Column>

        <Column field="result" header="Result" alignHeader={'center'} align={'center'}
                body={(guildBattleItem) => formatOutcome(guildBattleItem, guildBattleOnClick)}></Column>

        <Column field="actionsRemaining" style={{width: '50px'}} alignHeader={'center'} align={'center'}
                header={<SwordImage height={17}/>}
                body={(guildBattleItem) => guildBattleItem.actionsRemaining + "/" + guildBattleItem.actionsMax}></Column>

        <Column field="stars" style={{minWidth: '70px'}} alignHeader={'center'} align={'center'}
                header={<StarImage/>}
                body={(guildBattleItem) => formatStars(guildBattleItem, guildBattleOnClick)}></Column>

        {!props.hideWipes &&
            <Column field="crashes" style={{width: '50px'}} alignHeader={'center'} align={'center'}
                    header={<ToiletPaperIcon height={17}/>}></Column>}

        {!props.hideAbsences &&
            <Column field="absences" style={{width: '50px'}} alignHeader={'center'} align={'center'}
                    header={<WotvIcon url={"/assets/icons/absent_icon.png"}  title={"Absent / missed attacks"} height={17} width={17}/>}></Column>}

        {!props.hideFreshClears &&
            <Column field="freshClears" style={{width: '50px'}} alignHeader={'center'} align={'center'}
                    header={<FlexIcon height={17}/>}></Column>}

        {!props.hideEnemyGuildName &&
            <Column field="enemyActionsRemaining" alignHeader={'center'} align={'center'}
                    header={<SwordImage height={17}/>}
                    body={(guildBattleItem) => guildBattleItem.enemyActionsRemaining + "/" + guildBattleItem.enemyActionsMax}></Column>}

        {!props.hideEnemyGuildName &&
            <Column field="opponent" style={{minWidth: '110px'}} header="Opponent" align={"right"}
                    body={(guildBattleItem) => formatGuildName(guildBattleItem.enemyGuildName, guildBattleItem.enemyGuildId, guildNameOnClick)}></Column>}
    </DataTable>);
}

export default GuildBattleHistoryTable