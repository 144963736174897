const arrows: { [index: string]: string } = {
    PLUS: "/assets/icons/greenplus2.png",
    MINUS: "/assets/icons/minus2.png"
}

export type ArrowImageProps = {
    type:  "PLUS" | "MINUS";
    size?: number
    dead?: boolean
}

const PlusMinusIcon = (props: ArrowImageProps) => {
    let size = props.size || 12
    let dead = props.dead || false
    return <img style={{
        width: size,
        height: size,
        lineHeight: size,
        filter: `brightness(${dead ? "40%" : "100%"})`,
    }} src={arrows[props.type]} alt={""}/>
}

export default PlusMinusIcon;