import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import {
    convertLocalDateStringToDate,
    convertToLocalDateString,
    elementColorMap,
    guildBattleMapSelector,
    guildBattleStartMapSelector, questInfoDataAtom,
    questTitleDataAtom,
    unitElementMapSelector,
    unitNameDataAtom
} from "../../utilities/WotvDataUtil";
import * as React from "react";
import { ReactElement } from "react";
import { UnitImage } from "../../graphics/UnitImage";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TooltipProps } from "recharts/types/component/Tooltip";
import { isAfter, isBefore, isEqual, max, min } from "date-fns";
import { GuildBattleMapIcon } from "../../graphics/icon/GuildBattleMapIcon";
import { useRecoilValue } from "recoil";


export interface MetaChartDataItem {
    numTeams: number;
    numUnrevealed: number;
    numAbsent?: number;
    date: string;
    individualUnitAbsentCount?: { [index: string]: number };
}

export interface UnitMetaLineChartProps {
    data: MetaChartDataItem[],
    keys: string[],
    maxKeys: number
    title?: string,

    domain: number[],

    chartHeight?: number,

    onActiveDotClick?: (MetaChartDataItem: string) => void;
    individualUnitOnly?: boolean
}

export const UnitMetaLineChart = (unitMetaLineChartProps: UnitMetaLineChartProps) => {

    let unitElementMap = useRecoilValue(unitElementMapSelector);
    const unitNameMap = useRecoilValue(unitNameDataAtom);
    const guildBattleMap = useRecoilValue(guildBattleMapSelector);
    const guildBattleStartMap = useRecoilValue(guildBattleStartMapSelector);
    const questTitleMap = useRecoilValue(questTitleDataAtom);
    const questInfoMap = useRecoilValue(questInfoDataAtom);

    let chartHeight = unitMetaLineChartProps.chartHeight || 600;

    let chartData = unitMetaLineChartProps.data;
    let chartKeys = unitMetaLineChartProps.keys;

    let chartDates = chartData.map((item) => convertLocalDateStringToDate(item.date));
    let minDate = min(chartDates);
    let maxDate = max(chartDates);

    let mapDates = Object.keys(guildBattleStartMap).map((dateString) => convertLocalDateStringToDate(dateString));

    let mapTicks = mapDates.filter((date) => (isEqual(date, minDate) || isAfter(date, minDate)) && (isEqual(date, maxDate) || isBefore(date, maxDate)))
        .map((date) => {
            for (const chartDate of chartDates) {
                if (isEqual(chartDate, date) || isAfter(chartDate, date)){
                    return convertToLocalDateString(chartDate);
                }
            }

            return convertToLocalDateString(date)
        });

    const onActiveDotClick = unitMetaLineChartProps.onActiveDotClick || (() => {
    });

    // @ts-ignore
    const CustomizedDot = (props): ReactElement<SVGElement> => {
        if (props.payload.date === chartData[chartData.length - 1].date) {
            let entries = Object.entries(props.payload);

            let currentCount = props.payload[props.dataKey];
            let numOffset = 0;
            let overlapSize = (unitMetaLineChartProps.domain[1] - unitMetaLineChartProps.domain[0]) / 30;
            // console.log("-------");
            // console.log(overlapSize);
            // console.log(unitMetaLineChartProps.domain);
            for (const entry of entries) {
                const key = entry[0] as string;
                const count = entry[1] as number;
                if (!key.startsWith("UN")) {
                    continue;
                }

                if (key === props.dataKey) {
                    continue;
                }

                if (Math.abs(count - currentCount) <= overlapSize) {
                    if (currentCount < count) {
                        numOffset++;
                    } else if (currentCount === count) {
                        let nameCompare = key.localeCompare(props.dataKey);
                        if (nameCompare === 0 || nameCompare === 1) {
                            numOffset++;
                        }
                    }
                }
            }
            // console.log(numOffset);
            // console.log(props.dataKey);

            return (
                <foreignObject width={40} height={40} x={props.cx + (numOffset % 3 * 30)} y={props.cy - 20}>
                    <UnitImage unitIname={props.dataKey} width={60} height={60} />
                </foreignObject>
            );
        } else {
            return <></>;
        }
    };
    type TooltipDataRow = {
        unitName: string,
        unitIname: string,
        count: number,
        percentage: string
    }

    const customTooltipContent = (props: TooltipProps<number, string>): JSX.Element => {
        if (props.active && props.payload && props.payload.length) {
            let tableData: TooltipDataRow[] = [];

            let payload = props.payload[0].payload;

            for (const entry of Object.entries(payload)) {
                let key = entry[0];
                let value = entry[1];

                if (!unitMetaLineChartProps.keys.includes(key)) {
                    continue;
                }

                if (value === 0) {
                    continue;
                }

                let count = parseInt(value as string);
                tableData.push({
                    unitName: unitNameMap[key],
                    unitIname: key,
                    count: count,
                    percentage: (count / payload.numTeams * 100).toFixed(2)
                });
            }

            let mapItem = guildBattleMap[payload.date];

            const mapTitle = questTitleMap[mapItem.questId];
            const mapInfo = questInfoMap[mapItem.questId] || [];

            tableData.sort((a, b) => b.count - a.count);

            return (
                <div className="card custom-tooltip" style={{ background: "rgba(0, 0, 0, 0.80)" }}>
                    <div className="flex justify-content-between" style={{ background: "rgba(0, 0, 0, 0.80)" }}>
                        <div style={{ marginRight: 5 }}>
                            <div><span className={"text-sm font-medium"}>Date: {props.label}</span></div>
                            <div><span className={"text-sm font-medium"}>Total: {payload.numTeams}</span></div>
                            <div><span className={"text-sm font-medium"}>Unrevealed: {payload.numUnrevealed}</span>
                            </div>
                            <div><span className={"text-sm font-medium"}>Absent: {payload.numAbsent || "-"}</span></div>
                        </div>
                        <div>
                            <div className="text-right"><span className={"text-sm font-medium"}>{mapTitle}</span></div>
                            {mapInfo.map((buff) => {
                                return <div className="text-right"><span className={"text-sm font-medium"}>{buff}</span>
                                </div>;
                            })}
                        </div>
                    </div>
                    <DataTable value={tableData} responsiveLayout="scroll" breakpoint="100px">
                        <Column className={"text-sm font-medium"} field="unitName" header={"Unit"} alignHeader={"right"}
                                align={"right"}></Column>
                        <Column className={"text-sm font-medium"} field="count" header={"# Teams"} alignHeader={"right"}
                                align={"right"}></Column>
                        <Column className={"text-sm font-medium"} field="percentage" header={"%"} alignHeader={"right"}
                                align={"right"}></Column>
                    </DataTable>
                </div>
            );
        }

        return <></>;
    };

    // @ts-ignore
    const renderGuildBattleMapTick = props => {
        return <foreignObject x={props.x - 40} y={props.y} width={80} height={80}>
            <div className={"flex flex-column align-items-center"}>
                <span>{props.payload.value}</span>
                <GuildBattleMapIcon height={80} width={80} guildBattleMapItem={guildBattleMap[props.payload.value]} />
            </div>
        </foreignObject>;
    };

    return (
        <ResponsiveContainer width="100%" height={chartHeight}>
            <LineChart data={chartData}
                       margin={{ top: 30, right: 3, left: -20, bottom: 55 }}>
                <CartesianGrid strokeDasharray="3 3" />

                <XAxis dataKey="date"
                       padding={{ right: unitMetaLineChartProps.individualUnitOnly ? 45 : 100 }}
                       ticks={mapTicks}
                       tick={(props) => renderGuildBattleMapTick(props)}
                />
                <YAxis domain={[0, unitMetaLineChartProps.domain[1]]}
                       tickCount={10}
                       style={{ fill: "#FFFFFF" }}
                />
                {
                    chartKeys.map((key) => {
                        let elementColorMapElement = elementColorMap[+unitElementMap[key]];

                        return <Line type="monotone" dataKey={key}
                                     isAnimationActive={false}
                                     stroke={elementColorMapElement}
                                     strokeWidth={"3px"}
                                     dot={CustomizedDot}
                            // @ts-ignore
                                     activeDot={{ onClick: (event, payload) => onActiveDotClick(payload.payload) }}
                        >
                        </Line>;
                    })
                }
                <Tooltip content={customTooltipContent} />
            </LineChart>
        </ResponsiveContainer>
    );
};