import * as React from "react";
import { useTransition } from "react";
import { unitImageMapSelector, unitNameDataAtom } from "../utilities/WotvDataUtil";
import { useNavigate } from "react-router-dom";
import { unitMapAtom } from "../service/ConfigService";
import { useRecoilValue, useRecoilValue_TRANSITION_SUPPORT_UNSTABLE } from "recoil";
import { PlayerLogUnitStatus } from "../types/wotv-guild-data-api";

export interface UnitImageProps {
    unitIname: string;
    width?: number;
    height?: number;
    unitStatus?: PlayerLogUnitStatus;
    marginRight?: number;
    marginTop?: number;
    marginLeft?: number;
    marginBottom?: number;
    scrollToTopOnClick?: boolean;
}

export const UnitImage = ({
                              unitIname,
                              width = 70,
                              height = 70,
                              marginLeft = -10,
                              marginTop = -10,
                              marginRight = 0,
                              marginBottom = 0,
                              unitStatus = "A",
                              scrollToTopOnClick = true
                          }: UnitImageProps) => {
    const navigate = useNavigate();
    const [inTransition, startTransition] = useTransition();

    const unitMap = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(unitMapAtom);
    const unitNameMap = useRecoilValue(unitNameDataAtom);

    const unitImageMap = useRecoilValue(unitImageMapSelector);

    const noData = !unitMap.hasOwnProperty(unitIname);

    const onClick = () => {
        if (noData) {
            return;
        }
        startTransition(() => {
            navigate(`/unit/${unitMap[unitIname]}`);
            if (scrollToTopOnClick) {
                window.scrollTo({ top: 0, behavior: "smooth" });
            }
        });
    };

    const unitName = unitNameMap[unitIname];

    let imageUrl;

    if (unitIname === "~UNREVEALED~") {
        imageUrl = "/assets/icons/unrevealed_silhouette.png";
    } else if (!unitImageMap.hasOwnProperty(unitIname)) {
        // console.log(unitIname + " image missing");
        imageUrl = "/assets/icons/image_coming_soon.png";
    } else {
        imageUrl = unitImageMap[unitIname];
    }

    let brightnessValue = (unitStatus === "D" || noData) ? "40%" : unitStatus === "K" ? "40%" : "100%";

    return <div style={{
        display: "inline-block",
        position: "relative",
        width: width,
        height: height,
        marginLeft: marginLeft,
        marginTop: marginTop,
        marginBottom: marginBottom,
        marginRight: marginRight
    }}>
        <img className={(!noData ? "cursor-pointer" : "default")} src={imageUrl} style={{
            filter: `brightness(${brightnessValue})`,
            width: width,
            height: height,
            position: "absolute",
            top:0,
            left:0
            // marginLeft: marginLeft,
            // marginTop: marginTop,
            // marginBottom: marginBottom,
            // marginRight: marginRight
        }}
             alt={noData ? `${unitName} - No Data` : `${unitName}`}
             title={noData ? `${unitName} - No Data` : `${unitName}`}
             onClick={() => onClick()}
        />
        {unitStatus === "K" && <img className={(!noData ? "cursor-pointer" : "default")}
                                    src={"/assets/gb/cmn_icon_unit_dead.png"} style={{zIndex: 2,
            width: 50,
            height: 50,
            position: "absolute",
            top:10,
            left:10}}
                                    onClick={() => onClick()}
        />
        }
    </div>;
};