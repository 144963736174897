import { useNavigate } from "react-router-dom";
import {
    convertLocalDateArrayToDate,
    convertLocalDateStringToDate,
    convertToLocalDateString
} from "../../utilities/WotvDataUtil";
import { atom, selector, selectorFamily, useRecoilValue_TRANSITION_SUPPORT_UNSTABLE, useSetRecoilState } from "recoil";
import {
    guildBattleByGuildId,
    guildRosterByGuildIdAndDateSelector,
    GuildRosterRequest
} from "../../service/GuildBattleDataService";
import { CalendarValueType } from "primereact/calendar";
import { useTransition } from "react";
import { ReadyDateCalendarInput } from "../wotv/ReadyDateCalendarInput";
import { Fieldset } from "primereact/fieldset";
import { format, isBefore, max, min, parse, subDays } from "date-fns";
import { dataReadyDatesSelector } from "../../service/ConfigService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { GuildRosterItem, PlayerSummary, PositionType } from "../../types/wotv-guild-data-api";
import { Button } from "primereact/button";
import { PositionImage } from "../../graphics/icon/PositionImage";
import { InputText } from "primereact/inputtext";

export interface GuildRosterDashletProps {
    guildId: number
}

export const rosterSelectedDateAtom = atom({
    key: 'rosterSelectedDateAtom',
    default: selector({
        key: 'rosterSelectedDateAtom/Default',
        get: ({get}) => format(get(dataReadyDatesSelector)[0], "yyyy-MM-dd"),
    }),
});

const positionValue = (type: PositionType) => {
    if (type === "M") return 0;
    if (type === "S") return 1;
    if (type === "L") return 2;

    return -1;
};

export const sortedGuildRosterItemSelector = selectorFamily({
    key: 'sortedGuildRosterItemSelector',
    get: (props: GuildRosterRequest) => ({get}) => {
        let guildBattleItems = get(guildBattleByGuildId({guildId: props.guildId}));

        let battleDates = guildBattleItems.map((guildBattleItem) => convertLocalDateArrayToDate(guildBattleItem.date));
        let maxDate = max(battleDates);
        maxDate = min([maxDate, convertLocalDateStringToDate(get(rosterSelectedDateAtom))]);

        let guildRosterItem = get(guildRosterByGuildIdAndDateSelector({
            guildId: props.guildId,
            date: convertToLocalDateString(maxDate)
        }));

        // the guildRosterItem above is read-only and the players array cannot be sorted
        // make a copy to sort.
        let mutableCopy = JSON.parse(JSON.stringify(guildRosterItem)) as GuildRosterItem;

        mutableCopy.players.sort((a, b) => {
            const positionDiff = positionValue(b.position) - positionValue(a.position);

            if (positionDiff) {
                return positionDiff;
            }

            const daysInGuildDiff = b.daysInGuild - a.daysInGuild;

            if (daysInGuildDiff) {
                return daysInGuildDiff;
            }

            return a.name.localeCompare(b.name);
        })

        return {
            data: mutableCopy,
            validDates: battleDates
        };
    }
});

export const GuildRosterDashlet = (props: GuildRosterDashletProps) => {
    const navigate = useNavigate();
    const [inTransition, startTransition] = useTransition();

    let selectedDate = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(rosterSelectedDateAtom)
    let setSelectedDate = useSetRecoilState(rosterSelectedDateAtom);

    const guildRosterData = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(sortedGuildRosterItemSelector({
        guildId: props.guildId,
        date: selectedDate
    }))

    const maxDate = max(guildRosterData.validDates);

    const onCalendarChange = (calendarValue: CalendarValueType): calendarValue is Date => {
        let calendarDate = calendarValue as Date;
        startTransition(() => {
            setSelectedDate(convertToLocalDateString(calendarDate));
        })
        return true;
    }

    const onPlayerNameClick = (playerSummary: PlayerSummary) => {
        startTransition(() => {
            navigate("/player/" + playerSummary.playerId);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        })
    }

    const formatName = (playerSummary: PlayerSummary) => {
        return (<>
            <div className={"flex align-items-center"}>
                <Button className="p-button-raised p-button-secondary" style={{lineHeight: "20px"}}
                        onClick={() => onPlayerNameClick(playerSummary)}><span
                    style={{marginRight: "5px"}}>{playerSummary.name}</span>
                </Button>
                <PositionImage guildPosition={playerSummary.position} size={23}/>
            </div>
        </>)
    };

    const formatDaysInGuild = (playerSummary: PlayerSummary) => {
        let joinDate = subDays(parse(selectedDate, "yyyy-MM-dd", new Date()), playerSummary.daysInGuild -1);
        return (<div>{convertToLocalDateString(joinDate)}</div>)
    };
    const formatDaysInPosition = (playerSummary: PlayerSummary) => {
        let joinDate = subDays(parse(selectedDate, "yyyy-MM-dd", new Date()), playerSummary.daysInPosition -1);
        return (<div>{convertToLocalDateString(joinDate)}</div>)
    };
    return (
        <>
            <Fieldset legend={"Roster"}>
                <div className="flex justify-content-start" style={{columnGap: "10px", rowGap: "10px", flexWrap: "wrap"}}>
                    <div className="p-inputgroup" style={{maxWidth: "200px"}}>
                        <span className="p-inputgroup-addon">
                            Date
                        </span>
                        <ReadyDateCalendarInput date={isBefore(convertLocalDateStringToDate(selectedDate), maxDate) ? selectedDate: convertToLocalDateString(maxDate)} maxDate={convertToLocalDateString(maxDate)} onChange={onCalendarChange}/>
                    </div>
                    <div className="p-inputgroup" style={{maxWidth: "120px"}}>
                        <span className="p-inputgroup-addon">
                            Members
                        </span>
                        <InputText value={guildRosterData.data.players.length} readOnly></InputText>
                    </div>
                </div>
            </Fieldset>

            <DataTable value={guildRosterData.data.players} dataKey="id"
                       rowHover rows={10} paginator responsiveLayout="scroll"
                       first={0}
            >
                <Column field="name" header="Name" body={(playerSummary) => formatName(playerSummary)}></Column>
                <Column field="daysInGuild" header="Join Date" body={(playerSummary) => formatDaysInGuild(playerSummary)}></Column>
                <Column field="daysInPosition" header="Position Date" body={(playerSummary) => formatDaysInPosition(playerSummary)}></Column>
            </DataTable>
        </>
    )
}