import {
    convertLocalDateArrayToDate,
    convertLocalDateArrayToString,
    convertLocalDateStringToDate,
    convertToLocalDateString,
    unitElementMapSelector
} from "../../utilities/WotvDataUtil";
import * as React from "react";
import { useState, useTransition } from "react";
import { selectorFamily, useRecoilValue } from "recoil";
import { PartyType } from "../../types/wotv-guild-data-api";
import { Fieldset } from "primereact/fieldset";
import { useNavigate } from "react-router-dom";
import { MetaChartDataItem, UnitMetaLineChart } from "./UnitMetaLineChart";
import { ReadyDateCalendarInput } from "../wotv/ReadyDateCalendarInput";
import { CalendarValueType } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { closestTo, compareAsc, isAfter, isBefore, max, min, subDays } from "date-fns";
import { idMapSelector } from "../../service/ConfigService";
import { findPartiesSelector } from "../../service/GuildBattleDataService";
import { WotvIcon } from "../../graphics/icon/WotvIcon";

export interface PlayerUnitChartPanelProps {
    fromDate?: string;
    toDate?: string;
    playerId?: number;

    showDashboardLegend?: boolean;

    hideElementsFilter?: boolean;
    hideMaxResultsFilter?: boolean;

    partyType: PartyType;
}


export type PlayerMetaChartRequestProps = {
    fromDate?: string,
    toDate?: string,
    numBattlesBack?: number

    playerId?: number
    maxUnitRank?: number
    minUnitRank?: number
    elementFilter?: boolean[]
}

export const playerMetaChartDataSelector = selectorFamily({
    key: "playerMetaChartDataSelector", get: (props: PlayerMetaChartRequestProps) => ({ get }) => {

        const unitElementMap = get(unitElementMapSelector);

        const maxUnitRank = props.maxUnitRank || 16;
        const minUnitRank = props.minUnitRank || 1;

        let idMap = get(idMapSelector);

        let attackUnitData: MetaChartDataItem[] = [];
        let defendUnitData: MetaChartDataItem[] = [];

        let attackMaxCount = 0;
        let attackMinCount = 9999999;

        let defendMaxCount = 0;
        let defendMinCount = 9999999;

        let attackFirstDate: string;
        let attackLastDate: string;

        let defendFirstDate: string;
        let defendLastDate: string;

        let allParties = get(findPartiesSelector({ playerId: props.playerId }));

        let playerParties = allParties.filter((party) => party.playerId === props.playerId);

        let attackCountByDateMap: Map<string, Map<string, number>> = new Map();
        let attackCountMap: Map<string, number> = new Map();

        let defendCountByDateMap: Map<string, Map<string, number>> = new Map();
        let defendCountMap: Map<string, number> = new Map();

        let attackDataKeys: Set<string> = new Set();
        let defendDataKeys: Set<string> = new Set();

        let unrevealedCount = 0;
        let unrevealedDateMap: Map<string, number> = new Map();

        if (playerParties.length > 0) {

            let allDates = playerParties.map(item => convertLocalDateArrayToDate(item.date));

            let maxDate = max(allDates);
            let maxDateString = convertToLocalDateString(maxDate);

            let minDate = closestTo(subDays(maxDate, 180), allDates);
            let minDateString = convertToLocalDateString(minDate!);

            let fromDate = props.fromDate || minDateString;
            let toDate = props.toDate || maxDateString;

            const addKey = (id: number, keys: Set<string>) => {
                let key = idMap[id];
                if (key !== "~UNREVEALED~") {
                    const element = unitElementMap[key];

                    if (!props.elementFilter || props.elementFilter[+element] || props.elementFilter.every((active) => !active)) {
                        keys.add(key);
                    }
                }
            };

            let dateFilteredParties = playerParties.filter((party) => {
                if (isBefore(convertLocalDateArrayToDate(party.date), convertLocalDateStringToDate(fromDate))) {
                    return false;
                }

                if (isAfter(convertLocalDateArrayToDate(party.date), convertLocalDateStringToDate(toDate))) {
                    return false;
                }

                return true;
            });

            dateFilteredParties.sort((a, b) => compareAsc(convertLocalDateArrayToDate(a.date), convertLocalDateArrayToDate(b.date)));

            dateFilteredParties.forEach((party, index) => {
                const keys = party.partyType === "A" ? attackDataKeys : defendDataKeys;
                addKey(party.unit1, keys);
                addKey(party.unit2, keys);
                addKey(party.unit3, keys);
                addKey(party.reserveUnit1, keys);
                addKey(party.reserveUnit2, keys);
            });

            attackDataKeys.forEach(value => attackCountMap.set(value, 0));
            defendDataKeys.forEach(value => defendCountMap.set(value, 0));

            const countKey = (id: number, keys: Set<string>, countMap: Map<string, number>) => {
                let key = idMap[id];
                if (key !== "~UNREVEALED~") {
                    if (keys.has(key)) {
                        countMap.set(key, countMap.get(key)! + 1);
                    }
                }
            };

            dateFilteredParties.forEach((party, index) => {
                const keys = party.partyType === "A" ? attackDataKeys : defendDataKeys;
                const countMap = party.partyType === "A" ? attackCountMap : defendCountMap;
                const countByDateMap = party.partyType === "A" ? attackCountByDateMap : defendCountByDateMap;

                countKey(party.unit1, keys, countMap);
                countKey(party.unit2, keys, countMap);
                countKey(party.unit3, keys, countMap);
                countKey(party.reserveUnit1, keys, countMap);
                countKey(party.reserveUnit2, keys, countMap);

                if (party.unrevealed) {
                    unrevealedCount++;
                }

                countByDateMap.set(convertLocalDateArrayToString(party.date), new Map(countMap));
                unrevealedDateMap.set(convertLocalDateArrayToString(party.date), unrevealedCount);
            });

            let attackPlayerDates = dateFilteredParties.filter((party) => party.partyType === "A").map((party) => convertLocalDateArrayToDate(party.date));
            let defendPlayerDates = dateFilteredParties.filter((party) => party.partyType === "D").map((party) => convertLocalDateArrayToDate(party.date));

            attackFirstDate = convertToLocalDateString(min(attackPlayerDates));
            attackLastDate = convertToLocalDateString(max(attackPlayerDates));

            defendFirstDate = convertToLocalDateString(min(defendPlayerDates));
            defendLastDate = convertToLocalDateString(max(defendPlayerDates));

            const getFilteredKeys = (unitCounts: [string, number][]) => {
                let keys = new Set<string>();

                unitCounts.sort((a, b) => b[1] - a[1]);

                let elementFilteredCounts = unitCounts.filter((entry) => {
                    const key = entry[0];
                    if (key === "~UNREVEALED~") {
                        return false;
                    }

                    const element = unitElementMap[key];

                    return !props.elementFilter || props.elementFilter[+element] || props.elementFilter.every((active) => !active);
                });

                elementFilteredCounts.forEach((entry, index) => {
                    let key = entry[0];

                    if (index + 1 >= minUnitRank && index + 1 <= maxUnitRank) {
                        keys.add(key);
                    }
                });

                return keys;
            };
            attackDataKeys = getFilteredKeys(Array.from(attackCountByDateMap.get(attackLastDate)!.entries()));
            defendDataKeys = getFilteredKeys(Array.from(defendCountByDateMap.get(defendLastDate)!.entries()));

            let attackCountMapEntries = Array.from(attackCountByDateMap.entries());
            let defendCountMapEntries = Array.from(defendCountByDateMap.entries());

            const numAttackParties = dateFilteredParties.filter((party) => party.partyType === "A").length;
            const numDefendParties = dateFilteredParties.filter((party) => party.partyType === "D").length;

            for (const entry of attackCountMapEntries) {
                let date = entry[0];
                let countMap = entry[1];

                let metaChartDataEntry: MetaChartDataItem = {
                    numTeams: numAttackParties,
                    numUnrevealed: 0,
                    date: date
                };

                attackDataKeys.forEach(key => {
                    const count = countMap.get(key)!;

                    // @ts-ignore
                    metaChartDataEntry[key] = count;

                    attackMaxCount = Math.max(count, attackMaxCount);
                    attackMinCount = Math.min(count, attackMinCount);
                });

                attackUnitData.push(metaChartDataEntry);
            }

            for (const entry of defendCountMapEntries) {
                let date = entry[0];
                let countMap = entry[1];

                let metaChartDataEntry: MetaChartDataItem = {
                    numTeams: numDefendParties,
                    numUnrevealed: unrevealedDateMap.get(date)!,
                    date: date
                };

                defendDataKeys.forEach(key => {
                    const count = countMap.get(key)!;

                    // @ts-ignore
                    metaChartDataEntry[key] = count;

                    defendMaxCount = Math.max(count, defendMaxCount);
                    defendMinCount = Math.min(count, defendMinCount);
                });

                defendUnitData.push(metaChartDataEntry);
            }
        }

        let attackMinDate = attackFirstDate! || props.fromDate!;
        let attackMaxDate = attackLastDate! || props.toDate!;

        let defendMinDate = defendFirstDate! || props.fromDate!;
        let defendMaxDate = defendLastDate! || props.toDate!;

        for (const data of attackUnitData) {
            const keys = Object.keys(data);

            for (const dataKey of attackDataKeys) {
                if (!keys.includes(dataKey)) {
                    // @ts-ignore
                    data[dataKey] = 0;
                }
            }
        }

        for (const data of defendUnitData) {
            const keys = Object.keys(data);

            for (const dataKey of defendDataKeys) {
                if (!keys.includes(dataKey)) {
                    // @ts-ignore
                    data[dataKey] = 0;
                }
            }
        }

        const attackMinRounded = Math.floor(attackMinCount / 10) * 10;
        let attackMaxRounded = Math.ceil(attackMaxCount / 10) * 10;

        const defendMinRounded = Math.floor(defendMinCount / 10) * 10;
        let defendMaxRounded = Math.ceil(defendMaxCount / 10) * 10;

        return {
            attackMinDate: convertLocalDateStringToDate(attackMinDate),
            attackMaxDate: convertLocalDateStringToDate(attackMaxDate),
            defendMinDate: convertLocalDateStringToDate(defendMinDate),
            defendMaxDate: convertLocalDateStringToDate(defendMaxDate),
            attackDomain: [attackMinRounded, attackMaxRounded],
            defendDomain: [defendMinRounded, defendMaxRounded],
            attackDataKeys: Array.from(attackDataKeys),
            defendDataKeys: Array.from(defendDataKeys),
            attackUnitData: attackUnitData,
            defendUnitData: defendUnitData
        };
    }
});

export const PlayerUnitChartPanel = (props: PlayerUnitChartPanelProps) => {
    const navigate = useNavigate();
    const [inTransition, startTransition] = useTransition();
    const [calendarFromDateString, setCalendarFromDateString] = useState<string>(props.fromDate!);
    const [calendarToDateString, setCalendarToDateString] = useState<string>(props.toDate!);
    const [minRank, setMinRank] = useState<number>(1);
    const [maxRank, setMaxRank] = useState<number>(500);
    const [maxUnitRank, setMaxUnitRank] = useState<number>(16);
    const [minUnitRank, setMinUnitRank] = useState<number>(1);
    const [elementFilter, setElementFilter] = useState<boolean[]>([false, false, false, false, false, false, false, false, false, false]);

    const onElementFilterChange = (element: number) => {
        let filters = [...elementFilter];
        filters[element] = !filters[element];
        setElementFilter(filters);
    };

    const metaAggregate = useRecoilValue(playerMetaChartDataSelector({
        playerId: props.playerId,
        fromDate: calendarFromDateString,
        toDate: calendarToDateString,
        maxUnitRank: maxUnitRank,
        minUnitRank: minUnitRank,
        elementFilter: elementFilter
    }));

    let chartData = props.partyType === "A" ? metaAggregate.attackUnitData : metaAggregate.defendUnitData;
    let chartKeys = props.partyType === "A" ? metaAggregate.attackDataKeys : metaAggregate.defendDataKeys;
    let chartTitle = props.partyType === "A" ? "Attack Teams: Cumulative Unit Usage" : "Defense Teams: Cumulative Unit Usage";
    let chartDomain = props.partyType === "A" ? metaAggregate.attackDomain : metaAggregate.defendDomain;
    let maxKeys = props.partyType === "A" ? metaAggregate.attackDataKeys.length : metaAggregate.defendDataKeys.length;

    const onCalendarFromChange = (calendarValue: CalendarValueType): calendarValue is Date => {
        let calendarFromDate = calendarValue as Date;
        let calendarToDate = calendarToDateString ? convertLocalDateStringToDate(calendarToDateString) : metaAggregate.attackMaxDate;

        if (isAfter(calendarFromDate, calendarToDate)) {
            calendarFromDate = new Date(calendarToDate.getTime());
        }
        startTransition(() => {
            setCalendarFromDateString(convertToLocalDateString(calendarFromDate));
        });
        return true;
    };

    const onCalendarToChange = (calendarValue: CalendarValueType) => {
        let calendarToDate = calendarValue as Date;
        let calendarFromDate = calendarFromDateString ? convertLocalDateStringToDate(calendarFromDateString) : metaAggregate.attackMinDate;

        if (isBefore(calendarToDate, calendarFromDate)) {
            calendarToDate = new Date(calendarFromDate.getTime());
        }
        startTransition(() => {
            setCalendarToDateString(convertToLocalDateString(calendarToDate));
        });
        return true;
    };

    return (<>
            <Fieldset legend={chartTitle} style={{ marginBottom: "10px" }}>
                <div className="flex justify-content-start"
                     style={{ columnGap: "10px", rowGap: "10px", flexWrap: "wrap" }}>
                    <div className="p-inputgroup" style={{ maxWidth: "200px" }}>
                        <span className="p-inputgroup-addon">From</span>
                        <ReadyDateCalendarInput
                            date={metaAggregate.attackMinDate ? convertToLocalDateString(metaAggregate.attackMinDate) : calendarFromDateString}
                            onChange={onCalendarFromChange} />
                    </div>

                    <div className="p-inputgroup" style={{ maxWidth: "200px" }}>
                        <span className="p-inputgroup-addon">To</span>
                        <ReadyDateCalendarInput
                            date={metaAggregate.attackMaxDate ? convertToLocalDateString(metaAggregate.attackMaxDate) : calendarFromDateString}
                            onChange={onCalendarToChange} />
                    </div>
                    {!props.hideMaxResultsFilter &&
                        <>
                            <div className="p-inputgroup" style={{ maxWidth: "200px" }}>
                                <span className="p-inputgroup-addon">Max Unit Rank</span>
                                <InputNumber value={maxUnitRank} min={1} max={999} showButtons
                                             onChange={(e) => setMaxUnitRank(e.value!)}></InputNumber>
                            </div>
                            <div className="p-inputgroup" style={{ maxWidth: "200px" }}>
                                <span className="p-inputgroup-addon">Min Unit Rank</span>
                                <InputNumber value={minUnitRank} min={1} max={999} showButtons
                                             onChange={(e) => setMinUnitRank(e.value!)}></InputNumber>
                            </div>
                        </>
                    }
                    {!props.hideElementsFilter &&
                        <div className="p-inputgroup align-items-center"
                             style={{ maxWidth: "375px", columnGap: "3px" }}>
                            <span className="p-inputgroup-addon">Filters</span>

                            <WotvIcon url={"/assets/icons/Element_Icon_Fire.png"} height={25} width={25}
                                      onClick={() => onElementFilterChange(1)} disable={!elementFilter[1]} />
                            <WotvIcon url={"/assets/icons/Element_Icon_Ice.png"} height={25} width={25}
                                      onClick={() => onElementFilterChange(2)} disable={!elementFilter[2]} />
                            <WotvIcon url={"/assets/icons/Element_Icon_Wind.png"} height={25} width={25}
                                      onClick={() => onElementFilterChange(3)} disable={!elementFilter[3]} />
                            <WotvIcon url={"/assets/icons/Element_Icon_Earth.png"} height={25} width={25}
                                      onClick={() => onElementFilterChange(4)} disable={!elementFilter[4]} />
                            <WotvIcon url={"/assets/icons/Element_Icon_Thunder.png"} height={25} width={25}
                                      onClick={() => onElementFilterChange(5)} disable={!elementFilter[5]} />
                            <WotvIcon url={"/assets/icons/Element_Icon_Water.png"} height={25} width={25}
                                      onClick={() => onElementFilterChange(6)} disable={!elementFilter[6]} />
                            <WotvIcon url={"/assets/icons/Element_Icon_Shine.png"} height={25} width={25}
                                      onClick={() => onElementFilterChange(7)} disable={!elementFilter[7]} />
                            <WotvIcon url={"/assets/icons/Element_Icon_Dark.png"} height={25} width={25}
                                      onClick={() => onElementFilterChange(8)} disable={!elementFilter[8]} />
                        </div>
                    }
                </div>
            </Fieldset>
            <UnitMetaLineChart data={chartData} keys={chartKeys} maxKeys={maxKeys} domain={chartDomain} />
        </>
    );
};