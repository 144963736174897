import React from 'react';
import {useParams} from "react-router-dom";
import {MetaUnitChartPanel} from "../components/chart/MetaUnitChartPanel";
import {RankProgressionChartDashlet} from "../components/dashlet/RankProgressionChartDashlet";
import {GuildRosterDashlet} from "../components/dashlet/GuildRosterDashlet";
import {GuildProfileBattleHistoryDashlet} from "../components/dashlet/GuildProfileBattleHistoryDashlet";
import {GuildNameChangesDashlet} from "../components/dashlet/GuildNameChangesDashlet";
import {GuildRosterActivityDashlet} from "../components/dashlet/GuildRosterActivityDashlet";
import { MetaUnitTablePanel } from "../components/chart/MetaUnitTablePanel";


export const GuildProfilePage = () => {

    let {guildId} = useParams();
    let guildIdNumber = +guildId!

    return (
        <div className="layout-dashboard" style={{marginTop: "5px"}}>
            <div className="grid">
                <div className="col-12 lg:col-8">
                    <div className="card widget-table">
                        <GuildProfileBattleHistoryDashlet guildId={guildIdNumber} />
                    </div>
                </div>
                <div className="col-12 lg:col-4">
                    <div className="card widget-table">
                        <RankProgressionChartDashlet guildId={guildIdNumber} />
                    </div>
                </div>

                <div className="col-12 lg:col-4">
                    <div className="card widget-table">
                        <GuildRosterDashlet guildId={guildIdNumber} />
                    </div>
                </div>

                <div className="col-12 lg:col-4">
                    <div className="card widget-table">
                        <GuildRosterActivityDashlet guildId={guildIdNumber} hideGuildName sort={"DATE"} />
                    </div>
                </div>

                <div className="col-12 lg:col-4">
                    <div className="card widget-table">
                        <GuildNameChangesDashlet guildId={guildIdNumber} />
                    </div>
                </div>

                <div className="col-12 lg:col-6">
                    <div className="card widget-table">
                        <MetaUnitChartPanel guildId={guildIdNumber} partyType={"A"} hideRankInputs
                                            hideMaxResultsFilter />
                    </div>
                </div>
                <div className="col-12 lg:col-6">
                    <div className="card widget-table">
                        <MetaUnitChartPanel guildId={guildIdNumber} partyType={"D"} hideRankInputs hideMaxResultsFilter
                                            hideAbsentFilter />
                    </div>
                </div>

                <div className="col-12 lg:col-6">
                    <div className="card widget-table" style={{ marginTop: "5px" }}>
                        <MetaUnitTablePanel guildId={guildIdNumber} showDashboardLegend partyType={"A"} numBattlesBack={1} hideRankInputs />
                    </div>
                </div>
                <div className="col-12 lg:col-6">
                    <div className="card widget-table" style={{ marginTop: "5px" }}>
                        <MetaUnitTablePanel guildId={guildIdNumber} showDashboardLegend partyType={"D"} numBattlesBack={1} hideRankInputs hideAbsentFilter />
                    </div>
                </div>
            </div>
        </div>

    );
};
