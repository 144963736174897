import * as React from "react";
import { Fieldset } from "primereact/fieldset";

export const FaqPage = () => {
    return (
        <div className="layout-dashboard" style={{ marginTop: "5px" }}>
            <div className="grid">
                <div className="col-12">
                    <div className={"flex justify-content-center"}>
                        <span className={"text-3xl"}>FAQ</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card widget-table">
                        <Fieldset legend={<span className={"text-cyan-400"}>What is the purpose of this site?</span>}>
                            <p className={"line-height-4"}>
                                To gain knowledge about the game, the changing meta, and the players, with the hope of
                                increasing engagement and interest in guild battles.
                            </p>
                            <p className={"line-height-4"}>
                                Guild leaders can see what is going on with the gulids around them as well as keep track
                                of their own players.
                            </p>
                            <p className={"line-height-4"}>
                                Anyone can talk about the "meta" and actually see the numbers and details about how
                                units are used.
                            </p>
                            <p className={"line-height-4"}>
                                Players can track their own battle history.
                            </p>
                            <p className={"line-height-4"}>
                                Data is fun.
                            </p>
                        </Fieldset>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card widget-table">
                        <Fieldset legend={<span className={"text-cyan-400"}>Where does the data come from?</span>}>
                            <p className={"line-height-4"}>
                                From the same tech that brings you our datamine information and the amazing <a
                                href={"https://wotv-calc.com"} target={"_blank"}>wotv-calc</a>,
                                this data is gathered by decrypting the client/server communications at the time that a
                                player is checking the Guild Battle results screen
                                after it ends each day.
                            </p>
                            <p className={"line-height-4"}>
                                It takes a few hours each day to retrieve the data so there will be a delay
                            </p>
                        </Fieldset>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card widget-table">
                        <Fieldset legend={<span className={"text-cyan-400"}>How often does the data get updated?</span>}>
                            <p className={"line-height-4"}>
                                It takes a few hours each day to retrieve the data, starting a few minutes after the end
                                of each guild battle.
                            </p>
                        </Fieldset>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card widget-table">
                        <Fieldset legend={<span className={"text-cyan-400 line-height-2"}>Why are there gaps in the data? Where's the data for limited guild battles?</span>}>
                            <p className={"line-height-4"}>
                                Unfortunately, I don't have the ability to pull the data from limited and guild
                                supremacy battles. These days will just not have any data.
                            </p>
                        </Fieldset>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card widget-table">
                        <Fieldset legend={<span className={"text-cyan-400 line-height-2"}>How come I can't find my guild or my player information?</span>}>
                            <p className={"line-height-4"}>
                                A script pulls data for the top 500-ish guilds every day. Although there are many
                                more guilds than that, most of the data from guilds beyond the top 500 is not very
                                useful. Many of those players are not attacking in guild battles with any regularity or
                                not even setting teams.
                            </p>
                            <p className={"line-height-4"}>
                                If you belong to a top 500 guild but still can't find your data, there could be an error
                                and you may <a href={"/#/contact"}>contact me</a> to get it fixed.
                            </p>
                            {/*                            <p className={"line-height-4"}>
                                My data goes back to when I started initially just pulling the data and storing it, July
                                2021.
                            </p>*/}
                        </Fieldset>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card widget-table">
                        <Fieldset legend={<span className={"text-cyan-400 line-height-2"}>The data looks wrong, can you fix it?</span>}>

                            <p className={"line-height-4"}>
                                If there is something that doesn't look right, please <a href={"/#/contact"}>let me
                                know</a>. I may not be processing something correctly.
                            </p>
                        </Fieldset>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card widget-table">
                        <Fieldset legend={<span className={"text-cyan-400 line-height-2"}>I have a feature request, can you build it?</span>}>
                            <p className={"line-height-4"}>
                                Sure, always looking for new use cases and build new functionality. <a
                                href={"/#/contact"}>Contact me</a> and we can talk about it. Thanks!
                            </p>
                        </Fieldset>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card widget-table">
                        <Fieldset legend={<span className={"text-cyan-400 line-height-2"}>This site is awesome! Can I buy you a coffee?</span>}>
                            <p className={"line-height-4"}>
                                Nope. No donations.
                            </p>
                            <p className={"line-height-4"}>
                                Buy your friend a coffee and have <i>them</i> thank me directly and that would be really
                                weird but cool!
                            </p>
                        </Fieldset>
                    </div>
                </div>
            </div>
        </div>
    );
};
