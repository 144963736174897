import React from "react";
import { PartyImage } from "./PartyImage";
import StarImage from "./icon/StarImage";
import { ShieldImage } from "./icon/ShieldImage";
import { WotvIcon } from "./icon/WotvIcon";
import { UnitPartyCompositionStats } from "../components/wotv/UnitPartyCompositionChart";

export const PartyCompositionImage = (props: UnitPartyCompositionStats) => {

    let width = 210;

    if (props.reserveUnit1 !== 0) {
        width += 55;
    }

    if (props.reserveUnit2 !== 0) {
        width += 55;
    }

    return <div style={{
        height: "85px",
        width: "20px",
        minWidth: width + "px",
        backgroundSize: width + "px 85px",
        margin: "0px",
        padding: "0px",
        borderSpacing: "0px",
        // paddingLeft: "20px",
        backgroundImage: props.partyType === "A" ? "url(/assets/gb/guildbattle_list_player_wide_v3.png)" : "url(/assets/gb/guildbattle_list_enemy_wide_v3.png)"
        // backgroundColor: partyItem.partyType === "A" ? "rgba(51,73,255,0.40)" : "rgba(255,51,51,0.4)",
        // borderRadius: "10px"
    }}>
        <div className="flex justify-content-between align-items-center"
             style={{
                 padding: "4px 13px 0px 13px",
                 width: width,
                 lineHeight: "20px",
                 fontWeight: 500,
                 color: "white",
                 marginLeft: "0px",
                 fontSize: "13px"
             }}>
            <div>
            <WotvIcon url={"/assets/icons/multiple_users_icon.png"} height={16} width={15}
                      title="Total times seen" />
            <span style={{ marginLeft: "5px" }}>{props.totalTeams}</span>
            </div>
            <div>
                <WotvIcon url={"/assets/icons/filter_icon.png"} height={16} width={16} title="Filtered parties" />
            <span style={{ marginLeft: "5px" }}>{props.numTeamsFiltered}</span>
            </div>

        </div>
        <div className={"flex justify-content-start"} style={{ padding: "4px 0px 0px 15px" }}>
            <div style={{ width: width - 63 }}><PartyImage {...props} unit1Status={props.unit1Alive ? "A" : "D"}
                                                           unit2Status={props.unit2Alive ? "A" : "D"}
                                                           unit3Status={props.unit3Alive ? "A" : "D"}
                                                           reserveUnit1Status={props.reserveUnit1Alive ? "A" : "D"}
                                                           reserveUnit2Status={props.reserveUnit2Alive ? "A" : "D"}
                                                           scrollToTopOnClick={true} /></div>

            <div className={"flex flex-column"} style={{ marginTop: props.guildRank ? -5 : 0 }}>

                {props.partyType === "D" && <div style={{ marginLeft: 2, marginTop: 10 }}><ShieldImage /><span
                    style={{ marginLeft: "5px", fontWeight: 500 }}>{isNaN(props.defends) ? "-" : props.defends.toFixed(1)}</span></div>}

                {props.partyType === "A" &&
                    <div><StarImage /><span style={{ marginLeft: 4, fontWeight: 500 }}>{isNaN(props.defends) ? "-" : props.stars.toFixed(1)}</span>
                    </div>}

                {props.partyType === "A" &&
                    <div><WotvIcon url={"/assets/icons/toilet_paper.png"} height={15} width={15}
                                   title="1st attack failures" /><span
                        style={{ marginLeft: 4, fontWeight: 500 }}>{props.numWipes}</span></div>}

                <div className={"flex align-items-center"}
                     style={{ marginTop: props.partyType === "A" ? "0px" : "3px" }}
                ><WotvIcon url={"/assets/icons/mili_rank.png"} height={15} width={15} title={"Median guild rank"} /><span
                    style={{
                        marginLeft: props.partyType === "A" ? "4px" : "6px",
                        fontWeight: 500,
                    }}>{props.guildRank}</span></div>
            </div>
        </div>
    </div>;
};