const arrows: { [index: string]: string } = {
    down: "/assets/icons/redarrowdown.png",
    up: "/assets/icons/greenarrowup.png",
}

export type ArrowImageProps = {
    direction: string
}

const ArrowImage = ({direction}: ArrowImageProps) => {
    return <img style={{height: "10px", width: "10px", marginLeft: "4px", verticalAlign: "middle"}}
                src={arrows[direction]} alt={""}/>
}

export default ArrowImage;