import { elementColorMap, unitElementMapSelector, unitNameDataAtom } from "../../utilities/WotvDataUtil";
import * as React from "react";
import { UnitImage } from "../../graphics/UnitImage";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useRecoilValue } from "recoil";
import { MetaChartDataItem } from "../chart/UnitMetaLineChart";


export interface UnitMetaTableItem {
    numTeams: number;
    numUnrevealed: number;
    numAbsent?: number;
    unitIname: string;
    unitDisplayName: string;
    unitCount: number;
}

export interface UnitMetaTableProps {
    data: MetaChartDataItem,
    keys: string[],
    maxKeys: number
    title?: string,

    domain: number[],

    chartHeight?: number,

    onActiveDotClick?: (MetaChartDataItem: string) => void;
    individualUnitOnly?: boolean
}

export const UnitMetaTable = (unitMetaLineChartProps: UnitMetaTableProps) => {

    let unitElementMap = useRecoilValue(unitElementMapSelector);
    const unitNameMap = useRecoilValue(unitNameDataAtom);

    let metaChartDataItem = unitMetaLineChartProps.data;
    let chartKeys = unitMetaLineChartProps.keys;

    let tableData: UnitMetaTableItem[] = [];

    for (const chartKey of chartKeys) {
        tableData.push({
            numAbsent: metaChartDataItem.individualUnitAbsentCount![chartKey],
            numTeams: metaChartDataItem.numTeams,
            numUnrevealed: metaChartDataItem.numUnrevealed,
            unitIname: chartKey,
            unitDisplayName: unitNameMap[chartKey],
            // @ts-ignore
            unitCount: metaChartDataItem[chartKey] as number
        });
    }

    tableData.sort((a, b) => {
        if (a.unitCount === b.unitCount) {
            return a.unitDisplayName.localeCompare(b.unitDisplayName);
        } else {
            return b.unitCount - a.unitCount;
        }
    });

    return (<DataTable id={"unit-meta-table-attack"} value={tableData} dataKey="sessionId" rows={10}
                       paginator={true}
                       stateKey="unit-meta-table-attack"
                       responsiveLayout="scroll"
                       rowHover
                       rowsPerPageOptions={[10, 20, 30, 50, 1000]}
    >
        <Column field="unit" style={{ minWidth: "60px", maxWidth: "60px" }} header="Unit"
                body={(unitMetaTableItem) => <div style={{ height: "35px", overflow: "hidden" }}>
                    <UnitImage unitIname={unitMetaTableItem.unitIname} width={60} height={60} />
                </div>}></Column>
        <Column field="unitName" style={{ minWidth: "90px" }} header="Name"
                body={(unitMetaTableItem) => <span style={{
                    borderBottom: "3px solid",
                    // @ts-ignore
                    borderColor: elementColorMap[unitElementMap[unitMetaTableItem.unitIname]]
                }}>
                    {unitMetaTableItem.unitDisplayName}
                    </span>}></Column>
        <Column field="numTeams" style={{ minWidth: "60px" }} header="Num Teams"
                body={(unitMetaTableItem) => unitMetaTableItem.unitCount}></Column>
        <Column field="percentage" style={{ minWidth: "60px" }} header="%"
                body={(unitMetaTableItem) => (unitMetaTableItem.unitCount / unitMetaTableItem.numTeams * 100).toFixed(2)}></Column>
    </DataTable>);
};