import React from "react";

export const FlexIcon = ({height, dead = false}: { height?: number, dead?: boolean }) => {
    return <img
        src="/assets/icons/flex_icon.png"
        style={{
            height: height,
            lineHeight: height,
            verticalAlign: "middle",
            filter: `brightness(${dead ? "40%" : "100%"})`,
        }}
        title="Fresh team clears"
    ></img>
}