import {selectorFamily, useRecoilValue_TRANSITION_SUPPORT_UNSTABLE} from "recoil";
import {PlayerChangeRowItem} from "../../service/GuildBattleDataService";
import * as React from "react";
import {useState} from "react";
import {Fieldset} from "primereact/fieldset";
import { compareDesc, isEqual } from "date-fns";
import {InputText} from "primereact/inputtext";
import {playerChangesByPlayerIdSelector} from "./PlayerNameChangesDashlet";
import {PlayerActivityTable} from "../table/PlayerActivityTable";
import {convertLocalDateArrayToDate} from "../../utilities/WotvDataUtil";
import { WotvIcon } from "../../graphics/icon/WotvIcon";

export interface PlayerActivityDashletProps {
    playerId: number

    hideGuildName?: boolean
    hideDateColumn?: boolean
    hideEnemyGuildName?: boolean
    hideCalendarInput?: boolean

    showCrashes?: boolean
}

export type PlayerChangeFilters = {
    minusChecked: boolean,
    plusChecked: boolean,
    rankChecked: boolean,
    playerId: number
}


export const playerActivityRowItemSelector = selectorFamily({
    key: 'playerActivityRowItemSelector',
    get: (filters: PlayerChangeFilters) => ({get}) => {

        let playerChangeEventItems = get(playerChangesByPlayerIdSelector(
            filters.playerId
        ));

        let playerChangeRowItems: PlayerChangeRowItem[] = [];

        playerChangeEventItems.forEach((playerChangeEventItem) => {
            if (playerChangeEventItem.action === "JOINED" || playerChangeEventItem.action === "LEFT") {
                playerChangeRowItems.push({
                        date: convertLocalDateArrayToDate(playerChangeEventItem.date),
                        guildId: playerChangeEventItem.guildId,
                        guildName: playerChangeEventItem.guildName,
                        playerId: playerChangeEventItem.playerId,
                        playerName: playerChangeEventItem.name,
                        playerPosition: playerChangeEventItem.position,
                        playerPositionOld: playerChangeEventItem.oldPosition,
                        guildAction: playerChangeEventItem.action
                    } as PlayerChangeRowItem
                )
            } else if (playerChangeEventItem.oldPosition) {
                // this is a rank change
                playerChangeRowItems.push({
                    date: convertLocalDateArrayToDate(playerChangeEventItem.date),
                    guildId: playerChangeEventItem.guildId,
                    guildName: playerChangeEventItem.guildName,
                    playerId: playerChangeEventItem.playerId,
                    playerName: playerChangeEventItem.name,
                    playerPosition: playerChangeEventItem.position,
                    playerPositionOld: playerChangeEventItem.oldPosition,
                    guildAction: "POSITION_CHANGED"
                } as PlayerChangeRowItem)
            }
        });

        playerChangeRowItems.sort((a,b) => {
            if (isEqual(a.date, b.date)){
                if (a.guildAction === "JOINED") {
                    return 1;
                }

                if (a.guildAction === "LEFT") {
                    return -1;
                }

                return 0;
            } else {
                return compareDesc(a.date, b.date);
            }
        })
        const allUnchecked = !filters.plusChecked && !filters.minusChecked && !filters.rankChecked;

        return playerChangeRowItems.filter(item => {
            if (allUnchecked) {
                return true;
            }

            switch (item.guildAction) {
                case "JOINED":
                    return filters.plusChecked || (item.playerPosition !== "M" && filters.rankChecked);
                case "LEFT":
                    return filters.minusChecked || (item.playerPosition !== "M" && filters.rankChecked);
                case "POSITION_CHANGED" :
                    return filters.rankChecked;
                case "NAME_CHANGED":
                    return false;
            }
        });
    }
});

export const PlayerActivityDashlet = (props: PlayerActivityDashletProps) => {
    const [minusChecked, setMinusChecked] = useState(false);
    const [plusChecked, setPlusChecked] = useState(false);
    const [rankChecked, setRankChecked] = useState(false);

    const playerChangeRowItems = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(playerActivityRowItemSelector({
        playerId: props.playerId,
        minusChecked: minusChecked,
        plusChecked: plusChecked,
        rankChecked: rankChecked
    }));

    return (
        <>
            <Fieldset legend={"Guild Activity"}>
                <div className="flex align-items-center " style={{columnGap: "10px", rowGap: "10px", flexWrap: "wrap"}}>
                    <div className="p-inputgroup" style={{maxWidth: "100px"}}>
                        <span className="p-inputgroup-addon">
                            Total
                        </span>
                        <InputText value={playerChangeRowItems.length} readOnly></InputText>
                    </div>
                    <div className="p-inputgroup align-items-center" style={{maxWidth: "200px"}}>
                        <span className="p-inputgroup-addon">
                            Filters
                        </span>
                        <div style={{ marginLeft: 10 }}>
                            <WotvIcon url={"/assets/icons/greenplus2.png"} height={20} width={20}
                                      onClick={() => setPlusChecked(!plusChecked)}
                                      disable={!plusChecked}/>
                        </div>

                        <div style={{ marginLeft: 10 }}>
                            <WotvIcon url={"/assets/icons/minus2.png"} height={20} width={20}
                                      onClick={() => setMinusChecked(!minusChecked)}
                                      disable={!minusChecked}/>
                        </div>

                        <div style={{ marginLeft: 10 }}>
                            <WotvIcon url={"/assets/icons/mili_rank.png"} height={20} width={20}
                                      onClick={() => setRankChecked(!rankChecked)}
                                      disable={!rankChecked}/>
                        </div>
                    </div>
                </div>
            </Fieldset>
            <PlayerActivityTable playerChangeRowItems={playerChangeRowItems}
                                 hideGuildName={props.hideGuildName}
                                 hideDateColumn={props.hideDateColumn}
            />
        </>);
}