import * as React from 'react';
import {MetaUnitChartPanel} from "../components/chart/MetaUnitChartPanel";
import { MetaUnitTablePanel } from "../components/chart/MetaUnitTablePanel";

export const MetaPage = () => {
    return (
        <div className="layout-dashboard" style={{marginTop: "5px"}}>
            <div className="grid">
                <div className="col-12">
                    <div className="card widget-table" style={{ marginTop: "5px" }}>
                        <MetaUnitChartPanel showDashboardLegend partyType={"A"} numBattlesBack={60} />
                    </div>
                </div>
                <div className="col-12">
                    <div className="card widget-table" style={{ marginTop: "5px" }}>
                        <MetaUnitChartPanel showDashboardLegend partyType={"D"} numBattlesBack={60} hideAbsentFilter />
                    </div>
                </div>
                <div className="col-12 lg:col-6">
                    <div className="card widget-table" style={{ marginTop: "5px" }}>
                        <MetaUnitTablePanel showDashboardLegend partyType={"A"} numBattlesBack={1} />
                    </div>
                </div>
                <div className="col-12 lg:col-6">
                    <div className="card widget-table" style={{ marginTop: "5px" }}>
                        <MetaUnitTablePanel showDashboardLegend partyType={"D"} numBattlesBack={1} hideAbsentFilter />
                    </div>
                </div>
            </div>
        </div>
    );
};
