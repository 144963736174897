import React from "react";
import { unitDataAtom, unitNameDataAtom } from "../../utilities/WotvDataUtil";
import { UnitImage } from "../../graphics/UnitImage";
import { useRecoilValue } from "recoil";

export interface UnitsDashletProps {
    element: number;
}

export const UnitsDashlet = (props: UnitsDashletProps) => {
    const unitData = useRecoilValue(unitDataAtom);
    const unitNameMap = useRecoilValue(unitNameDataAtom);
    let items = unitData.items;
    // @ts-ignore
    let units = items.filter(unit => unit["type"] === 0
        && unit["elem"][0] === props.element
        && unit["iname"].includes("_P_")
        && typeof unit["piece"] !== "undefined"
    );

    // @ts-ignore
    units.sort((a, b) => {
        if (a["rare"] === b["rare"]) {
            return unitNameMap[a["iname"]].localeCompare(unitNameMap[b["iname"]]);
        } else {
            return b["rare"] - a["rare"];
        }
    });

    return (
        <>
            {
                // @ts-ignore
                units.map((unit) => {
                    return <UnitImage height={100} width={100} unitIname={unit["iname"]} />;
                })
            }
        </>
    );
};