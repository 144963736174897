import { useRecoilValue } from "recoil";
import { findPartiesSelector, guildRosterByGuildIdAndDateSelector } from "../../service/GuildBattleDataService";
import * as React from "react";
import { Fieldset } from "primereact/fieldset";
import { compareDesc } from "date-fns";
import { InputText } from "primereact/inputtext";
import {
    convertLocalDataArrayToString,
    convertLocalDateArrayToDate,
    convertLocalDateArrayToString
} from "../../utilities/WotvDataUtil";
import { rosterSelectedDateAtom } from "./GuildRosterDashlet";
import { useNavigate } from "react-router-dom";
import { useTransition } from "react";
import { GuildBattleItem, PartyItem } from "../../types/wotv-guild-data-api";

export interface PlayerProfileInfoDashletProps {
    playerId: number;
}

export const PlayerProfileInfoDashlet = (props: PlayerProfileInfoDashletProps) => {

    const navigate = useNavigate();
    const [inTransition, startTransition] = useTransition();

    const onGuildNameClick = (guildId: number) => {
        startTransition(() => {
            navigate("/guild/" + guildId);
        })
    }

    let parties = useRecoilValue(findPartiesSelector({
        playerId: props.playerId
    }));

    let partiesCopy = [...parties];

    partiesCopy.sort((a, b) => compareDesc(convertLocalDateArrayToDate(a.date), convertLocalDateArrayToDate(b.date)));

    let latestPartyItem = partiesCopy[0];

    let attackParties = partiesCopy.filter(party => party.partyType === "A" && party.playerId === props.playerId);
    attackParties.sort((a, b) => compareDesc(convertLocalDateArrayToDate(a.date), convertLocalDateArrayToDate(b.date)));

    return (
        <>
            <Fieldset legend={"Player Profile"}>
                <div className="flex align-items-center "
                     style={{ columnGap: "10px", rowGap: "10px", flexWrap: "wrap" }}>
                    <div className="p-inputgroup" style={{ maxWidth: "225px" }}>
                        <span className="p-inputgroup-addon">
                            Name
                        </span>
                        <InputText value={latestPartyItem.playerName} readOnly></InputText>
                    </div>
                    <div className="p-inputgroup" style={{ maxWidth: "225px" }}>
                        <span className="p-inputgroup-addon">
                            Guild
                        </span>
                        <InputText className={"cursor-pointer"} value={latestPartyItem.guildName} readOnly onClick={() => onGuildNameClick(latestPartyItem.guildId)} ></InputText>
                    </div>
                 <div className="p-inputgroup" style={{ maxWidth: "175px" }}>
                        <span className="p-inputgroup-addon">
                            Last Seen
                        </span>
                        <InputText value={convertLocalDateArrayToString(latestPartyItem.date)} readOnly></InputText>
                    </div>
                    <div className="p-inputgroup" style={{ maxWidth: "175px" }}>
                        <span className="p-inputgroup-addon">
                            Battles Fought
                        </span>
                        <InputText value={attackParties.length} readOnly></InputText>
                    </div>
                </div>
            </Fieldset>
        </>);
};