import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import * as React from "react";
import { TooltipProps } from "recharts/types/component/Tooltip";
import { UnitImage } from "../../graphics/UnitImage";
import { PartyType } from "../../types/wotv-guild-data-api";

export interface UnitGuildRankUsageChartItem {
    unitIname: string;
    element: number;
    numTeams: number;
    numAbsent: number;
    numUnrevealed: number;
    minGuildRank: number;
    maxGuildRank: number;
    count: number;
}

export interface UnitGuildRankUsageChartProps {
    chartData: UnitGuildRankUsageChartItem[]
    partyType: PartyType

    chartHeight?: number
}

export const UnitGuildRankUsageChart = (props: UnitGuildRankUsageChartProps) => {

    const chartHeight = props.chartHeight || 600

    const customTooltipContent = (props: TooltipProps<number, string>): JSX.Element => {
        if (props.active && props.payload && props.payload.length) {
            let payload = props.payload[0].payload;
            return (
                <div className="card custom-tooltip" style={{background: "rgba(0, 0, 0, 0.60)"}}>
                    <div className={"flex justify-content-center"} style={{margin: "-10px 0px -15px 0px"}}><UnitImage unitIname={payload.unitIname} width={90} height={90}/></div>
                    <div className={"flex justify-content-between"}><span>Min Rank: &nbsp;</span><span> {payload.minGuildRank}</span></div>
                    <div className={"flex justify-content-between"}><span>Max Rank: &nbsp; </span><span> {payload.maxGuildRank}</span></div>
                    <div className={"flex justify-content-between"}><span>Count: &nbsp; </span><span> {payload.count}</span></div>
                </div>
            );
        }

        return <></>;
    };

    return (
        <ResponsiveContainer width="100%" height={chartHeight}>
            <BarChart data={props.chartData}
                      margin={{ top: 50, right: 0, left: -25, bottom: 5 }}
            >
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis dataKey="minGuildRank" reversed allowDecimals={false} style={{fill: "#FFFFFF"}} />
                <YAxis tickCount={10} allowDecimals={false} style={{fill: "#FFFFFF"}} />
                <Bar type="monotone" dataKey={"count"}
                     fill={props.partyType === "D" ? "#ff0000" : "#003cff"}
                />
                <Tooltip content={customTooltipContent}  cursor={{ fill: "rgba(206, 206, 206, 0.05)" }} />
            </BarChart>
        </ResponsiveContainer>
    );
};