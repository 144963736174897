import {
    convertLocalDateArrayToDate,
    convertLocalDateStringToDate,
    convertToLocalDateString
} from "../../utilities/WotvDataUtil";
import * as React from 'react'
import {useState, useTransition} from 'react'
import {useNavigate} from "react-router-dom";
import {RankProgressionLineChart} from "../chart/RankProgressionLineChart";
import {CalendarValueType} from "primereact/calendar";
import { closestTo, isAfter, isBefore, isEqual, max, min, subDays } from "date-fns";
import {ReadyDateCalendarInput} from "../wotv/ReadyDateCalendarInput";
import {Fieldset} from "primereact/fieldset";
import {selectorFamily, useRecoilValue_TRANSITION_SUPPORT_UNSTABLE} from "recoil";
import {guildBattleByGuildId} from "../../service/GuildBattleDataService";

export type RankProgressionChartPanelProps = {
    guildId: number
}

export type RankProgressionFilters = {
    minDate: string,
    maxDate: string,
    guildId: number
}

const filteredGuildBattleItemsSelector = selectorFamily({
    key: 'filteredGuildBattleItemsSelector',
    get: (props: RankProgressionFilters) => ({get}) => {

        let guildBattleItems = get(guildBattleByGuildId({
            guildId: props.guildId
        }));

        return guildBattleItems.filter(item => {
            let date = convertLocalDateArrayToDate(item.date);
            let fromDate = convertLocalDateStringToDate(props.minDate);
            let toDate = convertLocalDateStringToDate(props.maxDate);
            return ((isEqual(date, fromDate) || isEqual(date, toDate))
                || (isAfter(date, fromDate) && isBefore(date, toDate)))
        });
    }
});

export const RankProgressionChartDashlet = (props: RankProgressionChartPanelProps) => {
    const navigate = useNavigate();
    const [inTransition, startTransition] = useTransition();

    const guildBattleItems = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(guildBattleByGuildId({
        guildId: props.guildId
    }))

    let guildBattleDates = guildBattleItems.map(item => convertLocalDateArrayToDate(item.date));

    let maxDate = max(guildBattleDates);
    let maxDateString = convertToLocalDateString(maxDate);

    let minDate = min(guildBattleDates);

    let minDateString = convertToLocalDateString(minDate!);

    let firstDate = closestTo(subDays(maxDate, 90), guildBattleDates);

    const [calendarFromDateString, setCalendarFromDateString] = useState<string>(convertToLocalDateString(firstDate!));
    const [calendarToDateString, setCalendarToDateString] = useState<string>(maxDateString);

    const filteredGuildBattleItems = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(filteredGuildBattleItemsSelector({
        minDate: calendarFromDateString,
        maxDate: calendarToDateString,
        guildId: props.guildId
    }))

    const onCalendarFromChange = (calendarValue: CalendarValueType): calendarValue is Date => {
        let calendarDate = calendarValue as Date;
        let calendarToDate = convertLocalDateStringToDate(calendarToDateString);
        if (isAfter(calendarDate, calendarToDate)) {
            calendarDate = calendarToDate
        }
        startTransition(() => {
            setCalendarFromDateString(convertToLocalDateString(calendarDate));
        })
        return true;
    }
    const onCalendarToChange = (calendarValue: CalendarValueType) => {
        let calendarDate = calendarValue as Date;
        let calendarFromDate = convertLocalDateStringToDate(calendarFromDateString);
        if (isBefore(calendarDate, calendarFromDate)) {
            calendarDate = calendarFromDate
        }
        startTransition(() => {
            setCalendarToDateString(convertToLocalDateString(calendarDate));
        })
        return true;
    }

    return (<>
            <Fieldset legend={"Rank Progression"}>
                <div className="flex justify-content-start"
                     style={{columnGap: "10px", rowGap: "10px", flexWrap: "wrap"}}>
                    <div className="p-inputgroup" style={{maxWidth: "200px"}}>
                        <span className="p-inputgroup-addon">From</span>
                        <ReadyDateCalendarInput
                            date={calendarFromDateString} minDate={minDateString} maxDate={maxDateString}
                            onChange={onCalendarFromChange}/>
                    </div>

                    <div className="p-inputgroup" style={{maxWidth: "200px"}}>
                        <span className="p-inputgroup-addon">To</span>
                        <ReadyDateCalendarInput
                            date={calendarToDateString} minDate={minDateString} maxDate={maxDateString}
                            onChange={onCalendarToChange}/>
                    </div>
                </div>
            </Fieldset>
            <RankProgressionLineChart guildBattleItems={filteredGuildBattleItems}/>
        </>
    );
}