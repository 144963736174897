import * as React from "react";
import { Fieldset } from "primereact/fieldset";

export const LegalPage = () => {
    return (
        <div className="layout-dashboard" style={{ marginTop: "5px" }}>
            <div className="grid">
                <div className="col-12">
                    <div className={"flex justify-content-center"}>
                        <span className={"text-3xl"}>Legal</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card widget-table">
                        <Fieldset legend={<span className={"text-cyan-400 line-height-2"}>Terms and conditions</span>}>
                            <p className={"line-height-4"}>
                                This site is not affiliated in any way with War of the Visions: Final Fantasy Brave Exvius, gumi Inc., or Square Enix Holdings Co., Ltd.
                            </p>
                            <p className={"line-height-4"}>
                                All data, images, and other assets owned and trademarked by Square Enix Holdings Co., Ltd.
                            </p>
                            <p className={"line-height-4"}>
                                You may share or link any of the information on this website without any permission required.
                            </p>
                        </Fieldset>
                    </div>
                </div>
            </div>
        </div>
    );
};
