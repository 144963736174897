import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { elementColorMap, unitElementMapSelector } from "../../utilities/WotvDataUtil";
import * as React from "react";
import { ReactElement } from "react";
import { UnitImage } from "../../graphics/UnitImage";
import { useRecoilValue } from "recoil";

export interface GuildBattleMetaChartItem {
    numTeams: number;
    numUnrevealed: number;
}

export interface GuildBattleMetaChartProps {
    data: GuildBattleMetaChartItem,
    keys: string[],
    title?: string,
    offsetMap: Map<string, number>
    // domain: number[],
}

export const GuildBattleMetaChart = (props: GuildBattleMetaChartProps) => {

    let unitElementMap = useRecoilValue(unitElementMapSelector);

    let chartData = props.data;
    let chartKeys = props.keys;

    // @ts-ignore
    const customizedLabel = (chartProps, labelKey: string, chartData: GuildBattleMetaChartItem): ReactElement<SVGElement> => {
        const imgHeight = 50;
        const imgWidth = 50;
        const labelElementNumber = +unitElementMap[labelKey];

        let offset = 0;

        // @ts-ignore
        let labelCount = chartData[labelKey];
        const unitHeight: number = chartProps.height / labelCount;

        let foundMyself = false;

        chartKeys.forEach((key) => {
            foundMyself = foundMyself || (labelKey === key);

            if (!foundMyself) {
                return;
            }

            if (labelKey === key) {
                return;
            }

            // @ts-ignore
            let count = chartData[key];

            let otherUnitElementNumber = +unitElementMap[key];

            if (labelElementNumber !== otherUnitElementNumber) {
                return;
            }

            let calculatedHeight = count * unitHeight;

            if (calculatedHeight < 20) {
                offset++;
            }
        });

        return (
            <foreignObject width={50} height={50} x={chartProps.x + (chartProps.width / 2) - 15}
                           y={chartProps.y + (chartProps.height / 2) - 15 - (offset * (30 - unitHeight))}>
                <UnitImage unitIname={labelKey} width={imgWidth} height={imgHeight} />
            </foreignObject>
        );
    };

    return (
        <ResponsiveContainer width="100%" height={600}>
            <BarChart data={[chartData]}
                      margin={{ top: 50, right: 0, left: -25, bottom: 5 }}
                      barSize={50}
                      reverseStackOrder
            >
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis dataKey="date" />
                <YAxis tickCount={10} style={{fill: "#FFFFFF"}}/>
                {
                    chartKeys.map((key) => {
                        if (key === "numTeams" || key === "numUnrevealed" || key === "offsetMap") {
                            return <></>;
                        }
                        let elementNumber = +unitElementMap[key];
                        let elementColorMapElement = elementColorMap[elementNumber];

                        return <Bar type="monotone" dataKey={key}
                                    isAnimationActive={false}
                                    stackId={elementNumber}

                                    fill={elementColorMapElement}
                                    label={(props) => customizedLabel(props, key, chartData)}
                                    style={{ stroke: "#000", strokeWidth: 2 }}
                        />;
                    })
                }
            </BarChart>
        </ResponsiveContainer>
    );
};