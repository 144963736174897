import { selectorFamily } from "recoil";
import { compareAsc, isEqual } from "date-fns";
import { apiUrl } from "../Config";
import { GuildMetaItem, MetaAggregateItem } from "../types/wotv-guild-data-api";
import { dataReadyDatesSelector, idMapSelector } from "./ConfigService";
import {
    convertLocalDateArrayToDate,
    convertLocalDateArrayToString,
    convertLocalDateStringToDate,
    convertToLocalDateString,
    unitElementMapSelector
} from "../utilities/WotvDataUtil";
import { MetaChartDataItem } from "../components/chart/UnitMetaLineChart";
import { guildBattleByGuildId } from "./GuildBattleDataService";

export type GuildMetaRequestByGuildIdProps = {
    guildId: number, fromDate: string, toDate: string
}

export const guildMetaByIdAndDateSelector = selectorFamily({
    key: "guildMetaByIdAndDateSelector",
    get: ({ guildId, fromDate, toDate }: GuildMetaRequestByGuildIdProps) => ({ get }) => {

        let dataReadyDates = get(dataReadyDatesSelector);
        let latestDate = dataReadyDates[0];

        let previousBattleDate = dataReadyDates[Math.min(7, dataReadyDates.length - 1)];

        toDate = toDate || convertToLocalDateString(latestDate);
        fromDate = fromDate || convertToLocalDateString(previousBattleDate);

        return fetch(apiUrl() + "/meta/guild/" + guildId + "/" + fromDate + "/" + toDate)
            .then(response => response.json())
            .then((guildMetaItems: GuildMetaItem[]) => {
                guildMetaItems.sort((a, b) => compareAsc(convertLocalDateArrayToDate(a.date), convertLocalDateArrayToDate(b.date)));
                return guildMetaItems;
            });
    }
});

export type MetaAggregateRequestProps = {
    minRank?: number,
    maxRank?: number,
    fromDate?: string,
    toDate?: string,
    numBattlesBack?: number

    guildId?: number
    maxUnitRank?: number
    minUnitRank?: number

    elementFilter?: boolean[]
    absentFilterChecked?: boolean
    unitIdOnly?: number
}

export const metaAggregateSelector = selectorFamily({
    key: "metaAggregateSelector",
    get: (props: MetaAggregateRequestProps) => ({ get }) => {

        const minRank = props.minRank || 1;
        const maxRank = props.maxRank || 500;
        let dataReadyDates = get(dataReadyDatesSelector);
        let latestDate = dataReadyDates[0];

        const numBattlesBack = props.numBattlesBack || 30;

        let previousBattleDate = dataReadyDates[Math.min(numBattlesBack, dataReadyDates.length - 1)];

        const toDate = props.toDate || convertToLocalDateString(latestDate);
        const fromDate = props.fromDate || convertToLocalDateString(previousBattleDate);

        return fetch(apiUrl() + "/meta/aggregate/" + minRank + "/" + maxRank + "/" + fromDate + "/" + toDate)
            .then(response => response.json())
            .then((metaAggregateItems: MetaAggregateItem[]) => {

                return metaAggregateItems;
            });
    }
});

export type MetaItem = GuildMetaItem | MetaAggregateItem;

export const lineChartMetaData = selectorFamily({
    key: "lineChartMetaData", get: (props: MetaAggregateRequestProps) => ({ get }) => {

        const maxUnitRank = props.maxUnitRank || 10;
        const minUnitRank = props.minUnitRank || 1;

        let idMap = get(idMapSelector);
        const unitElementMap = get(unitElementMapSelector);

        let attackUnitData: MetaChartDataItem[] = [];
        let defendUnitData: MetaChartDataItem[] = [];

        let attackDataKeys: Set<string> = new Set();
        let defendDataKeys: Set<string> = new Set();

        let attackMaxCount = 0;
        let attackMinCount = 9999999;

        let defendMaxCount = 0;
        let defendMinCount = 9999999;

        let firstDate: number[] = [];
        let lastDate: number[] = [];

        let meta: MetaItem[];

        if (props.guildId) {
            let guildBattleItems = get(guildBattleByGuildId({ guildId: props.guildId }));

            let toDate = props.toDate || convertLocalDateArrayToString(guildBattleItems[0].date);

            const maxIndex = Math.min(6, guildBattleItems.length - 1);

            let fromDate = props.fromDate || convertLocalDateArrayToString(guildBattleItems[maxIndex].date);

            meta = get(guildMetaByIdAndDateSelector({
                guildId: props.guildId, fromDate: fromDate, toDate: toDate
            }));
        } else {
            meta = get(metaAggregateSelector({
                minRank: props.minRank,
                maxRank: props.maxRank,
                fromDate: props.fromDate,
                toDate: props.toDate,
                numBattlesBack: props.numBattlesBack
            }));
        }

        if (meta.length > 0) {
            firstDate = meta[0]["date"];
            lastDate = meta[meta.length - 1]["date"];

            let attackLastMetaEntries = meta.filter((meta) => meta.partyType === "A" && isEqual(convertLocalDateArrayToDate(meta.date), convertLocalDateArrayToDate(lastDate)));
            let defendLastMetaEntries = meta.filter((meta) => meta.partyType === "D" && isEqual(convertLocalDateArrayToDate(meta.date), convertLocalDateArrayToDate(lastDate)));

            if (props.guildId) {
                attackLastMetaEntries = meta.filter((meta) => meta.partyType === "A");
                defendLastMetaEntries = meta.filter((meta) => meta.partyType === "D");
            }

            if (props.unitIdOnly) {
                attackDataKeys.add(idMap[props.unitIdOnly]);
                defendDataKeys.add(idMap[props.unitIdOnly]);
            } else {


                attackLastMetaEntries.forEach((metaEntry) => {
                    let individualUnitCount = Object.entries(metaEntry.individualUnitCount);

                    let elementFilteredCount = individualUnitCount.filter((value, index) => {
                        const key = idMap[value[0]];
                        if (key === "~UNREVEALED~") {
                            return false;
                        }

                        const element = unitElementMap[key];

                        return !props.elementFilter || props.elementFilter[+element] || props.elementFilter.every((active) => !active);
                    });


                    elementFilteredCount.sort((a, b) => {
                        if (!props.absentFilterChecked) {
                            return b[1] - (metaEntry.individualUnitAbsentCount[b[0]] || 0) - (a[1] - (metaEntry.individualUnitAbsentCount[a[0]] || 0));
                        } else {
                            return b[1] - a[1];
                        }
                    });

                    elementFilteredCount.forEach((entry, index) => {
                        let key = idMap[entry[0]];

                        if (key !== "~UNREVEALED~") {
                            let keys;
                            if (metaEntry.partyType === "A") {
                                keys = attackDataKeys;
                            } else {
                                keys = defendDataKeys;
                            }

                            if (index + 1 >= minUnitRank && index + 1 <= maxUnitRank) {
                                keys.add(key);
                            }
                        }
                    });
                });

                defendLastMetaEntries.forEach((metaEntry) => {
                    let individualUnitCount = Object.entries(metaEntry.individualUnitCount);

                    let elementFilteredCount = individualUnitCount.filter((value, index) => {
                        const key = idMap[value[0]];
                        if (key === "~UNREVEALED~") {
                            return false;
                        }

                        const element = unitElementMap[key];

                        return !props.elementFilter || props.elementFilter[+element] || props.elementFilter.every((active) => !active);
                    });

                    elementFilteredCount.sort((a, b) => b[1] - a[1]);

                    elementFilteredCount.forEach((entry, index) => {
                        let key = idMap[entry[0]];

                        if (key !== "~UNREVEALED~") {
                            let keys;
                            if (metaEntry.partyType === "A") {
                                keys = attackDataKeys;
                            } else {
                                keys = defendDataKeys;
                            }

                            if (props.guildId) {
                                keys.add(key);
                            } else {
                                if (index + 1 >= minUnitRank && index + 1 <= maxUnitRank) {
                                    keys.add(key);
                                }
                            }
                        }
                    });
                });
            }
/*

            let validEntries = Object.entries(meta[meta.length-1].individualUnitCount).filter((entry) => {
                let keys = meta[meta.length-1].partyType === "A" ? attackDataKeys : defendDataKeys;
                return keys.has(idMap[entry[0]]);
            });

            const minEntry = validEntries.reduce((min, current) => {
                return current[1] < min[1] ? current : min;
            }, validEntries[0]);

            const maxEntry = validEntries.reduce((max, current) => {
                return current[1] > max[1] ? current : max;
            }, validEntries[0]);

            let overlapSize = Math.ceil((maxEntry[1]) / 30);

            const values: number[] = validEntries.map(tuple => tuple[1]);

// Sort the values in ascending order
            values.sort((a, b) => a - b);

// Initialize an empty array to store the buckets
            const buckets: number[][] = [];

// Iterate through the sorted values
            for (const value of values) {
                let currentBucket = buckets[buckets.length - 1];

                // Create a new bucket if no existing bucket or the current bucket is full
                if (!currentBucket || currentBucket.length === 3) {
                    currentBucket = [];
                    buckets.push(currentBucket);
                }

                // Check if the current value can be added to the current bucket
                if (currentBucket.length === 0 || (value - currentBucket[0]) <= overlapSize) {
                    currentBucket.push(value);
                } else {
                    // Create a new bucket and add the current value to it
                    currentBucket = [value];
                    buckets.push(currentBucket);
                }
            }

            console.log(overlapSize);
            console.log(buckets);

            // Iterate over the buckets
            for (let i = 1; i < buckets.length; i++) {
                const currentBucket = buckets[i];
                const previousBucket = buckets[i - 1];

                // Check if the minimum value of the current bucket is within 200 of the maximum value of the previous bucket
                if (currentBucket[0] - previousBucket[previousBucket.length - 1] <= overlapSize) {
                    // Calculate the increment value
                    const increment = previousBucket[previousBucket.length - 1] + overlapSize - currentBucket[currentBucket.length - 1];

                    // Increment all values of the current bucket by the same value
                    for (let j = 0; j < currentBucket.length; j++) {
                        currentBucket[j] += increment;
                    }
                }
            }

            console.log(buckets);
*/


            meta.forEach((metaEntry, index) => {

                let metaChartDataEntry: MetaChartDataItem = {
                    numTeams: metaEntry.numTeams,
                    numUnrevealed: metaEntry.numUnrevealed,
                    numAbsent: metaEntry.numAbsent,
                    date: convertLocalDateArrayToString(metaEntry.date),
                    individualUnitAbsentCount: metaEntry.individualUnitAbsentCount
                };

                let individualUnitCount = Object.entries(metaEntry.individualUnitCount);

                individualUnitCount.sort((a, b) => b[1] - a[1]);

                individualUnitCount.forEach((entry, index) => {
                    let idNum = entry[0];
                    let key = idMap[idNum];
                    let value = entry[1] as number;

                    let keys = metaEntry.partyType === "A" ? attackDataKeys : defendDataKeys;

                    if (keys.has(key)) {

                        if (!props.absentFilterChecked) {
                            value -= metaEntry.individualUnitAbsentCount[idNum] || 0;
                        }

                        // @ts-ignore
                        metaChartDataEntry[key] = value;

                        if (metaEntry.partyType === "A") {
                            attackMaxCount = Math.max(attackMaxCount, value);
                            attackMinCount = Math.min(attackMinCount, value);
                        } else {
                            defendMaxCount = Math.max(defendMaxCount, value);
                            defendMinCount = Math.min(defendMinCount, value);
                        }
                    }
                });

                if (metaEntry.partyType === "A") {
                    attackUnitData.push(metaChartDataEntry);
                } else {
                    defendUnitData.push(metaChartDataEntry);
                }
            });
        }

        let minDate = firstDate.length > 0 ? convertLocalDateArrayToDate(firstDate) : convertLocalDateStringToDate(props.fromDate!);
        let maxDate = lastDate.length > 0 ? convertLocalDateArrayToDate(lastDate) : convertLocalDateStringToDate(props.toDate!);

        for (const data of attackUnitData) {
            const keys = Object.keys(data);

            for (const dataKey of attackDataKeys) {
                if (!keys.includes(dataKey)) {
                    // @ts-ignore
                    data[dataKey] = 0;
                }
            }
        }

        for (const data of defendUnitData) {
            const keys = Object.keys(data);

            for (const dataKey of defendDataKeys) {
                if (!keys.includes(dataKey)) {
                    // @ts-ignore
                    data[dataKey] = 0;
                }
            }
        }

        const attackMinRounded = Math.floor(attackMinCount / 10) * 10;
        let attackMaxRounded = Math.ceil(attackMaxCount / 10) * 10;

        const defendMinRounded = Math.floor(defendMinCount / 10) * 10;
        let defendMaxRounded = Math.ceil(defendMaxCount / 10) * 10;

        return {
            minDate: minDate,
            maxDate: maxDate,
            attackDomain: [attackMinRounded, attackMaxRounded],
            defendDomain: [defendMinRounded, defendMaxRounded],
            attackDataKeys: Array.from(attackDataKeys),
            defendDataKeys: Array.from(defendDataKeys),
            attackUnitData: attackUnitData,
            defendUnitData: defendUnitData
        };
    }
});