import React from "react";

const SwordImage = ({height = 15, dead = false}: { height?: number, dead?: boolean }) => {
    return <img
        src="/assets/gb/guildbattle_icon_battle_amount.png"
        style={{
            height: height,
            lineHeight: height,
            verticalAlign: "middle",
            filter: `brightness(${dead ? "40%" : "100%"})`,
        }}
        title="Actions remaining"
    ></img>
}

export default SwordImage;