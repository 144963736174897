import { eachDayOfInterval, format, min, parse } from "date-fns";
import { atom, selector } from "recoil";

const pako = require("pako");

// Atom to store the fetched value
export const unitDataAtom = atom({
    key: 'unitDataAtom',
    default: selector({
        key: 'unitDataAtom/default',
        get: async () => {
            const response = await fetch(`https://assets.wotv-stats.com/${region()}/data/Unit.json`);
            const text = await response.text();
            return JSON.parse(text);
        },
    }),
});


// Atom to store the fetched value
export const guildBattleMapDataAtom = atom({
    key: 'guildBattleMapDataAtom',
    default: selector({
        key: 'guildBattleMapDataAtom/default',
        get: async () => {
            const response = await fetch(`https://assets.wotv-stats.com/${region()}/data/GuildBattleMap.json`);
            const text = await response.text();
            return JSON.parse(text);
        },
    }),
});

export const guildBattleMapSelector = selector({
    key: 'guildBattleMapSelector',
    get: ({ get }) => {
        const data = get(guildBattleMapDataAtom);

        let map: { [index: string]: GuildBattleMapItem } = {};

        for (const item of data.items[0].schedules) {
            let startDate = parse(item["start_at"].split(" ")[0], "yyyy/MM/dd", new Date());
            let endDate = parse(item["end_at"].split(" ")[0], "yyyy/MM/dd", new Date());

            let end = min([endDate, new Date()]);
            if (end < startDate) {
                end = startDate;
            }

            let interval: Interval = {
                start: startDate,
                end: end
            };

            let dates = eachDayOfInterval(interval);

            for (const date of dates) {
                map[convertToLocalDateString(date)] = {
                    start: convertToLocalDateString(startDate),
                    end: convertToLocalDateString(endDate),
                    questId: item["quest_id"],
                    mapIname: item["map_iname"]
                };
            }
        }

        return map;
    },
});


export const guildBattleStartMapSelector = selector({
    key: 'guildBattleStartMapSelector',
    get: ({ get }) => {
        const data = get(guildBattleMapDataAtom);

        let map: { [index: string]: GuildBattleMapItem } = {};

        for (const item of data.items[0].schedules) {
            let startDate = parse(item["start_at"].split(" ")[0], "yyyy/MM/dd", new Date());
            let endDate = parse(item["end_at"].split(" ")[0], "yyyy/MM/dd", new Date());

            map[convertToLocalDateString(startDate)] = {
                start: convertToLocalDateString(startDate),
                end: convertToLocalDateString(endDate),
                questId: item["quest_id"],
                mapIname: item["map_iname"]
            };
        }

        return map;
    },
});

export const unitNameDataAtom = atom({
    key: 'unitNameDataAtom',
    default: selector({
        key: 'unitNameDataAtom/default',
        get: async () => {
            const lang = region() === "gl" ? "en" : "ja";
            const response = await fetch(`https://assets.wotv-stats.com/${region()}/data/${lang}/text/masterparam/UnitName.json`);
            const text = await response.text();
            const json = JSON.parse(text);

            let map: { [index: string]: string } = {};

            for (const item of json.infos) {
                map[item["key"]] = item["value"];
            }

            map["~UNREVEALED~"] = "Unrevealed";
            return map;
        },
    }),
});


export const unitModelDataAtom = atom({
    key: 'unitModelDataAtom',
    default: selector({
        key: 'unitModelDataAtom/default',
        get: async () => {
            const response = await fetch(`https://assets.wotv-stats.com/${region()}/data/UnitModel.json`);
            const text = await response.text();
            return JSON.parse(text);
        },
    }),
});

export const questTitleDataAtom = atom({
    key: 'questTitleDataAtom',
    default: selector({
        key: 'questTitleDataAtom/default',
        get: async () => {
            const lang = region() === "gl" ? "en" : "ja";
            const response = await fetch(`https://assets.wotv-stats.com/${region()}/data/${lang}/text/masterparam/quest/QuestTitle.json`);
            const text = await response.text();
            const data =  JSON.parse(text);

            let map: { [index: string]: string } = {};

            for (const item of data.infos) {
                map[item["key"]] = item["value"];
            }

            return map;
        },
    }),
});

export const questInfoDataAtom = atom({
    key: 'questInfoDataAtom',
    default: selector({
        key: 'questInfoDataAtom/default',
        get: async () => {
            const lang = region() === "gl" ? "en" : "ja";
            const response = await fetch(`https://assets.wotv-stats.com/${region()}/data/${lang}/text/masterparam/quest/QuestInfo.json`);
            const text = await response.text();
            const data =  JSON.parse(text);

            let map: { [index: string]: string[] } = {};

            for (const item of data.infos) {
                if (!item["key"].includes("GVG")) {
                    continue;
                }

                map[item["key"]] = item["value"].split("/");
            }

            return map;
        },
    }),
});

export const unitElementMapSelector = selector({
    key: 'unitElementMapSelector',
    get: ({ get }) => {
        const unitData = get(unitDataAtom);

        let map: { [index: string]: string } = {};

        for (const item of unitData.items) {
            if (item.hasOwnProperty("elem")) {
                map[item.iname] = item["elem"][0];
            }
        }
        return map;
    },
});

export const unitImageMapSelector = selector({
    key: 'unitImageMapSelector',
    get: ({ get }) => {
        const unitData = get(unitDataAtom);
        const unitModelData = get(unitModelDataAtom);

        let map: { [index: string]: string } = {};

        for (const item of unitData.items) {
            let charaId = item.charaId;
            if (charaId === undefined) {
                continue;
            }

            map[item.iname] = `https://assets.wotv-stats.com/${region()}/unit_models/${charaId.toLowerCase()}_m.png`;
        }

        for (const item of unitModelData.items) {
            if (!item.hasOwnProperty("img")) {
                continue;
            }
            map[item.iname] = `https://assets.wotv-stats.com/${region()}/unit_models/${item.img.toLowerCase()}_m.png`;
        }

        return map;
    },
});

export const convertLocalDateStringToDate = (localDateString: string) => {
    return parse(localDateString, "yyyy-MM-dd", new Date());
};
export const convertLocalDateArrayToDate = (dateArray: number[]) => {
    return new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
};
export const convertLocalDateArrayToString = (dateArray: number[]) => {
    return convertToLocalDateString(new Date(dateArray[0], dateArray[1] - 1, dateArray[2]));
};
export const convertToLocalDateString = (date: Date) => {
    return format(date, "yyyy-MM-dd");
};

export const convertLocalDataArrayToString = (dateArray: number[]) => {
    return convertToLocalDateString(convertLocalDateArrayToDate(dateArray));
};

export function isGlobal() {
    return !window.location.pathname.toString().includes("/jp");
}

export function region() {
    return isGlobal() ? "gl" : "jp";
}

export type GuildBattleMapItem = {
    start: string
    end: string
    questId: string
    mapIname: string
}

export const fetchGzip = (input: RequestInfo | URL, init?: RequestInit): Promise<Response> => {
    return fetch(input, {
        headers: {
            "wotv-compress": "true"
        }
    }).then((compressedResponse) => {
        return compressedResponse.text();
    }).then(text => {
        let input = atob(text.replaceAll("\"", ''));
        let charArray = input.split("").map(function(x) {
            return x.charCodeAt(0);
        });
        return JSON.parse(pako.inflate(charArray, { to: "string" }));
    })
}

export const elementColorMap: { [index: number]: string } = {
    1: "rgba(255,0,0,1.0)",
    2: "rgba(39,82,255,1.0)",
    3: "rgba(0,255,4,1.0)",
    4: "rgba(185,77,3,1.0)",
    5: "rgba(255,213,0,1.0)",
    6: "rgba(0,255,217,1.0)",
    7: "rgba(255,255,255,1.0)",
    8: "rgb(140,0,178)"
};