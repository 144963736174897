import * as React from "react";
import {idMapSelector} from "../service/ConfigService";
import {useRecoilValue} from "recoil";
import {UnitImage} from "./UnitImage";
import { PlayerLogUnitStatus } from "../types/wotv-guild-data-api";

export type PartyImageProps = {
    unit1: number
    unit2: number
    unit3: number
    reserveUnit1?: number
    reserveUnit2?: number

    unit1Status: PlayerLogUnitStatus
    unit2Status: PlayerLogUnitStatus
    unit3Status: PlayerLogUnitStatus
    reserveUnit1Status?: PlayerLogUnitStatus
    reserveUnit2Status?: PlayerLogUnitStatus

    scrollToTopOnClick?: boolean
}

export const PartyImage = (props: PartyImageProps) => {
    const reserveUnit1 = props.reserveUnit1 || 0;
    const reserveUnit2 = props.reserveUnit1 || 0;

    const idMap = useRecoilValue(idMapSelector);

    return (<div style={{ display: "inline-block" }}>
        <UnitImage unitIname={idMap[props.unit1]} height={70} width={70} unitStatus={props.unit1Status} marginLeft={-20}
                   marginRight={-20} marginTop={-10} scrollToTopOnClick={props.scrollToTopOnClick} />

        <UnitImage unitIname={idMap[props.unit2]} height={70} width={70} unitStatus={props.unit2Status} marginLeft={0}
                   marginRight={-20} marginTop={-10} scrollToTopOnClick={props.scrollToTopOnClick}/>

        <UnitImage unitIname={idMap[props.unit3]} height={70} width={70} unitStatus={props.unit3Status} marginLeft={0}
                   marginRight={-20} marginTop={-10} scrollToTopOnClick={props.scrollToTopOnClick}/>

        {(reserveUnit1 !== 0) &&
            <UnitImage unitIname={idMap[props.reserveUnit1!]} height={70} width={70} unitStatus={props.reserveUnit1Status}
                       marginLeft={0}
                       marginRight={-20} marginTop={-10} scrollToTopOnClick={props.scrollToTopOnClick}/>}

        {(reserveUnit2 !== 0) &&
            <UnitImage unitIname={idMap[props.reserveUnit2!]} height={70} width={70} unitStatus={props.reserveUnit2Status}
                       marginLeft={0}
                       marginRight={-20} marginTop={-10} scrollToTopOnClick={props.scrollToTopOnClick}/>}
    </div>);
};