import {selectorFamily, useRecoilValue_TRANSITION_SUPPORT_UNSTABLE} from "recoil";
import {
    filterSelectedDateAtom,
    GuildRosterChangeRequest,
    playerChangeRowItemsByGuildId
} from "../../service/GuildBattleDataService";
import * as React from "react";
import {useState} from "react";
import {Fieldset} from "primereact/fieldset";
import {GuildRosterActivityTable} from "../table/GuildRosterActivityTable";
import {compareDesc} from "date-fns";
import {Checkbox} from "primereact/checkbox";
import PlusMinusIcon from "../../graphics/icon/PlusMinusIcon";
import {RankIcon} from "../../graphics/icon/RankIcon";
import {InputText} from "primereact/inputtext";
import { WotvIcon } from "../../graphics/icon/WotvIcon";

export interface GuildRosterActivityDashletProps {
    date?: string
    guildId: number

    hideGuildName?: boolean
    hideDateColumn?: boolean
    hideEnemyGuildName?: boolean
    hideCalendarInput?: boolean

    showCrashes?: boolean
    sort?: "RANK" | "DATE"
}

export type PlayerChangeFilters = GuildRosterChangeRequest & {
    minusChecked: boolean,
    plusChecked: boolean,
    rankChecked: boolean
}


export const checkedPlayerChangeItemsSelector = selectorFamily({
    key: 'checkedPlayerChangeItemsSelector',
    get: (filters: PlayerChangeFilters) => ({get}) => {

        let playerChangeRowItems = get(playerChangeRowItemsByGuildId({
            date: filters.date,
            guildId: filters.guildId
        }));

        const allUnchecked = !filters.plusChecked && !filters.minusChecked && !filters.rankChecked;

        return playerChangeRowItems.filter(item => {
            if (allUnchecked) {
                return true;
            }
            switch (item.guildAction) {
                case "JOINED":
                    return filters.plusChecked || (item.playerPosition !== "M" && filters.rankChecked);
                case "LEFT":
                    return filters.minusChecked || (item.playerPosition !== "M" && filters.rankChecked);
                case "POSITION_CHANGED" :
                    return filters.rankChecked;
            };
        });
    }
});

export const GuildRosterActivityDashlet = (props: GuildRosterActivityDashletProps) => {
    const latestDataReadyDate = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(filterSelectedDateAtom)

    const [minusChecked, setMinusChecked] = useState(false);
    const [plusChecked, setPlusChecked] = useState(false);
    const [rankChecked, setRankChecked] = useState(false);

    const changesDate = props.date || latestDataReadyDate;

    const playerChangeRowItems = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(checkedPlayerChangeItemsSelector({
        date: changesDate,
        guildId: props.guildId,
        minusChecked: minusChecked,
        plusChecked: plusChecked,
        rankChecked: rankChecked
    }));

    let sortedItems = [...playerChangeRowItems];

    if (props.sort === "RANK"){
        sortedItems.sort((a, b) => a.guildRank - b.guildRank);
    } else {
        sortedItems.sort((a, b) => compareDesc(a.date, b.date));
    }

    return (
        <>
            <Fieldset legend={"Player Changes"}>
                <div className="flex align-items-center " style={{columnGap: "10px", rowGap: "10px", flexWrap: "wrap"}}>
                    <div className="p-inputgroup" style={{maxWidth: "100px"}}>
                        <span className="p-inputgroup-addon">
                            Total
                        </span>
                            <InputText value={sortedItems.length} readOnly></InputText>
                    </div>
                    <div className="p-inputgroup align-items-center" style={{maxWidth: "200px"}}>
                        <span className="p-inputgroup-addon">
                            Filters
                        </span>
                        <div style={{ marginLeft: 10 }}>
                        <WotvIcon url={"/assets/icons/greenplus2.png"} height={20} width={20}
                                  onClick={() => setPlusChecked(!plusChecked)}
                                  disable={!plusChecked}/>
                        </div>

                        <div style={{ marginLeft: 10 }}>
                        <WotvIcon url={"/assets/icons/minus2.png"} height={20} width={20}
                                  onClick={() => setMinusChecked(!minusChecked)}
                                  disable={!minusChecked}/>
                        </div>

                        <div style={{ marginLeft: 10 }}>
                        <WotvIcon url={"/assets/icons/mili_rank.png"} height={20} width={20}
                                  onClick={() => setRankChecked(!rankChecked)}
                                  disable={!rankChecked}/>
                        </div>
                    </div>
                </div>
            </Fieldset>
            <GuildRosterActivityTable playerChangeRowItems={sortedItems}
                                      hideGuildName={props.hideGuildName}
                                      hideDateColumn={props.hideDateColumn}
            />
        </>);
}