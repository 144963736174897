import {Bar, BarChart, Rectangle, ResponsiveContainer, XAxis, YAxis} from "recharts";
import { elementColorMap, unitElementMapSelector } from "../../utilities/WotvDataUtil";
import * as React from "react";
import {PartyItem, PartyType} from "../../types/wotv-guild-data-api";
import {useRecoilValue} from "recoil";
import {idMapSelector} from "../../service/ConfigService";
import {PartyCompositionImage} from "../../graphics/PartyCompositionImage";

export interface UnitPartyCompositionStats {
    partyType: PartyType

    totalTeams: number
    numTeamsFiltered: number
    numWipes: number

    unit1: number
    unit2: number
    unit3: number
    reserveUnit1: number
    reserveUnit2: number

    unit1Alive: boolean
    unit2Alive: boolean
    unit3Alive: boolean
    reserveUnit1Alive: boolean
    reserveUnit2Alive: boolean

    stars: number
    defends: number
    guildRank: number

    matchedParties: PartyItem[]
}

export interface UnitPartyCompositionChartItem {
    keys: string[]
    unitPartyCompositionStatsMap: Map<string, UnitPartyCompositionStats>
    total: number
    maxCount: number
    // key: count
}

export interface UnitPartyCompositionChartProps {
    data: UnitPartyCompositionChartItem,
    title?: string,
    partyType: PartyType
    maxCount: number
}

export const UnitPartyCompositionChart = (props: UnitPartyCompositionChartProps) => {

    let chartData = props.data;
    let chartKeys = props.data.keys;

    const idMap = useRecoilValue(idMapSelector);
    const unitElementMap = useRecoilValue(unitElementMapSelector);


    // @ts-ignore
    const CustomLabel = (props, splitKey) => {

        let unitPartyCompositionStats = chartData.unitPartyCompositionStatsMap.get(splitKey)!;

        return <foreignObject x={props.x - 220} y={props.y} width={320} height={85}>
            <div style={{display: "inline"}}>
                <PartyCompositionImage {...unitPartyCompositionStats} />
            </div>
        </foreignObject>
    }

    // @ts-ignore
    const CustomBar = (props) => {
        let {name} = props.tooltipPayload[0];

        let unitIds: string[] = name.split("#");
        let unitInames = unitIds.map(id => idMap[id]);

        unitInames.sort((a, b) => {
            return +unitElementMap[a] - +unitElementMap[b];
        })

        let unitPartyCompositionStats = chartData.unitPartyCompositionStatsMap.get(name)!;

        let matchedParties = unitPartyCompositionStats.matchedParties;

        const elementMap: Map<number, number> = new Map();

        matchedParties.forEach(party => {
            if (unitElementMap.hasOwnProperty(idMap[party.unit1])) {
                elementMap.set(+unitElementMap[idMap[party.unit1]], (elementMap.get(+unitElementMap[idMap[party.unit1]]) || 0) + 1);
            }
            if (unitElementMap.hasOwnProperty(idMap[party.unit2])) {
                elementMap.set(+unitElementMap[idMap[party.unit2]], (elementMap.get(+unitElementMap[idMap[party.unit2]]) || 0) + 1);
            }
            if (unitElementMap.hasOwnProperty(idMap[party.unit3])) {
                elementMap.set(+unitElementMap[idMap[party.unit3]], (elementMap.get(+unitElementMap[idMap[party.unit3]]) || 0) + 1);
            }
            if (unitElementMap.hasOwnProperty(idMap[party.reserveUnit1])) {
                elementMap.set(+unitElementMap[idMap[party.reserveUnit1]], (elementMap.get(+unitElementMap[idMap[party.reserveUnit1]]) || 0) + 1);
            }
            if (unitElementMap.hasOwnProperty(idMap[party.reserveUnit2])) {
                elementMap.set(+unitElementMap[idMap[party.reserveUnit2]], (elementMap.get(+unitElementMap[idMap[party.reserveUnit2]]) || 0) + 1);
            }
        })

        let total = 0;
        for (let i = 1; i <= 8; i++) {
            let count = elementMap.get(i) || 0;

            total += count;
        }

        let elementEntries = Array.from(elementMap.entries());

        elementEntries.sort((a, b) => a[0] - b[0]);

        let usedWidth = 0;

        //use explicit fill here, or use the additional css class and make a css selector to update fill there
        return <>
            {
                elementEntries.map(value => {
                    let elementNumber = value[0];
                    let count = value[1];

                    let portion = count / total;
                    let width = props.width * portion;


                    let bar = <Rectangle {...props} height={60} width={width} fill={elementColorMap[elementNumber]}
                                         y={props.y + 10} x={props.x + usedWidth - 1}/>

                    usedWidth += width;

                    return bar;
                })
            }
        </>

    };

    return (
        <ResponsiveContainer width="100%" height={520}>
            <BarChart data={[chartData]} layout={"vertical"} margin={{top: 0, right: 40, bottom: 0, left: 165}}>
                {/*<CartesianGrid strokeDasharray="5 5"/>*/}
                <XAxis type="number" label={{value: "Number of Teams / Elements of Enemy Teams"}}
                       tick={false} domain={[0, props.maxCount]}/>
                <YAxis type="category" dataKey={"numTeams"} tickMargin={10} domain={[0, props.maxCount]}/>
                {/*<Tooltip formatter={(value) => value.toFixed(5)}/>*/}
                {
                    chartKeys!.filter(key => key.includes("#")).map((splitKey) => {
                        return (<Bar
                                key={splitKey}
                                dataKey={splitKey}
                                isAnimationActive={false}
                                stackId={splitKey}
                                shape={CustomBar}
                                barSize={90}
                                label={(props) => CustomLabel(props, splitKey)}
                            >
                            </Bar>
                        )
                    })
                }

            </BarChart>
        </ResponsiveContainer>
    );
};