import React from "react";
import { useRecoilValue_TRANSITION_SUPPORT_UNSTABLE } from "recoil";
import { Fieldset } from "primereact/fieldset";
import GuildBattleHistoryTable from "../table/GuildBattleHistoryTable";
import { guildBattleByGuildId } from "../../service/GuildBattleDataService";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { apiUrl } from "../../Config";

export type GuildProfileBattleHistoryDashletProps = {
    guildId: number
}

export const GuildProfileBattleHistoryDashlet = (props: GuildProfileBattleHistoryDashletProps) => {

    const guildBattleItems = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(guildBattleByGuildId({
        guildId: props.guildId
    }));

    let guildBattleItem = guildBattleItems.filter(guildBattleItem => guildBattleItem.guildId === props.guildId)[0];

    const exportCsv = () => {
        let filename = props.guildId + ".csv";
        fetch(apiUrl() + "/guild/" + props.guildId + "/csv", {
            headers: {
                "Accept": "text/csv"
            }
        }).then(response => {
            let disposition = response.headers.get("content-disposition");
            if (disposition && disposition.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }
            return response.blob();
        }).then(data => {
            var a = document.createElement("a");
            a.href = window.URL.createObjectURL(data);
            a.download = filename;
            a.click();
            a.remove();
        });
    };

    return (<>
        <Fieldset legend={"Guild Profile"}>
            <div className="flex justify-content-between"
                 style={{ columnGap: "10px", rowGap: "10px", flexWrap: "wrap" }}>
                <div className="p-inputgroup" style={{ maxWidth: "250px" }}>
                        <span className="p-inputgroup-addon">
                            Name
                        </span>
                    <InputText value={guildBattleItem.guildName} readOnly></InputText>
                </div>
                <Button label="CSV" icon="pi pi-download" onClick={event => exportCsv()} />
            </div>
        </Fieldset>
        <GuildBattleHistoryTable
            guildBattleItems={guildBattleItems}
            tableId={"guild-battle-history-table"}
            hideCalendarInput={true}
            hideGuildName
            hideWipes
            hideAbsences
            hideFreshClears
        />
    </>);
};
