import {selectorFamily, useRecoilValue_TRANSITION_SUPPORT_UNSTABLE} from "recoil";
import * as React from "react";
import {Fieldset} from "primereact/fieldset";
import {compareDesc} from "date-fns";
import {apiUrl} from "../../Config";
import {GuildChangeEventItem} from "../../types/wotv-guild-data-api";
import {convertLocalDateArrayToDate} from "../../utilities/WotvDataUtil";
import {GuildNameChangesTable} from "../table/GuildNameChangesTable";
import {InputText} from "primereact/inputtext";

export interface GuildNameChangesDashletProps {
    date?: string
    guildId: number

    hideGuildName?: boolean
    hideDateColumn?: boolean
    hideEnemyGuildName?: boolean
    hideCalendarInput?: boolean

    showCrashes?: boolean
}

export const guildChangeEventsByGuildId = selectorFamily({
    key: 'guildChangeEventsByGuildId',
    get: (guildId: number) => ({get}) => {

        return fetch(apiUrl() + "/guild/" + guildId + "/changes")
            .then(response => response.json())
            .then(guildChangeEventItems => {
                return guildChangeEventItems as GuildChangeEventItem[]
            })
    }
});

export const GuildNameChangesDashlet = (props: GuildNameChangesDashletProps) => {
    const guildChangeEventItems = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(guildChangeEventsByGuildId(props.guildId));

    let sortedItems = [...guildChangeEventItems];

    sortedItems.sort((a, b) => compareDesc(convertLocalDateArrayToDate(a.date), convertLocalDateArrayToDate(b.date)));

    return (
        <>
            <Fieldset legend={"Guild Name Changes"}>
                <div className="flex" style={{columnGap: "10px", rowGap: "10px", flexWrap: "wrap"}}>
                    <div className="p-inputgroup" style={{maxWidth: "100px"}}>
                        <span className="p-inputgroup-addon">
                            Total
                        </span>
                        <InputText value={guildChangeEventItems.length} readOnly></InputText>
                    </div>
                </div>
            </Fieldset>
            <GuildNameChangesTable guildChangeEventItems={guildChangeEventItems}
            />
        </>);
}