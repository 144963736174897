import React from "react";

export const ToiletPaperIcon = ({height = 15, dead = false}: { height?: number, dead?: boolean }) => {
    return <img
        src="/assets/icons/toilet_paper.png"
        style={{
            height: height,
            lineHeight: height,
            verticalAlign: "middle",
            filter: `brightness(${dead ? "40%" : "100%"})`,
        }}
        title="1st attack failures"
    ></img>
}