import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {convertToLocalDateString} from "../../utilities/WotvDataUtil";
import {PlayerChangeRowItem} from "../../service/GuildBattleDataService";
import {compareDesc} from "date-fns";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";
import {useTransition} from "react";
import PlusMinusIcon from "../../graphics/icon/PlusMinusIcon";
import {RankIcon} from "../../graphics/icon/RankIcon";
import {PositionImage} from "../../graphics/icon/PositionImage";
import {PlayerChangeEventItem} from "../../types/wotv-guild-data-api";
import { WotvIcon } from "../../graphics/icon/WotvIcon";
import * as React from "react";

export type PlayerActivityTableProps = {
    playerChangeRowItems: PlayerChangeRowItem[],
    hideDateColumn?: boolean
    hideNameColumn?: boolean
    hideGuildName?: boolean
}

export const PlayerActivityTable = (props: PlayerActivityTableProps) => {

    const navigate = useNavigate();
    const [inTransition, startTransition] = useTransition();

    let sortedItems = [...props.playerChangeRowItems];

    sortedItems.sort((a, b) => compareDesc(a.date, b.date));

    const formatGuildName = (item: PlayerChangeRowItem) => {
        return <Button label={item.guildName}
                       className="p-button-raised p-button-secondary"
                       style={{lineHeight: "20px"}}
                       onClick={() => onGuildNameClick(item.guildId)}/>
    };

    const onGuildNameClick = (guildId: number) => {
        startTransition(() => {
            navigate("/guild/" + guildId);
            window.scrollTo({top: 0, behavior: 'smooth'});
        })
    }

    const formatRosterChanges = (playerChangeRowItem: PlayerChangeRowItem) => {
        switch (playerChangeRowItem.guildAction) {
            case "LEFT":
                return (
                    <div className={"flex align-items-center"} style={{lineHeight: "27px"}}>
                        <WotvIcon url={"/assets/icons/minus2.png"} height={15} width={15}/>
                        <Button className="p-button-raised p-button-secondary"
                                style={{lineHeight: "20px", marginLeft: "5px"}}
                                onClick={() => onGuildNameClick(playerChangeRowItem.guildId)}>{playerChangeRowItem.guildName}
                        </Button>
                        <PositionImage size={23} guildPosition={playerChangeRowItem.playerPosition}/>
                    </div>
                );
            case "JOINED":
                return (
                    <div className={"flex align-items-center"} style={{lineHeight: "27px"}}>
                        <WotvIcon url={"/assets/icons/greenplus2.png"} height={15} width={15}/>
                        <Button className="p-button-raised p-button-secondary"
                                style={{lineHeight: "20px", marginLeft: "5px"}}
                                onClick={() => onGuildNameClick(playerChangeRowItem.guildId)}>{playerChangeRowItem.guildName}
                        </Button>
                        <PositionImage size={23} guildPosition={playerChangeRowItem.playerPosition}/>
                    </div>
                );
            case "POSITION_CHANGED":
                let oldPosition = <></>;
                let newPosition = <></>;

                let oldImg = "/assets/icons/" + playerChangeRowItem.playerPositionOld + ".png";
                let newImg = "/assets/icons/" + playerChangeRowItem.playerPosition + ".png";

                if (playerChangeRowItem.playerPosition !== "M") {
                    newPosition = <>
                        <WotvIcon url={"/assets/icons/greenplus2.png"} height={15} width={15}/>
                        <img style={{
                            width: "23px",
                            height: "23px",
                            verticalAlign: "middle"
                        }} src={newImg} alt={""}/></>
                }

                if (playerChangeRowItem.playerPositionOld !== "M") {
                    oldPosition = <>
                        <WotvIcon url={"/assets/icons/minus2.png"} height={15} width={15}/>
                        <img style={{
                            width: "23px",
                            height: "23px",
                            verticalAlign: "middle"
                        }} src={oldImg} alt={""}/></>
                }

                return (
                    <div className={"flex align-items-center"} style={{lineHeight: "27px", columnGap: "3px"}}>
                        <WotvIcon url={"/assets/icons/mili_rank.png"} height={20} width={20} />
                        <Button className="p-button-raised p-button-secondary"
                                style={{lineHeight: "20px", marginLeft: "5px"}}
                                onClick={() => onGuildNameClick(playerChangeRowItem.guildId)}>{playerChangeRowItem.guildName}
                        </Button> {oldPosition} {newPosition}
                    </div>
                );
            default:
                return (<></>);
        }
    }


    return (
        <>
            <DataTable id="player-activity-table" value={sortedItems} dataKey="id"
                       rowHover rows={10} paginator responsiveLayout="scroll">
                {!props.hideDateColumn &&
                    <Column field="date" header="Date" body={(data) => convertToLocalDateString(data.date)}></Column>}
                {!props.hideNameColumn &&
                    <Column field="guildName" header="Event" body={(data) => formatRosterChanges(data)}></Column>}
            </DataTable>
        </>
    );
}

