import {GuildBattleItem} from "../types/wotv-guild-data-api";

export type WinLoseDrawPops = {
    guildBattleItem: GuildBattleItem

    onClick?: (guildBattleItem: GuildBattleItem) => void

    height?: number
}
export const WinLoseDrawImage = (props: WinLoseDrawPops) => {
    const height = props.height || 26

    let imgUrl;
    switch (props.guildBattleItem.outcome) {
        case 1:
            imgUrl = "/assets/gb/win.png"
            break;
        case 2:
            imgUrl = "/assets/gb/lose.png"
            break;
        case 3:
            imgUrl = "/assets/gb/draw.png"
            break;
    }

    return (
        <img className={"cursor-pointer"} src={imgUrl} height={height} alt={""} style={{verticalAlign: "middle"}}
             onClick={event => props.onClick ? props.onClick(props.guildBattleItem) : () => {}}/>
    )
}
