import React from "react";

export const ShieldImage = ({height = 15, dead = false}: { height?: number, dead?: boolean }) => {
    return <img
        alt=""
        src="/assets/icons/shield.png"
        style={{
            height: height,
            lineHeight: height,
            verticalAlign: "middle",
            filter: `brightness(${dead ? "40%" : "100%"})`,
        }}
        title="# defends"

    ></img>
}