import React from 'react';
import {useParams} from "react-router-dom";
import {PlayerProfileInfoDashlet} from "../components/dashlet/PlayerProfileInfoDashlet";
import {PlayerGuildHistoryDashlet} from "../components/dashlet/PlayerGuildHistoryDashlet";
import {PlayerNameChangesDashlet} from "../components/dashlet/PlayerNameChangesDashlet";
import {PlayerActivityDashlet} from "../components/dashlet/PlayerActivityDashlet";
import { PlayerUnitChartPanel } from "../components/chart/PlayerUnitChartPanel";
import { PlayerMatchedPartiesDashlet } from "../components/dashlet/PlayerMatchedPartiesDashlet";
import { PlayerMatchedPartiesDetailDashlet } from "../components/dashlet/PlayerMatchedPartiesDetailDashlet";

export const PlayerProfilePage = () => {

    let {playerId} = useParams();
    let playerIdNumber = +playerId!

    return (
        <div className="layout-dashboard" style={{marginTop: "5px"}}>
            <div className="grid">
                <div className="col-12">
                    <div className="card widget-table">
                        <PlayerProfileInfoDashlet playerId={playerIdNumber} />
                    </div>
                </div>
                <div className="col-12 lg:col-4">
                    <div className="card widget-table">
                        <PlayerGuildHistoryDashlet playerId={playerIdNumber} />
                    </div>
                </div>
                <div className="col-12 lg:col-4">
                    <div className="card widget-table">
                        <PlayerActivityDashlet playerId={playerIdNumber} />
                    </div>
                </div>
                <div className="col-12 lg:col-4">
                    <div className="card widget-table">
                        <PlayerNameChangesDashlet playerId={playerIdNumber} />
                    </div>
                </div>
                <div className="col-12 lg:col-6">
                    <div className="card widget-table">
                        <PlayerUnitChartPanel playerId={playerIdNumber} partyType={"A"} />
                    </div>
                </div>
                <div className="col-12 lg:col-6">
                    <div className="card widget-table">
                        <PlayerUnitChartPanel playerId={playerIdNumber} partyType={"D"} />
                    </div>
                </div>
                <div className="col-12">
                    <div className="card widget-table">
                        <PlayerMatchedPartiesDashlet playerId={playerIdNumber} partyType={"A"}
                                                     attackFilterDefault={true} defenseFilterDefault={false} />
                    </div>
                </div>
                <div className="col-12">
                    <div className="card widget-table">
                        <PlayerMatchedPartiesDashlet playerId={playerIdNumber} partyType={"D"}
                                                     attackFilterDefault={false} defenseFilterDefault={true}
                                                     hideAbsentFilter />
                    </div>
                </div>

                <div className="col-12 lg:col-6">
                    <div className="card widget-table">
                        <PlayerMatchedPartiesDetailDashlet playerId={playerIdNumber} partyType={"A"} hideLegend hideAbsentFilter hideDateFilter
                                                           attackFilterDefault={true} defenseFilterDefault={false} />
                    </div>
                </div>
                <div className="col-12 lg:col-6">
                    <div className="card widget-table">
                        <PlayerMatchedPartiesDetailDashlet playerId={playerIdNumber} partyType={"D"} hideLegend hideAbsentFilter hideDateFilter
                                                           attackFilterDefault={false} defenseFilterDefault={true} />
                    </div>
                </div>
                {/*
                {
                    Object.entries(partyMeta["UNIT"]).map((entry, index) => {
                        let dataKeys = entry[1]["dataKeys"];
                        let data = entry[1]["data"];

                        return (<div className="col-12 lg:col-3">
                            <div className="card widget-table">
                                <div className="card-header">
                                    <span>Popularity - {entry[0]}</span>
                                </div>

                                <UnitMetaChart data={data} dataKeys={dataKeys} limit={15}/>
                            </div>
                        </div>);
                    })
                }
                {
                    Object.entries(partyMeta["PARTY"]).map((entry, index) => {
                        let dataKeys = entry[1]["dataKeys"];
                        let data = entry[1]["data"];

                        return (<div className="col-12 lg:col-3">
                            <div className="card widget-table">
                                <div className="card-header">
                                    <span>Popularity - {entry[0]}</span>
                                </div>

                                <PartyMetaChart data={data} dataKeys={dataKeys} limit={15}/>
                            </div>
                        </div>);
                    })
                }
*/}

            </div>
        </div>

    );
};