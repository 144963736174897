import {PartyItem, PartyType, PlayerLogInfo, PlayerLogItem} from "../../types/wotv-guild-data-api";
import React, { startTransition, useState } from "react";
import {
    convertLocalDateArrayToString,
    convertToLocalDateString
} from "../../utilities/WotvDataUtil";
import {DataView} from "primereact/dataview";
import {InputText} from "primereact/inputtext";
import PlayerPartyDetailImage, { PlayerLogItemExtended } from "../../graphics/PlayerPartyDetailImage";
import { guildRankMapData } from "../../service/GuildBattleDataService";
import { useRecoilValue } from "recoil";

export interface PlayerMatchedPartiesDetailDataViewProps {
    guildId?: number;
    sessionId?: number;
    date?: string;

    playerId?: number;

    partyType: PartyType;
    hideLegend?: boolean;
    playerLogItems: PlayerLogItem[];
    rows?: number
}

export type PlayerDataViewItem = {
    playerLogItem: PlayerLogItem
}

export const PlayerMatchedPartiesDetailDataView = (props: PlayerMatchedPartiesDetailDataViewProps) => {

    let playerLogItems = props.playerLogItems;

    const [date, setDate] = useState<string>("");

    const guildRankMap = useRecoilValue(guildRankMapData(date));

    let datesSet = new Set<string>();
    for (let item of playerLogItems) {
        datesSet.add(convertLocalDateArrayToString(item.date));
    }

    if (datesSet.size === 1) {
        let singleDate = datesSet.values().next().value;
        if (singleDate !== date)
        setDate(singleDate);
    }

    let filterdPlayerLogItems = playerLogItems.filter((item) => {
        return true
        // return (guildRankMap.get(item.allyLogInfo.guildId) || 999) <= 500
    })

    if (!props.playerId) {
        filterdPlayerLogItems.sort((a, b) => {
            let aRank = guildRankMap.get(a.allyLogInfo.guildId) || 0;
            let bRank = guildRankMap.get(b.allyLogInfo.guildId) || 0;
            let rankDifference = aRank - bRank;

            return rankDifference !== 0 ? rankDifference : (a.allyLogInfo.playerName.localeCompare(b.allyLogInfo.playerName));
        });
    }

    const itemTemplate = (playerLogItem: PlayerLogItem) => {
        return (
            <div className={"col-12"}>
                <div
                    className={""}>

                    {!props.hideLegend &&
                        <>
                            <div className="flex align-items-center "
                                 style={{
                                     columnGap: "5px",
                                     rowGap: "10px",
                                     flexWrap: "wrap",
                                     marginTop: "20px",
                                     marginBottom: "-8px"
                                 }}>
                                <div className="p-inputgroup" style={{maxWidth: "140px", height: "25px"}}>
                                    <span className="p-inputgroup-addon">
                                        Date
                                    </span>
                                    <InputText className={"cursor-pointer"}
                                               value={convertLocalDateArrayToString(playerLogItem.date)}
                                               readOnly>

                                    </InputText>
                                </div>
                                <div className="p-inputgroup" style={{maxWidth: "150px", height: "25px"}}>
                                    <InputText className={"cursor-pointer"} value={playerLogItem.allyLogInfo.guildName}
                                               readOnly>
                                    </InputText>
                                </div>
                                <div className="p-inputgroup" style={{maxWidth: "150px", height: "25px"}}>
                                    <InputText className={"cursor-pointer"} value={playerLogItem.enemyLogInfo.guildName}
                                               readOnly>
                                    </InputText>
                                </div>
                            </div>
                        </>}
                    <div className={"flex "}
                         style={{flexWrap: "wrap", columnGap: "10px", marginTop: "15px"}}>

                        <PlayerPartyDetailImage allyLogInfo={playerLogItem.allyLogInfo} enemyLogInfo={playerLogItem.enemyLogInfo} guildRank={guildRankMap.get(playerLogItem.allyLogInfo.guildId)}></PlayerPartyDetailImage>
                        <PlayerPartyDetailImage allyLogInfo={playerLogItem.enemyLogInfo} enemyLogInfo={playerLogItem.allyLogInfo} guildRank={guildRankMap.get(playerLogItem.enemyLogInfo.guildId)}></PlayerPartyDetailImage>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <DataView
            value={filterdPlayerLogItems}
            itemTemplate={(item, layout) => itemTemplate(item)}
            rowsPerPageOptions={[5, 10, 15, 30]}
            rows={5}
            paginator
            totalRecords={props.playerLogItems.length}
            // paginatorPosition={"both"}
            emptyMessage={"No detailed matchup information for this date."}
        >
        </DataView>
    );
};