import * as React from 'react'

export type WotvIconProps = {
    url: string
    width?: number
    height?: number
    disable?: boolean

    onClick?:  () => void
    title?: string
}

export class WotvIcon extends React.Component<WotvIconProps, {}> {

    render() {
        return <img src={this.props.url} onClick={this.props.onClick}
                    title={this.props.title}
                    style={{
            filter: `brightness(${this.props.disable ? "40%" : "100%"})`,
            width: this.props.width || 25,
            height: this.props.height || 25,
            lineHeight: this.props.height || 25,
            verticalAlign: "middle"
        }}
        />
    }
}