import React from 'react';
import {useRecoilValue} from "recoil";
import {useParams} from "react-router-dom";
import { UnitGuildRankUsageChartDashlet } from "../components/dashlet/UnitGuildRankUsageChartDashlet";
import { MetaUnitChartPanel } from "../components/chart/MetaUnitChartPanel";
import { UnitPartiesDataViewDashlet } from "../components/dashlet/UnitPartiesDataViewDashlet";
import { unitNameDataAtom } from "../utilities/WotvDataUtil";
import { idMapSelector } from "../service/ConfigService";
import { UnitPartyCompositionDashlet } from "../components/dashlet/UnitPartyCompositionDashlet";
import { PlayerMatchedPartiesDetailDashlet } from "../components/dashlet/PlayerMatchedPartiesDetailDashlet";

export const UnitProfilePage = () => {

    let {unitId} = useParams();
    let unitIdNumber = +unitId!

    let idMap = useRecoilValue(idMapSelector);
    let unitNameMap = useRecoilValue(unitNameDataAtom);

    let unitName = unitNameMap[idMap[unitIdNumber]];

    return (
        <div className="layout-dashboard" style={{marginTop: "5px"}}>
            <div className="grid">
                <div className="col-12 lg:col-6">
                    <div className="card widget-table" style={{ marginTop: "5px" }}>
                        <MetaUnitChartPanel title={`${unitName} - Attack Usage`} chartHeight={400}
                                            unitIdOnly={unitIdNumber} showDashboardLegend partyType={"A"}
                                            numBattlesBack={60} hideAbsentFilter />
                    </div>
                </div>
                <div className="col-12 lg:col-6">
                    <div className="card widget-table" style={{ marginTop: "5px" }}>
                        <MetaUnitChartPanel title={`${unitName} - Defense Usage`} chartHeight={400}
                                            unitIdOnly={unitIdNumber} showDashboardLegend partyType={"D"}
                                            numBattlesBack={60} hideAbsentFilter />
                    </div>
                </div>
                <div className="col-12 lg:col-6">
                    <div className="card widget-table">
                        <UnitGuildRankUsageChartDashlet title={`${unitName} - Guild Rank Distribution - Attack Teams`}
                                                        chartHeight={400} unitId={unitIdNumber} partyType={"A"} />
                    </div>
                </div>
                <div className="col-12 lg:col-6">
                    <div className="card widget-table">
                        <UnitGuildRankUsageChartDashlet title={`${unitName} - Guild Rank Distribution - Defense Teams`}
                                                        chartHeight={400} unitId={unitIdNumber} partyType={"D"} />
                    </div>
                </div>
                <div className="col-12 lg:col-6">
                    <div className="card widget-table">
                        <UnitPartyCompositionDashlet title={`${unitName} - Party Compositions - Attack Teams`}
                                                     unitId={unitIdNumber} partyType={"A"} />
                    </div>
                </div>
                <div className="col-12 lg:col-6">
                    <div className="card widget-table">
                        <UnitPartyCompositionDashlet title={`${unitName} - Party Compositions - Defense Teams`}
                                                     unitId={unitIdNumber} partyType={"D"} />
                    </div>
                </div>
{/*                <div className="col-12 lg:col-6">
                    <div className="card widget-table" style={{ marginTop: "5px" }}>
                        <UnitPartiesDataViewDashlet title={`${unitName} - Individual - Attack Teams`}
                                                    unitId={unitIdNumber} partyType={"A"} />
                    </div>
                </div>
                <div className="col-12 lg:col-6">
                    <div className="card widget-table" style={{ marginTop: "5px" }}>
                        <UnitPartiesDataViewDashlet title={`${unitName} - Individual - Defense Teams`}
                                                    unitId={unitIdNumber} partyType={"D"} />
                    </div>
                </div>*/}
                <div className="col-12 lg:col-6">
                    <div className="card widget-table">
                        <PlayerMatchedPartiesDetailDashlet unitId={unitIdNumber} partyType={"A"} hideLegend
                                                           hideAbsentFilter
                                                           attackFilterDefault={true} defenseFilterDefault={false} />
                    </div>
                </div>
                <div className="col-12 lg:col-6">
                    <div className="card widget-table">
                        <PlayerMatchedPartiesDetailDashlet unitId={unitIdNumber} partyType={"D"} hideLegend
                                                           hideAbsentFilter
                                                           attackFilterDefault={false} defenseFilterDefault={true} />
                    </div>
                </div>
            </div>

        </div>

    );
};